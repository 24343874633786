
app.controller('ticketController',['$scope','$compile','$rootScope','$stateParams','userModel','schemeModel','masterModel','$http','$cookieStore','$cookies','$state','$timeout','$filter','$window',     function($scope,$compile,$rootScope,$stateParams,$userModel,$schemeModel,$masterModel,$cookieStore,$http,$cookies,$state,$timeout,$filter,$window){
    let id = $stateParams.id;
    $scope.isDisabled = false;
    masterModel.getUploadsUrl().success(function(response){
    if (angular.equals(response.status,'success')) {
        $rootScope.uploadsUrl = response.upload_url;
    }
    }); 
//console.log("customerTicketLoginController");
    if ($state.current.name == 'customerTicketLogin') {
        $scope.captchaCode = "";
        $scope.captoken = "";
    }
    if ($state.current.name == 'ticketGuestUser') {
        //console.log("ticketGuestUser");
        
        //return;
    }
    if ($state.current.name == 'guestUserDetail') {
       // if($scope.auth.authenticated){

            $scope.loader = true;
            userModel.getGuestUserDetail($state.params.id).success(function(response){
                $scope.loader = false;
                $scope.name=response.data[0].name;
                $scope.username=response.data[0].registration_no;
            });

      //  }

    }
    if ($state.current.name == 'customerTicket') {
        if($scope.auth.authenticated){
            $scope.loader = true; 
            $scope.customerTicket={};
            userModel.getTicketDetail().success(function(response){
                 //console.log(response);
                    if (response.status == 'success') {
                        $scope.loader = false; 
                        $scope.ticketDetails = response.data;
                        $timeout(function(){
                            angular.element('.selectpicker').selectpicker('refresh');
                        },300);
                        //console.log($scope.ticketDetails);
                    }
            });
        }
    }

    if ($state.current.name == 'addCustomerTicket') {
       //console.log(!$scope.auth.authlogindetails.User.beneficiary_id);
        if($scope.auth.authenticated){
            $scope.loader=true;
                $scope.customerTicket={};
                schemeModel.getDistricts().success(function(response){
                 //console.log(response);
                    if (response.status == 'success') {

                        $scope.districts = response.data;
                        $timeout(function(){
                            angular.element('.selectpicker').selectpicker('refresh');
                        },300);
                        //console.log($scope.districts);
                    }
                });
            userModel.getTicketCategory().success(function(response){
             //console.log(response);
                if (response.status == 'success') {

                    $scope.ticketCategories = response.data;
                    $timeout(function(){
                        angular.element('.selectpicker').selectpicker('refresh');
                    },300);
                    //console.log($scope.ticketCategories);
                }
            });
            userModel.getTicketSubCategory().success(function(response){
             //console.log(response);
                if (response.status == 'success') {

                    $scope.ticketSubCategories = response.data;
                    $timeout(function(){
                        angular.element('.selectpicker').selectpicker('refresh');
                    },300);
                    //console.log($scope.ticketSubCategories);
                }
            });
            
            if (!$scope.auth.authlogindetails.User.beneficiary_id) {
                    $scope.customerTicket.ticketCategory=6;
                    $scope.customerTicket.ticketSubCategory=1;
                    $('#ticketCategory').prop('disabled',true);
                    $('#ticketSubCategory').prop('disabled',true);
            }else{
                userModel.getBeneficiaryDetail($scope.auth.authlogindetails.User.beneficiary_id).success(function(response){
                    if (response.status == 'success') {
                        var guestUser=0;
                        $scope.customerTicket.districtId=response.data[0].district_id;
                        $scope.getTaluka(parseInt(response.data[0].district_id));
                        $('#district').prop('disabled',true);
                    }
                });
                
            }
           $scope.loader=false; 
        }
    }

    if ($state.current.name === 'viewTicketDetail') {

        if($scope.auth.authenticated){
            $scope.loader=true;
            $scope.customerTicket={};
            userModel.getTicketDetail(id).success(function(response){
            try {
              if(response.status){
                $scope.ticketData = response.data;
                $scope.ticketDocs = response.data.ticketDocs;

                //console.log($scope.ticketDocs);
                $scope.loader=false;
              }else{
                $scope.loader=false;       
              }
            } catch (error) {
                $scope.loader=false;
            }
            });
        }
    }
    if ($state.current.name === 'editTicketDetail') {

        if($scope.auth.authenticated){
            $scope.loader=true;
            schemeModel.getDistricts().success(function(response){
                if (response.status == 'success') {

                    $scope.districts = response.data;
                    $timeout(function(){
                        angular.element('.selectpicker').selectpicker('refresh');
                    },300);
                }
            });
            userModel.getTicketCategory().success(function(response){
             //console.log(response);
                if (response.status == 'success') {

                    $scope.ticketCategories = response.data;
                    $timeout(function(){
                        angular.element('.selectpicker').selectpicker('refresh');
                    },300);
                    //console.log($scope.ticketCategories);
                }
            });
            userModel.getTicketSubCategory().success(function(response){
             //console.log(response);
                if (response.status == 'success') {

                    $scope.ticketSubCategories = response.data;
                    $timeout(function(){
                        angular.element('.selectpicker').selectpicker('refresh');
                    },300);
                    //console.log($scope.ticketSubCategories);
                }
            });
            userModel.getTicketDetail(id).success(function(response){
            try {
                if(response.status){
                    $ticket=response.data[0];
                    $scope.customerTicket={};
                    $scope.customerTicket.id = response.data[0].id;
                    $scope.customerTicket.ticketCategory = parseInt(response.data[0].ticket_category);
                    $scope.customerTicket.ticketSubCategory = parseInt(response.data[0].ticket_sub_category);
                    $scope.customerTicket.districtId = parseInt(response.data[0].district_id);
                    $scope.getTaluka(parseInt(response.data[0].district_id));
                    $scope.customerTicket.talukaId = parseInt(response.data[0].taluka_id);
                    $scope.getCities(parseInt(response.data[0].taluka_id));
                    $scope.customerTicket.cityId = parseInt(response.data[0].city_id);
                    $scope.customerTicket.description = response.data[0].description;
                    $scope.customerTicket.documentUpload = response.data[0].document_upload;
                    $scope.customerTicket.document_upload_alternate = response.data[0].document_upload_alternate;
                    $scope.customerTicket.description = response.data[0].description;
                    $scope.customerTicket.ticketDocs = response.data['ticketDocs'];
                    // console.log("respnse ",response.data['ticketDocs']);
                    // console.log("vairble ", $scope.customerTicket.ticketDocs)

                    $scope.loader=false;
                }else{
                    $scope.loader=false;       
                }
            } catch (error) {
                $scope.loader=false;
            }
            });
        }
    }

    $scope.addTicketDoc = function(){
        // alert("edditTicketDoc");
            var blankRow = {"id":0,"documentUpload":"","document_upload_alternate":""};
            $scope.customerTicket.ticketDocs.push(blankRow);
            // console.log($scope.customerTicket.ticketDocs);
            // return;
    };
    /*start remove ticket document*/
   $scope.removeTicketDoc = function(index,flag){
        // alert(flag);
        if(flag=='ticket_document'){
                $scope.customerTicket.ticketDocs.splice(index,1); 
        }                   
    };
        /*end remove nominal income*/
$scope.customerTicket={};
$scope.customerTicket.ticketDocs  = [{"id":0,"documentUpload":"","document_upload_alternate":""}];

angular.extend($scope,{
    ticketDocError : [],

    doTicketLogin: function(user) { 
        //alert("hi");
        //console.log(user);
        $scope.errorMsg='';
        $scope.userMobile='';
        if($scope.form.loginForm.$valid){
            $scope.loader=true;
            $scope.disabled_submit = true;
           // $('#login_button').prop('disabled',true);
            var data = angular.copy(user);
            data.registrationNumber = angular.copy(userModel.encryptCredentials(data.registrationNumber));
            data.password = angular.copy(userModel.encryptCredentials(data.password));
            //data.registrationNumber = data.registrationNumber;
            //data.password = data.password;
            data.flag="Ticket";

            userModel.doGuestLogin(data).success(function(response){

                if (response.status=="success") {
                    $scope.userMobile = response.data[0].mobile_no;
                    $scope.counter = 0;
                    userModel.generateOTP(response.data[0].mobile_no,response.data[0]).success(function(response){

                        $scope.loader=false;
                        angular.element('#loginForm').hide();
                        angular.element('#otpForm').show();
                        
                        $scope.isotpDisabled = true;
                        $scope.errorMsg ='';
                        // $scope.counter = 180;
                        $scope.counter = 60;
                        if (response.status == 203) {
                            $scope.counter = response.time;
                        }
                        $scope.onTimeout = function(){
                        if ($scope.counter > 0){
                            $scope.counter--;
                            mytimeout = $timeout($scope.onTimeout,1000);
                        } else {
                            $timeout.cancel(mytimeout);
                            $scope.counter = 0;
                            $scope.isotpDisabled = false;
                        }                           
                    }
                    var mytimeout = $timeout($scope.onTimeout,1000);
                        
                    
                    });
                } else if (response.status==false) {
                    $scope.errors = response.errors;
                    $scope.disabled_submit = false;
                    $scope.loader=false;
                    $('#login_button').prop('disabled',false);
                    $scope.errorMsg = 'Please enter correct Username and Password and try again';
                    setTimeout(function(){
                        $('#errorMsg').fadeOut('slow')
                    }, 4000);

                } else if (response.status=="fail") {

                    userModel.doLogin(data).success(function(response){
                        //console.log(response);
                        if (response == 'false'){
                            $scope.loader=false;
                            $scope.disabled_submit = false;
                            $('#login_button').prop('disabled',false);
                            $scope.errorMsg = 'Please enter correct Username and Password and try again';
                            setTimeout(function(){
                                $('#errorMsg').fadeOut('slow')
                            }, 4000);
                        }else if(response.response == 'not varified'){
                            //$scope.errorMsg ='';
                            $scope.loader=false;
                            localStorage.setItem('aadharVarificationData', JSON.stringify(response.Beneficiary));
                            $scope.aadhar_details = response.Beneficiary;
                            $state.go('pre_user_aadhar');
                            setTimeout(function(){
                                $('#errorMsg').fadeOut('slow')
                            }, 4000);
                        }else if(response.response == 'varified'){
                            // console.log(response);
                            // return;
                            $scope.userMobile = response.User.mobile_no;
                            $scope.counter=0;
                            userModel.generateOTP(response.User.mobile_no, response.User, response.Address).success(function(response){
                                $scope.loader=false;
                                angular.element('#loginForm').hide();
                                angular.element('#otpForm').show();
                                $scope.isotpDisabled = true;
                                $scope.errorMsg ='';
                                $scope.isotpDisabled = true;
                                // $scope.counter = 180;
                                $scope.counter = 60;
                                if (response.status == 203) {
                                    $scope.counter = response.time;
                                }
                                $scope.onTimeout = function(){
                                    if ($scope.counter > 0){
                                        $scope.counter--;
                                        mytimeout = $timeout($scope.onTimeout,1000);
                                    } else {
                                        $timeout.cancel(mytimeout);
                                        $scope.counter = 0;
                                        $scope.isotpDisabled = false;
                                    }                           
                                }
                                var mytimeout = $timeout($scope.onTimeout,1000);
                                
                            });
                            
                           // $state.go('dashboard'); 
                            // window.location.reload(); 

                        }
                        else if(response.attempts<3){
                            //$scope.errorMsg ='';
                            $scope.attempt=response.attempts;
                            $scope.disabled_submit = false;
                            $scope.loader=false;
                            $('#login_button').prop('disabled',false);
                            $scope.errorMsg = 'You have entered wrong username or password '+$scope.attempt+'times';
                            setTimeout(function(){
                                $('#errorMsg').fadeOut('slow')
                            }, 4000);
                            
                        }
                        else if(response.attempts==3){
                            //$scope.errorMsg ='';
                            $scope.attempt=response.attempts;
                            $scope.disabled_submit = false;
                            $scope.loader=false;
                            $('#login_button').prop('disabled',false);
                            $scope.errorMsg = 'Due to three unsuccessful login attempts,your login has been blocked.';
                            setTimeout(function(){
                                $('#errorMsg').fadeOut('slow')
                            }, 4000);
                            //alert($scope.errorMsg);
                        }
                        else if(response === 'blocked'){
                        // $scope.errorMsg ='';
                        $scope.disabled_submit = false;
                        $scope.loader=false;
                        $('#login_button').prop('disabled',false);
                        $scope.errorMsg = 'Due to three unsuccessful login attempts,your login has been blocked.For further assistance,please contact admin.';
                        setTimeout(function(){
                            $('#errorMsg').fadeOut('slow')
                        }, 4000);
                        //alert($scope.errorMsg);
                        }
                    });                    
                    
                }
                
             });
           

        }else{
            $scope.login_formSubmit = true;
            $scope.loader=false;

        }

    },
    cancel: function() {
        //alert("cancel");
        location.reload();
    },
    ticketLoginOtp: function(OTP){ 
            $scope.error = '';
            if($scope.form.otpForm.$valid){
                $scope.loader=true;
                $scope.guestOtpSubmit = false;
                userModel.verifyOTP(OTP).success(function(response){

                    if(response.status == 200){
                        $scope.loader=false;
                         $scope.userDetails = { User:response.user}
                         //console.log($scope.userDetails);
                         //$scope.userDetails = { User:response.User, Beneficiary: response.Beneficiary, Address: response.Address}
                         // $cookies.put('auth', JSON.stringify($scope.userDetails));
                         sessionStorage.authData = JSON.stringify($scope.userDetails);
                         //$cookies.put('logoutFlag', "customerTicket");
                         $scope.auth.authenticated = userModel.getAuthStatus();
                         $scope.auth.authlogindetails = userModel.getUserObj();
                         $scope.auth.logoutFlag = "customerTicket";
                         //$cookies.get('logoutFlag')
                         //console.log($scope.auth);
                         $state.go('customerTicket'); 

                    } else if(response.status == 408){
                        $scope.errorOtp = "Your OTP expired";
                        $scope.loader=false;
                        $('#loginBtn').attr('disabled',true);
                        setTimeout(function() {
                            angular.element('.alert-danger').hide();                       
                        }, 10000);
                    } else {
                        $scope.errorOtp = "Please enter correct OTP";
                        $('#loginBtn').attr('disabled',true);
                        $scope.loader=false;
                        setTimeout(function() {
                            angular.element('.alert-danger').hide();                       
                        }, 10000);
                    }
                });
            } else {
                $scope.guestOtpSubmit = true;
                $scope.loader=false;
            }
        },
    resendOTP: function() {

            $scope.successStep2 = '';
            $scope.errorStep2 = '';
            $scope.counter = 0;
            $scope.loader=true;

            $('#resendOTP').attr('disabled',true);
            userModel.resendOTP($scope.userMobile).success(function(response){
                //console.log(response);
                if(response){
                    //angular.element('.alert-success').show();
                    $scope.successOtp = 'OTP sent to your mobile number';
                    setTimeout(function() {
                        angular.element('.alert-success').hide();                       
                    }, 10000);
                   $scope.isotpDisabled = true;
                   $scope.loader=false;
                    // $scope.counter = 180;
                    $scope.counter = 60;
                    if (response.status == 203) {
                        $scope.counter = response.time;
                    }
                    $scope.onTimeout = function(){
                        if ($scope.counter > 0){
                            $scope.counter--;
                            mytimeout = $timeout($scope.onTimeout,1000);
                        } else {
                            $timeout.cancel(mytimeout);
                            $scope.counter = 0;
                            $scope.isotpDisabled = false;
                        }                           
                    }
                    var mytimeout = $timeout($scope.onTimeout,1000);
                    
                } else if(response==201){
                    $scope.errorOtp = 'You will be able to resend the OTP after 3 mins.';
                    setTimeout(function() {
                        angular.element('.alert-danger').hide();                       
                    }, 10000);
                   $scope.isotpDisabled = true;
                   $scope.loader=false;
                } else {
                    $scope.errorStep2 = response;
                    $scope.loader=false;
                    $('#resendOTP').removeAttr('disabled');
                }
            }).error(function() {
                // body...
                $scope.loader=false;
                $('#resendOTP').removeAttr('disabled');
                $scope.errorStep2 = "Some internal error occurred.";
            });     

    },
    getTaluka:function(districtid){
            //alert(districtid);
            masterModel.getTalukas(districtid).success(function(response) {
                $scope.taluka=response;
                $timeout(function(){
                            angular.element('.selectpicker').selectpicker('refresh');
                          },300);                
            });
    },
        

    getCities:function(talukaid){
        //alert(stateid);
        masterModel.getCities(talukaid).success(function(response) {
            $scope.cities=response;
            $timeout(function(){
                        angular.element('.selectpicker').selectpicker('refresh');
                      },300);           
        });
    },

    // **********Function to add or edit a ticket**********
    addTicketDetails:function(ticket, id=''){

        if($scope.auth.authenticated){
            $scope.docValid = true;
            $scope.isDisabled = true;
            $scope.errorMsg='';
            $scope.errorDocMsg=[];

            if(id != ''){
                ticket.id = id;
            } 

            angular.forEach($scope.customerTicket.ticketDocs,function(key, value) {
                if(key.documentUpload==''){
                    $scope.docValid=false;
                    $scope.errorDocMsg[value]=false;
                }
            })
                var postData = ''
                var contentType = 'application/json';

                if($scope.form.ticketForm.$valid && $scope.filevalidation && $scope.docValid){
                    $scope.loader=true;
                     //console.log(ticket);
                     //return;
                    if($scope.formdata!=undefined){
                        angular.forEach(ticket,function(key, value) {
                               
                            if(value=='ticketDocs'){
                                key = JSON.stringify(key);
                            }
                            $scope.formdata.append(value, key);
                            })

                          postData = $scope.formdata;
                          contentType = undefined;  
                    }else{
                        postData = ticket;                      
                    }

                userModel.addTicket(postData,contentType).success(function(response){
                 //console.log("result: ",response);
                    try {
                        if(response.status){    
                            $scope.isDisabled = false; 
                            $scope.loader = false;  
                            $state.go('customerTicket');
                            // $window.location.href = 'http://udyog.co/admin#/success_story';
                        }else{
                            $scope.isDisabled = false;
                            $scope.loader = false; 
                            $scope.errors = response.errors;
                            $scope.errorMsg = response.errorMsg;
                            // console.log("error accourd: ", $scope.errors);          
                        }

                    } catch (error) {
                        console.log("try error: ",error);        
                    }

                    localStorage.clear();
                });

            }
        }
    },
    // **********Function to add or edit a ticket**********    
    isMobileExists: function(){
         $scope.isMobileExist = false;
         if($scope.guestUser.mobile=='' || $scope.guestUser.mobile==undefined){
           return false;
         }
         userModel.isMobExist($scope.guestUser.mobile, '').success(function(response){
         if(response>0){
         $scope.isMobileExist = true;
         }
         });
    },
    // **********Function to add or edit a Guest user**********
    guestRegister:function(guest, id=''){
        //console.log(guest);
        $scope.isDisabled = true;
            if(id != ''){
                guest.id = id;
            } 
            var postData = ''
            var contentType = 'application/json';

            if($scope.form.guestForm.$valid && $scope.isMobileExist == false){

                $scope.loader=true;
                if($scope.formdata!=undefined){
                    angular.forEach(guest,function(key, value){
                        $scope.formdata.append(value, key);
                    });

                      postData = $scope.formdata;
                    
                
                      contentType = undefined;  
                }else{
                    // console.log("else: ",ticket);
                    postData = guest;
                  
                }

                postData.email = angular.copy(userModel.encryptCredentials(postData.email));
                postData.mobile = angular.copy(userModel.encryptCredentials(postData.mobile));
                postData.password = angular.copy(userModel.encryptCredentials(postData.password));
                //postData.confirmPassword = angular.copy(userModel.encryptCredentials(postData.confirmPassword));

                //console.log(postData);
                userModel.addGuestUser(postData,contentType).success(function(response){
                 //console.log("result: ",response);
                    $scope.loader = false; 
                    try {
                        if(response.status){    
                            $scope.isDisabled = false;   
                            $state.go('guestUserDetail',{id:response.id});
                            // $window.location.href = 'http://udyog.co/admin#/success_story';
                        }else{
                            $scope.isDisabled = false;
                            $scope.errors = response.errors;
                            if (response.errors.mobile[0]=="The mobile has already been taken.") {
                                response.errors.mobile[0]="Your mobile number is already registered as a guest user.";
                            }else if (
                                response.errors.mobile[1]=="The mobile has already been taken.") {
                                response.errors.mobile[1]="Your mobile number is already registered as a guest user.";
                            }else if (
                                response.errors.mobile[2]=="The mobile has already been taken.") {
                                response.errors.mobile[2]="Your mobile number is already registered as a guest user.";
                            }
                            $scope.passwordError = response.passwordError;
                            // console.log("error accourd: ", $scope.errors);          
                        }

                    } catch (error) {
                        console.log("try error: ",error);        
                    }

                    localStorage.clear();
                });
            }
    },
// **********Function to add or edit a ticket**********
    viewTicketComment: function(ticketId) {
        userModel.getTicketComments(ticketId, 6).success(function(response){
             $scope.loader = true;
            if (response) {
                $scope.loader = false; 
                $scope.ticketComments = response;   
                //console.log($scope.ticketComments);
                $('#viewComments').modal('show');
            }
        });
    },
    getTheFiles:function($files,flag){
        //alert(flag);
        if($scope.formdata == undefined){
            $scope.formdata = new FormData();
        }
        if(flag=='ticket_document'){
            $scope.ticket_document_error='';
            var fileExt = ["image/png","image/jpg","image/jpeg","application/pdf"];
            if(fileExt.indexOf($files[0].type) != -1) {
                if($files[0].size <= 10485760){
                    $scope.$apply(function(){
                        $scope.customerTicket.documentUpload = angular.copy($files[0].name);
                        $scope.formdata.append('ticket_document',$files[0]);
                    });
                } else {
                    $scope.customerTicket.documentUpload = '';
                    $scope.ticket_document_error = "Please upload document less than 10MB size!";
                }
            } else {
                $scope.ticket_document_error = "Unsupported file format.";
            }
      }
    },
    //*****function for check attachment size and extention match****
    validateFile: function(element, flag, index, isShowError = 1) {
        //console.log(element);
        var val = element[0].name; //get file value
        val = val.split("\\");
        val = val[val.length-1];

        var ext = val.substring(val.lastIndexOf('.') + 1).toLowerCase(); // get file extention 
         
        // Find no multiple Extensions
        var fileNameArrayExt = val.split('.');

        if (fileNameArrayExt.length > 2) {
            var msg = 'Files with multiple extensions or dots (.) are Not allowed.';
            //console.log(msg);
            if (isShowError) {
                $scope.showFileError(element, msg, flag, index);
            }

            return false;
        }

        var fileNameArraySpace = val.split(' ');
         //console.log('fileNameArraySpace',fileNameArraySpace);
        if (fileNameArraySpace.length > 1) {
            var msg = 'Files with spaces are Not allowed.';
            //console.log(msg);
            if (isShowError) {
                $scope.showFileError(element, msg, flag, index);
            }

            return false;
        }

        var fileNameArrayDoubleDots = val.split('..');
        if (fileNameArrayDoubleDots.length > 1) {
            var msg = 'Files with double dots (.) are Not allowed.';
            //console.log(msg);
            if (isShowError) {
                $scope.showFileError(element, msg, flag, index);
            }

            return false;
        }

        var fileName = val.split('.')[0];
        if ($scope.checkSpecialCharsTitle(fileName)) {
            var msg = 'Special characters are not allowed in file name.';
            //console.log(msg);
            if (isShowError) {
                $scope.showFileError(element, msg, flag, index);
            }

            return false;
        }

        $scope.showFileError(element, '', flag ,index);
        return true;
    },
    /**
    * @author Hemlata
    * Finds special characters in string
    * @return true/false
    */
    checkSpecialCharsTitle: function (argument1) {
        var format1 = /[!@#$%^&*+\=\[\]{};:"\\|<>\/?]/;
        return format1.test(argument1);
    },
    // show error for file input
    showFileError: function(element, msg = '', flag, index, hide=0) {
    // console.log('inside showFileError',msg);
    // find out identifier to give to error element
        switch (flag) {
            case 'ticket_document':
                if (msg != '') {
                    $scope.ticketDocError[index] = msg;
                    console.log($scope.ticketDocError);
                    return $scope.ticketDocError;
                }else{
                    $scope.ticketDocError[index]='';
                }
                break;          
           
                return false;
        }
            //console.log(Msg);
    },
    getTheMultipleFiles: function ($files, flag, index){
            
           $scope.filevalidation=$scope.validateFile($files, flag, index);
           
           var ticketDocError = true;
           
            for (var i = 0; i < $scope.ticketDocError.length; i++) {
                
                if($scope.ticketDocError[i]){
                    var ticketDocError=false;
                    break;
                }else{
                    var ticketDocError=true;
                }
            } 

            if (!ticketDocError) {
                 $scope.filevalidation=false;
            }
    
            if($scope.formdata == undefined){
                $scope.formdata = new FormData();
            }
            if(flag == 'ticket_document') {
                //alert('res');
                $scope.ticket_document_error= '';
                //console.log($files);
                var fileExt = ["image/png","image/jpg","image/jpeg",'image/bmp'];
                if(fileExt.indexOf($files[0].type) != -1) {
                    if($files[0].size <= 10485760){
                            $scope.$apply(function(){
                            $scope.customerTicket.ticketDocs[index].documentUpload = angular.copy($files[0].name);
                            // console.log($scope.customerTicket.ticketDocs[index]);
                            // alert("residence");
                            $scope.formdata.append('ticket_document'+index,$files[0]);
                        });
                    } else {
                        $scope.customerTicket.ticketDocs[index].documentUpload = '';
                        $scope.ticket_document_error[index] = "Please upload document less than 10MB size!";
                    }
                } else {
                    $scope.ticket_document_error[index] = "Unsupported file format.";
                }           
            }

        },
    opendoc: function(data,flag){
        var windowObjectReference;
        switch(flag)
        {
            case('ticket_document'):
                windowObjectReference = window.open($rootScope.uploadsUrl+"user_uploads/ticket/"+data);
            break;
            case('info_document'):
                windowObjectReference = window.open($rootScope.uploadsUrl+"user_uploads/ticket/"+data);
            break;
             
        }   
    },

    deleteTicketDetail: function(id){
        //alert(id);
        if($scope.auth.authenticated){
            var msg ='Are you sure you want to delete?'
            var size='small';
            bootbox.confirm({
                size: size,
                message: msg,
                buttons: {
                    confirm: {
                        label: 'Confirm',
                        className: 'btn-success'
                    },
                    cancel: {
                        label: 'cancel',
                        className: 'btn-danger'
                    }
                },
                callback: function(result){
                    if (result == true) {
                        $scope.loader=true; 
                        //console.log("confirm");
                        userModel.deletTicket(id).success(function(response){
                                // console.log("result: ",response);
                            try {
                                if(response.status=='success'){ 
                                //console.log("deletee");   
                                    $scope.isDisabled = false; 
                                    $scope.loader = false;
                                    $state.reload();
                                    //$state.go('customerTicket');
                                    // $window.location.href = 'http://udyog.co/admin#/success_story';
                                }else{
                                    $scope.isDisabled = false;
                                    $scope.loader = false; 
                                    $scope.errors = response.errors;
                                    // console.log("error accourd: ", $scope.errors);          
                                }

                            } catch (error) {
                                console.log("try error: ",error);        
                            }

                            localStorage.clear();
                        });
                    }else{
                        console.log("not confiem");
                        return;
                    }
                }
            });
        }
    },
    createCaptchaFp: function(){
                var list = document.getElementById("captchaFp").getElementsByTagName("canvas");
                
                for (var k = list.length - 1; k >= 0; k--) {
                        var item = list[k];
                        item.parentNode.removeChild(item);
                      }
                

                  var list1 = document.getElementById("captchaFp").getElementsByTagName("img");
                    for (var j = list1.length - 1; j >= 0; j--) {
                        var item = list1[j];
                        item.parentNode.removeChild(item);
                      }

                  userModel.authenticateForgotapi().success(function(response){
                    //$scope.captchaCodeFp = captcha.join("");
                    $scope.captchaCodeFp = response.captchafp;
                    $scope.tokenFp = response.token;
                        var canv=document.createElement('canvas');
                    canv.id = "captchaFp1";
                    canv.width = 120;
                    canv.height = 30;
                    var strng=$scope.captchaCodeFp;
                    var ctx = canv.getContext("2d");
                    ctx.clearRect(0, 0, ctx.width,ctx.height);;
                    ctx.font = "25px Georgia";
                    ctx.strokeText(strng, 0, 20);
                    
                    $timeout(function(){
                    document.getElementById("captchaFp").appendChild(canv); 
                    }, 1000);

                    $timeout(function(){
                    $scope.txt = "<img src='public/assets/frontend/img/captcha_refresh.png' class='float-right'  style='height: 30px;margin-right:0px;margin-top: -20px;cursor: pointer;' ng-click='createCaptchaFp()'/>";
                    var html = $compile($scope.txt)($scope);
                        angular.element(document.getElementById("captchaFp")).append(html); 
                    }, 1100);

                  });
        },

        reCaptcha: function(){
                $scope.grievance.captcha_string = captcha();
                $scope.grievance.captcha = '';
        },
        forgotPassword: function(aadhar){
            //console.log(aadhar);
            //console.log($scope.captchaFp);
            /*console.log($scope.captchaCodeFp);*/
            $scope.errorMesg = '';
            $scope.successMesg = '';
            angular.element('#submit').removeAttr('disabled');
            //$('#forgot-pwd').show();
            if (aadhar != undefined) {
                        if($scope.captchaFp==$scope.captchaCodeFp){
                            $scope.disabled_submit1 = true;
                        angular.element('#submit').attr('disabled',true);   
                        aadharenc = angular.copy(userModel.encryptAdhar(aadhar));
                        captchafp = angular.copy(userModel.encryptCredentials($scope.captchaCodeFp));
                        tokenF = angular.copy($scope.tokenFp);
                        //console.log(aadharenc);
                        aadhar_id = angular.copy(userModel.encryptCredentials(aadhar));

                        userModel.forgotPassword(aadhar_id,aadharenc,captchafp,tokenF).success(function(response){
                            // console.log(response.message);
                            if(response == 'false'){
                                $scope.errorMesg = "Captcha did not match";
                                $('#errorM').fadeOut('slow');
                            }else{
                            if (response.message == 'Success') {
                                $scope.successMesg = "New Password SMS/Email send to your Registered Mobile No and Email Id ";
                                
                                 setTimeout(function() {
                                        delete $scope.errorMesg;
                                        delete $scope.successMesg;
                                          $('#successM').fadeOut('slow');
                                        angular.element('#forgetPassword').modal('hide');

                                    },3000);
                                 $scope.disabled_submit1 = false;
                                 $scope.registrationNumber = '';
                                 angular.element('#submit').removeAttr('disabled');
                            }else{
                                $scope.errorMesg = response.message;
                                setTimeout(function() {
                                        delete $scope.errorMesg;
                                        delete $scope.successMesg;
                                          $('#errorM').fadeOut('slow');
                                    },3000);
                                $scope.disabled_submit1 = false;
                                angular.element('#submit').removeAttr('disabled');
                            }
                        }

                        });
                        }else{
                                $scope.disabled_submit1 = false;
                               $scope.errorMesg = "Please enter valid captcha code";
                               setTimeout(function() {
                                        delete $scope.errorMesg;
                                        delete $scope.successMesg;
                                          $('#errorM').fadeOut('slow');
                                    },3000);
                               angular.element('#submit').removeAttr('disabled');
                        }
                
            }else{
                   $scope.disabled_submit1 = false;
                   $scope.errorMesg = "Please Enter Aadhar Id/Registration No. First";
                   setTimeout(function() {
                            delete $scope.errorMesg;
                            delete $scope.successMesg;
                              $('#errorM').fadeOut('slow');
                        },3000);
                   angular.element('#submit').removeAttr('disabled');
            }
        },

  });
  

        
}]);


app.directive('validPasswordC', function() {
    return {
        require: 'ngModel',
            scope: {
              reference: '=validPasswordC'
        },
        link: function(scope, elm, attrs, ctrl) {
            ctrl.$parsers.unshift(function(viewValue, $scope) {

                var noMatch = viewValue != scope.reference
                ctrl.$setValidity('noMatch', !noMatch);
                return (noMatch)?noMatch:!noMatch;
            });

            scope.$watch("reference", function(value) {;
                ctrl.$setValidity('noMatch', value === ctrl.$viewValue);
            });
        }
    }
});
