app.controller('registrationAadharAuthController',function($scope,$compile,$state,$timeout,userModel,masterModel){
	angular.extend($scope,{
		tokenRg : "",
		form: {},
		genders: [{"value": 1,"option":"Male"},{"value":2,"option":"Female"},{"value":3,"option":"Transgender"}],
		regStep1: {
			//captcha_string: captcha(),
			tokenRg : "",
			captcha_string: "",
			country_id: 1,
			state_id: 27,
			nationality: 26,
		},
		step1Submit: false,
		step2Submit: false,
		step3Submit: false,
		// isotpDisabled:true,
		aadharAlreadyExists : {
			displayUserError : false,
			userType : null,
		},
		errorStep3Arr: [],
		// isAadharExist: false
        isMobileExists: function(){
         $scope.isMobileExist = false;
            // alert($scope.regStep1.mobile);
            // console.log("isMobileExists");
            // console.log($scope.isMobileExist);
         if($scope.regStep1.mobile=='' || $scope.regStep1.mobile==undefined){
           return false;
         }
         userModel.isMobExist($scope.regStep1.mobile, '').success(function(response){
         if(response>0){
         $scope.isMobileExist = true;
         }
         });
        }
	});

	/*function captcha() {
		// body...
	    var text = "";
	    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

	    for( var i=0; i < 6; i++ )
	        text += possible.charAt(Math.floor(Math.random() * possible.length));

	    return text;
	    
	}*/
	if ($state.current.name == 'registration') {
		setTimeout(function(){
			                    $('html, body').animate({
			                        scrollTop: ($('.panel-heading').offset().top - 300)
			                    }, 2000);
			                }, 100); 
	}
	$scope.decrementCountdown = function(){
		$scope.countdown -=1;
		var seconds = $scope.countdown ;
		var hours = parseInt( seconds / 3600 );
		seconds = seconds % 3600;
		var minutes = parseInt( seconds / 60 ); 
		seconds = Math.trunc(seconds % 60);
		if(hours==0 && minutes==0){
		$scope.counter=seconds + " sec";  
		}else if(hours==0){
		$scope.counter=minutes+":"+seconds + " min";  
		}
     };
     $timeout(function () {$scope.createCaptchaRg()}, 1000);
	angular.extend($scope,{
		createCaptchaRg: function(){
				var list = document.getElementById("captchaRg").getElementsByTagName("canvas");
				
				for (var k = list.length - 1; k >= 0; k--) {
					    var item = list[k];
					    item.parentNode.removeChild(item);
					  }

				  var list1 = document.getElementById("captchaRg").getElementsByTagName("img");
					for (var j = list1.length - 1; j >= 0; j--) {
					    var item = list1[j];
					    item.parentNode.removeChild(item);
					  }
				  userModel.authenticateReg().success(function(response){
				  	$scope.captchaCodeRg = response.captchaRg;
					$scope.tokenRg = response.token;
						var canv=document.createElement('canvas');
							canv.id = "captchaRg1";
						  	canv.width = 120;
						  	canv.height = 30;
						  	var strng=$scope.captchaCodeRg;
						  	var ctx = canv.getContext("2d");
			   				ctx.clearRect(0, 0, ctx.width,ctx.height);;
							ctx.font = "25px Georgia";
							ctx.strokeText(strng, 0, 20);
						$timeout(function(){
			   			document.getElementById("captchaRg").appendChild(canv);	
						}, 1000);

						$timeout(function(){
			   			$scope.txt = "<img ng-click='createCaptchaRg()' style='height:30px;margin-bottom:20px;cursor:pointer' title='Refresh Captcha' src='public/img/captcha_refresh.png'>";
						var html = $compile($scope.txt)($scope);
	                        angular.element(document.getElementById("captchaRg")).append(html);	
						}, 1100);
				  });
		},
		cancel: function (argument) {
			$state.go('home');
		},
		// function for generating new captcha string when clicked on refresh button for employer...
		/*reCaptcha: function(){
	 			$scope.regStep1.captcha_string = captcha();
	 			$scope.regStep1.captcha = '';
		},*/
		registration_step_1: function() 
		{
			//$scope.regStep1.aadhar_no_org=angular.copy(userModel.encryptCredentials($scope.regStep1.aadhar_no_org));
			//$scope.regStep1.captcha=angular.copy(userModel.encryptCredentials($scope.regStep1.captcha));
			$scope.error = '';
			//console.log($scope.regStep1);
			if ($scope.form.step_1_form.$valid && $scope.regStep1.captcha === $scope.captchaCodeRg && $scope.isAadharExist == false && $scope.isMobileExist == false && $scope.aadharAlreadyExists.displayUserError != true) {
			$scope.regStep1.aadhar_no_org=angular.copy(userModel.encryptCredentials($scope.regStep1.aadhar_no_org));
			//if (1) {
				//console.log('if');
				// return;
				$('#first_step_next').attr('disabled', true);
				$scope.step1Submit = false;
				userModel.getAadharVerified($scope.regStep1,$scope.tokenRg).success(function(response){
					// console.log(response);
					if(response == ''){
						$scope.error ="Error occurred in Aadhar verification, please try after sometime";
						$scope.step1Submit = true;
						angular.element('#first_step_next').removeAttr('disabled');
					}else if(response == false){
						$scope.error ="Aadhar server is down due to Server error,Please try after some time !";
						$scope.step1Submit = true;
						angular.element('#first_step_next').removeAttr('disabled');

					}else if(response!='false'){
					//if(response=='false'){
						if (response.Ret == 'y') {
						//if (1) {
							userModel.generateOTP($scope.regStep1.mobile).success(function(response){
								if(response){
									//console.log(response);
									angular.element('#step1').hide();
									angular.element('#step2').show();

									$scope.counter = 60;
									if (response.status == 203) {
										$scope.counter = response.time;
									}

									$scope.isotpDisabled = true;
									$timeout(function(){
										$scope.isotpDisabled = false;
									}, $scope.counter * 1000);

									// $scope.counter = 180;
								    $scope.onTimeout = function(){
								        if ($scope.counter > 0){
								        	$scope.counter--;	
								        	mytimeout = $timeout($scope.onTimeout,1000);
								        } else {
								        	$timeout.cancel(mytimeout);
								        	$scope.counter = 0;
								        }

						    		}
								    var mytimeout = $timeout($scope.onTimeout,1000);
									
						if (response.Ret == 'y') {
							userModel.generateOTP($scope.regStep1.mobile).success(function(response){
								if(response){
									//console.log(response);
									angular.element('#step1').hide();
									angular.element('#step2').show();
									$scope.isotpDisabled = true;
									
									$scope.counter = 60;
									if (response.status == 203) {
										$scope.counter = response.time;
									}

									$scope.isotpDisabled = true;
									$timeout(function(){
									    $scope.isotpDisabled = false;
									  }, $scope.counter * 1000);

									// $scope.counter = 180;
								    $scope.onTimeout = function(){
								        if ($scope.counter > 0){
								        	$scope.counter--;	
								        	mytimeout = $timeout($scope.onTimeout,1000);
								        } else {
								        	$timeout.cancel(mytimeout);
								        	$scope.counter = 0;
								        }

						    		}
								    var mytimeout = $timeout($scope.onTimeout,1000);
									
									angular.element('#step3').hide();
								}					   
					  		});	
						} 
						else{
				 	 		if(response.ResponseCode == 1001){
								
								$scope.error = "Please enter correct name and aadhar ID";
								$scope.step1Submit = true;
								setTimeout(function(){
				                    $('html, body').animate({
				                        scrollTop: ($('.alert').offset().top - 300)
				                    }, 2000);
				                }, 100); 
				                setTimeout(function(){
										    $('#errorMsg').fadeOut('slow')
										}, 3000);
				                angular.element('#first_step_next').removeAttr('disabled');
								
							} else if( response.ResponseCode == 100) {

								$scope.error = "Either of your Name or DOB is not matching as mentioned in Aadhaar Card";
								$scope.step1Submit = true;
								setTimeout(function(){
				                    $('html, body').animate({
				                        scrollTop: ($('.alert').offset().top - 300)
				                    }, 2000);
				                }, 100); 
				                setTimeout(function(){
										    $('#errorMsg').fadeOut('slow')
										}, 3000);
				                 angular.element('#first_step_next').removeAttr('disabled');

							} else if (response.ResponseCode >=930 && response.ResponseCode <=939){
								
								$scope.error = "Technical Exception";
								$scope.step1Submit = true;
								setTimeout(function(){
				                    $('html, body').animate({
				                        scrollTop: ($('.alert').offset().top - 300)
				                    }, 2000);
				                }, 100); 
				                setTimeout(function(){
										    $('#errorMsg').fadeOut('slow')
										}, 3000);
				                 angular.element('#first_step_next').removeAttr('disabled');
									
							} else if(response.ResponseCode == 1008){
								
								$scope.error = "Please enter correct Aadhar ID";
								$scope.step1Submit = true;
								setTimeout(function(){
				                    $('html, body').animate({
				                        scrollTop: ($('.alert').offset().top - 300)
				                    }, 2000);
				                }, 100); 
				                setTimeout(function(){
										    $('#errorMsg').fadeOut('slow')
										}, 3000);
				                 angular.element('#first_step_next').removeAttr('disabled');
								
							} else if(response.ResponseCode == 998){
								$scope.error = "Ensure you have entered correct Aadhaar number.  Please retry with correct Aadhaar number after sometime.";
								$scope.step1Submit = true;
								setTimeout(function(){
				                    $('html, body').animate({
				                        scrollTop: ($('.alert').offset().top - 300)
				                    }, 2000);
				                }, 100); 
				                setTimeout(function(){
										    $('#errorMsg').fadeOut('slow')
										}, 3000);
				                 angular.element('#first_step_next').removeAttr('disabled');
								
							} else if(response.ResponseCode == 511){
								$scope.error = "Technical Exception";
								$scope.step1Submit = true;
								setTimeout(function(){
				                    $('html, body').animate({
				                        scrollTop: ($('.alert').offset().top - 300)
				                    }, 2000);
				                }, 100); 
				                setTimeout(function(){
										    $('#errorMsg').fadeOut('slow')
										}, 3000);
				                 angular.element('#first_step_next').removeAttr('disabled');
								
							} else if(response.ResponseCode == 1002){
								$scope.error = "Aadhaar verification is failed due to server error, please try after sometime";
								$scope.step1Submit = true;
								 angular.element('#first_step_next').removeAttr('disabled');
								
							} else if(response.ResponseCode == 997){
				                userModel.generateOTP($scope.regStep1.mobile).success(function(response){
								
										if(response){
											angular.element('#step1').hide();
											angular.element('#step2').show();
											angular.element('#step3').hide();
										}					   
						 		 	});	
							}
				    	}
					
									angular.element('#step3').hide();
								}					   
					  		});	
						} 
						else{
				 	 		if(response.ResponseCode == 1001){
								
								$scope.error = "Please enter correct name and aadhar ID";
								$scope.step1Submit = true;
								setTimeout(function(){
				                    $('html, body').animate({
				                        scrollTop: ($('.alert').offset().top - 300)
				                    }, 2000);
				                }, 100); 
				                setTimeout(function(){
										    $('#errorMsg').fadeOut('slow')
										}, 3000);
				                angular.element('#first_step_next').removeAttr('disabled');
								
							} else if( response.ResponseCode == 100) {

								$scope.error = "Either of your Name or DOB is not matching as mentioned in Aadhaar Card";
								$scope.step1Submit = true;
								setTimeout(function(){
				                    $('html, body').animate({
				                        scrollTop: ($('.alert').offset().top - 300)
				                    }, 2000);
				                }, 100); 
				                setTimeout(function(){
										    $('#errorMsg').fadeOut('slow')
										}, 3000);
				                 angular.element('#first_step_next').removeAttr('disabled');

							} else if (response.ResponseCode >=930 && response.ResponseCode <=939){
								
								$scope.error = "Technical Exception";
								$scope.step1Submit = true;
								setTimeout(function(){
				                    $('html, body').animate({
				                        scrollTop: ($('.alert').offset().top - 300)
				                    }, 2000);
				                }, 100); 
				                setTimeout(function(){
										    $('#errorMsg').fadeOut('slow')
										}, 3000);
				                 angular.element('#first_step_next').removeAttr('disabled');
									
							} else if(response.ResponseCode == 1008){
								
								$scope.error = "Please enter correct Aadhar ID";
								$scope.step1Submit = true;
								setTimeout(function(){
				                    $('html, body').animate({
				                        scrollTop: ($('.alert').offset().top - 300)
				                    }, 2000);
				                }, 100); 
				                setTimeout(function(){
										    $('#errorMsg').fadeOut('slow')
										}, 3000);
				                 angular.element('#first_step_next').removeAttr('disabled');
								
							} else if(response.ResponseCode == 998){
								$scope.error = "Ensure you have entered correct Aadhaar number.  Please retry with correct Aadhaar number after sometime.";
								$scope.step1Submit = true;
								setTimeout(function(){
				                    $('html, body').animate({
				                        scrollTop: ($('.alert').offset().top - 300)
				                    }, 2000);
				                }, 100); 
				                setTimeout(function(){
										    $('#errorMsg').fadeOut('slow')
										}, 3000);
				                 angular.element('#first_step_next').removeAttr('disabled');
								
							} else if(response.ResponseCode == 511){
								$scope.error = "Technical Exception";
								$scope.step1Submit = true;
								setTimeout(function(){
				                    $('html, body').animate({
				                        scrollTop: ($('.alert').offset().top - 300)
				                    }, 2000);
				                }, 100); 
				                setTimeout(function(){
										    $('#errorMsg').fadeOut('slow')
										}, 3000);
				                 angular.element('#first_step_next').removeAttr('disabled');
								
							} else if(response.ResponseCode == 1002){
								$scope.error = "Aadhaar verification is failed due to server error, please try after sometime";
								$scope.step1Submit = true;
								 angular.element('#first_step_next').removeAttr('disabled');
								
							} else if(response.ResponseCode == 997){
				                userModel.generateOTP($scope.regStep1.mobile).success(function(response){
								
										if(response){
											angular.element('#step1').hide();
											angular.element('#step2').show();
											angular.element('#step3').hide();
										}					   
						 		 	});	
							}
				    	}
					}else{
						$scope.error = "Enter valid captcha";
							setTimeout(function(){
										    $('#errorMsg').fadeOut('slow')
										}, 3000);
					}
				}); 
			}else {
				//console.log('else');
				if (angular.equals($scope.aadharAlreadyExists.displayUserError,true)) {
					//console.log('aadhar pop up open');
					$("#forgot-pwd").modal("show");
				}
				angular.element('#first_step_next').removeAttr('disabled');
				$scope.step1Submit = true;
			}
		  },
		isAadharExists: function(aadhar_no, errorVar){
			$scope.regStep1.aadhar_no = aadhar_no;
			var len = $scope.regStep1.aadhar_no.length;
			if(len==12){
				$scope.regStep1.aadhar_no_org=aadhar_no;
				var maskAdhar=aadhar_no;
				var last=maskAdhar.slice(-4);
				$scope.regStep1.aadhar_no = maskAdhar.substr(0,8).replace(/\d/g,"X")+last;
				//console.log($scope.regStep1.aadhar_no_org);
				aadhar_no=angular.copy(userModel.encryptCredentials($scope.regStep1.aadhar_no_org));
		            $scope[errorVar] = false;
		            if(aadhar_no=='' || aadhar_no==undefined){
		                return false;
		            }
		        userModel.isAadharExist(aadhar_no).success(function(response){
                var count = response.count;
                var type = response.type;
                if(type == 'MSSDS' || type=='New User' || type == 'previous_maharojgar_user'){
                    $scope.aadharAlreadyExists.displayUserError = true;
                    $scope.aadharAlreadyExists.userType = (type == 'previous_maharojgar_user' || type == 'New User') ? 'MR' : type;
                }
                if(count > 0){
                    $scope[errorVar] = true;
                	}
            	});
			}else{
				$scope.regStep1.aadhar_no_org='';
			}
		},
		/*isAadharExists: function(aadhar_no, errorVar){
			console.log('hi');
            aadhar_no=angular.copy(userModel.encryptCredentials(aadhar_no));

            $scope[errorVar] = false;
            
            if(aadhar_no=='' || aadhar_no==undefined){
                return false;
            }
            userModel.isAadharExist(aadhar_no).success(function(response){
                //alert(response);
                var count = response.count;
                var type = response.type;

                //alert(count);

                if(type == 'MSSDS' || type=='New User' || type == 'previous_maharojgar_user'){
                    $scope.aadharAlreadyExists.displayUserError = true;
                    $scope.aadharAlreadyExists.userType = (type == 'previous_maharojgar_user' || type == 'New User') ? 'MR' : type;
                }
                // console.log(response);
                if(count > 0){
                    //alert('above 0');
                    $scope[errorVar] = true;
                }
            });
        },*/
		// isMobileExists: function(){
  //   		$scope.isMobileExist = false;
  //           alert($scope.regStep1.mobile);
  //           console.log("isMobileExists");
  //           console.log($scope.isMobileExist);
  //   		if($scope.regStep1.mobile=='' || $scope.regStep1.mobile==undefined){
  //   		  return false;
  //   		}
  //   		userModel.isMobExist($scope.regStep1.mobile, '').success(function(response){
  //   		if(response>0){
  //   		$scope.isMobileExist = true;
  //   		}
  //   		});
		// },
		registration_step_2: function(OTP){
			$scope.error = '';
			if($scope.form.step_2_form.$valid){
				$('#second_step_next').attr('disabled', true);
				$scope.step2Submit = false;
				userModel.verifyOTP(OTP).success(function(response){
					// console.log(response);
					if(response.status == 200){
						angular.element('#step1').hide();
						angular.element('#step2').hide();
						angular.element('#step3').show();
					} else if(response.status == 408){
						$scope.errorStep2 = "Your OTP expired";
						$('#second_step_next').removeAttr('disabled');
					} else {
						$scope.errorStep2 = "Please enter correct OTP";
						$('#second_step_next').removeAttr('disabled');
					}
				});
			} else {
				$scope.step2Submit = true;
			}
		},
		registration_step_3: function() {
			// body...
			$scope.errorStep3Arr = [];
			$scope.errorStep3 = '';
			if($scope.form.step_3_form.$valid && ($scope.regStep1.password === $scope.regStep1.confirm_password)){
				$('#third_step_next').attr('disabled', true);
				$scope.step3Submit = false;
				$scope.regStep1.confirm_password = angular.copy(userModel.encryptCredentials($scope.regStep1.confirm_password));
				userModel.registerUpdateProfile($scope.regStep1).success(function(response){
					//console.log(response);
					if(response.message == 'success' || response.message == 'sucess'){
						userModel.password_log($scope.regStep1.aadhar_no,$scope.regStep1.confirm_password,$scope.regStep1.confirm_password).success(function(response){

						});
						bootbox.alert('Registered successfully');
						$state.go('home');
					} else if(response.status=='error') {
						
		               $scope.errorStep3 = response.message;
						$('#third_step_next').removeAttr('disabled');
					}else {
						$scope.errorStep3 = response.message;
						$('#third_step_next').removeAttr('disabled');
					}
				}).error(function() {
					// body...
					$scope.error = "Some internal error occurred.";
				});		
			} else {
				$scope.step3Submit = true;
			}
		},
		getDistricts: function(state_id,targetArr,element_id) {
			// body...
			masterModel.getDistricts(state_id).success(function(response){
				$scope[targetArr] = response;
				setTimeout(function() {
					angular.element('#'+element_id).selectpicker('refresh');
				}, 10);
			}).error(function() {
				// body...
				$scope.errorStep3Arr.push("Some internal error occurred in fetching district list from database.");
			});
		},
		gettalukas: function(district_id,targetArr,element_id) {
			// body...
			masterModel.getTalukas(district_id).success(function(response){
				$scope[targetArr] = response;
				setTimeout(function() {
					angular.element('#'+element_id).selectpicker('refresh');
				}, 10);
			}).error(function() {
				// body...
				$scope.errorStep3Arr.push("Some internal error occurred in fetching taluka list from database.");
			});
		},
		getCities: function(taluka_id,targetArr,element_id) {
			// body...
			masterModel.getCities(taluka_id).success(function(response){
				$scope[targetArr] = response;
				setTimeout(function() {
					angular.element('#'+element_id).selectpicker('refresh');
				}, 10);
			}).error(function() {
				// body...
				$scope.errorStep3Arr.push("Some internal error occurred in fetching cities list from database.");
			});
		},
		getSubCastes: function(caste_id,targetArr,element_id) {
			// body...
			masterModel.getSubCastes(caste_id).success(function(response){
				$scope[targetArr] = response;
				setTimeout(function() {
					angular.element('#'+element_id).selectpicker('refresh');
				}, 10);
			}).error(function() {
				// body...
				$scope.errorStep3Arr.push("Some internal error occurred in fetching sub caste list from database.");
			});
		},
		forgotPassword: function(aadhar){
			//$scope.loading = false;
			$scope.errorMesg = '';
			$scope.successMesg = '';
			angular.element('#submit').removeAttr('disabled');
			//$('#forgot-pwd').show();
			if (aadhar != undefined) {
			$scope.disabled_submit1 = true;
			angular.element('#submit').attr('disabled',true);	
			aadhar_id = angular.copy(userModel.encryptCredentials(aadhar));
			userModel.forgotPassword(aadhar_id).success(function(response){
				// console.log(response.message);
				if (response.message == 'Success') {
					$scope.successMesg = "New Password SMS/Email send to your Registered Mobile No and Email Id ";
					
					 setTimeout(function() {
							delete $scope.errorMesg;
							delete $scope.successMesg;
							  $('#successM').fadeOut('slow');
							angular.element('#forgot-pwd').modal('hide');

						},3000);
					 $scope.disabled_submit1 = false;
					 $scope.aadhar_id = '';
					 angular.element('#submit').removeAttr('disabled');
				}else{
					$scope.errorMesg = response.message;
					setTimeout(function() {
							delete $scope.errorMesg;
							delete $scope.successMesg;
							  $('#errorM').fadeOut('slow');
						},3000);
					$scope.disabled_submit1 = false;
					angular.element('#submit').removeAttr('disabled');
				}
			});
				
			}else{
				   $scope.disabled_submit1 = false;
			       $scope.errorMesg = "Please Enter Aadhar Id/Registration No. First";
			       setTimeout(function() {
							delete $scope.errorMesg;
							delete $scope.successMesg;
							  $('#errorM').fadeOut('slow');
						},3000);
			       angular.element('#submit').removeAttr('disabled');
			}
		},

		resendOTP: function(mobile_no) {
			$scope.successStep2 = '';
			$scope.errorStep2 = '';
			$scope.counter = 0;

			// $timeout.cancel(mytimeout);

			// clearTimeout(mytimeout);
			// body...
			$('#resendOTP').attr('disabled',true);
			userModel.generateOTP(mobile_no).success(function(response){
				//console.log(response);
				if(response){
					if (response) {

					angular.element('.alert-success').show();
					$scope.successStep2 = 'OTP sent to your mobile number '+mobile_no;
					setTimeout(function() {
						angular.element('.alert-success').hide();						
					}, 10000);
					}
				   $scope.isotpDisabled = true;
					// $timeout(function(){
					//     $scope.isotpDisabled = false;
					//   }, 140000);
					$scope.counter = 60;
					if (response.status == 203) {
						$scope.counter = response.time;
					}

					// $scope.counter = 180;
				    $scope.onTimeout = function(){
				        if ($scope.counter > 0){
				        	$scope.counter--;
				        	mytimeout = $timeout($scope.onTimeout,1000);
				        } else {
				        	$timeout.cancel(mytimeout);
						    $scope.counter = 0;
				        	$scope.isotpDisabled = false;
				        }				        	
				    }
				    var mytimeout = $timeout($scope.onTimeout,1000);
					//$('#resendOTP').removeAttr('disabled');
					
				} else {
					$scope.errorStep2 = response;
					$('#resendOTP').removeAttr('disabled');
				}
			}).error(function() {
				// body...
				$('#resendOTP').removeAttr('disabled');
				$scope.errorStep2 = "Some internal error occurred.";
			});		

		}
	});

	if ($state.current.name == 'registration') {
		angular.element('#step2').hide();
		angular.element('#step3').hide();

		masterModel.getCountry().success(function(response){
			$scope.country_list = response;
			setTimeout(function() {
				angular.element('#country').selectpicker('refresh');
			}, 10);
		}).error(function() {
			// body...
			$scope.errorStep3Arr.push("Some internal error occurred in fetching countries from database.");
		});

		masterModel.getStates($scope.regStep1.country_id).success(function(response){
			$scope.state_list = response;
			setTimeout(function() {
				angular.element('#state').selectpicker('refresh');
			}, 10);
		}).error(function() {
			// body...
			$scope.errorStep3Arr.push("Some internal error occurred in fetching states list from database.");
		});

		masterModel.getDistricts($scope.regStep1.state_id).success(function(response){
			$scope.district_list = response;
			setTimeout(function() {
				angular.element('#district').selectpicker('refresh');
			}, 10);
		}).error(function() {
			// body...
			$scope.errorStep3Arr.push("Some internal error occurred in fetching district list from database.");
		});

		masterModel.getNationalities().success(function(response){
			$scope.nationality_list = response;
			setTimeout(function() {
				angular.element('#nationality').selectpicker('refresh');
			}, 10);
		}).error(function() {
			// body...
			$scope.errorStep3Arr.push("Some internal error occurred in fetching nationality list from database.");
		});

		masterModel.getReligions().success(function(response){
			$scope.religion_list = response;
			setTimeout(function() {
				angular.element('#religion').selectpicker('refresh');
			}, 10);
		}).error(function() {
			// body...
			$scope.errorStep3Arr.push("Some internal error occurred in fetching religion list from database.");
		});

		masterModel.getCaste().success(function(response){
			$scope.caste_list = response;
			setTimeout(function() {
				angular.element('#caste').selectpicker('refresh');
			}, 10);
		}).error(function() {
			// body...
			$scope.errorStep3Arr.push("Some internal error occurred in fetching caste list from database.");
		});

		masterModel.getmothertongue().success(function(response){
			$scope.motherTongue_list = response.data;
			//console.log(response)
			setTimeout(function() {
				angular.element('#motherTongue').selectpicker('refresh');
			}, 10);
		}).error(function() {
			// body...
			$scope.errorStep3Arr.push("Some internal error occurred in fetching Languages list from database.");
		});

		masterModel.getmaritalstatus().success(function(response){
			$scope.maritalStatus_list = response.data;
			//console.log(response)
			setTimeout(function() {
				angular.element('#maritalStatus').selectpicker('refresh');
			}, 10);
		}).error(function() {
			// body...
			$scope.errorStep3Arr.push("Some internal error occurred in fetching marital status list from database.");
		});
	}
});
