app.controller('homeController', ['$scope','$compile','$cookieStore','$cookies','$state','$timeout','userModel','schemeModel','news','$translate','policy','notification','notice',  function($scope,$compile,$cookieStore,$cookies,$state,$timeout,userModel,schemeModel,news,$translate,policy,notification,notice){
	// console.log('running');
	$scope.form = {};
	$scope.user = {};
	$scope.search = {};
	$scope.producttype= [{'value':0,'option':'Product'},{'value':1,'option':'Service'}];

if ($state.current.name == 'home') {
	$scope.captchaCode = "";
	$scope.captoken = "";
		schemeModel.getSchemes().success(function(response){
			$scope.schemes = response;
		});

		schemeModel.getSchemeAppcount().success(function(response){
			$scope.count = response;
			
		});
		
		setTimeout(function(){
			$scope.createCaptchaLogin();
		},1000);
		setTimeout(function(){
				               $('html, body').animate({
				                        scrollTop: ($('.intro').offset().top - 300)
				                    }, 2000);
				                }, 100); 
		
		

	}

/*this is Multilingual functionality*/
//$scope.captchaCode : "";
$scope.policyRawData = policy.data;
$scope.newsRawData = news.data;
$scope.notificationRawData = notification.data;
$scope.noticeRawData = notice.data;
//console.log(notification.data);

	$scope.policyData = [];
	$scope.newsData = [];
	$scope.notificationData = [];
	$scope.noticeData = [];

	var key = localStorage.getItem('language_key');

	//console.log(key)
	if(key == 'en'){
				
				//console.log($scope.notiificationRawData);
				$scope.policyData = angular.copy($scope.policyRawData);
				angular.forEach($scope.policyRawData, function(value, key){
				$scope.policyData[key].teaser = angular.copy(value.teaser);
				$scope.policyData[key].title = angular.copy(value.title);
				$scope.policyData[key].content = angular.copy(value.english_content);
			});

				$scope.newsData = angular.copy($scope.newsRawData);
				angular.forEach($scope.newsRawData, function(value, key){
				$scope.newsData[key].english_content = angular.copy(value.english_content);
				$scope.newsData[key].title = angular.copy(value.title);
			});

				$scope.notificationData = angular.copy($scope.notificationRawData);
				 angular.forEach($scope.notificationRawData, function(value, key){
				 $scope.notificationData[key].title = angular.copy(value.title);
			});

			$scope.noticeData = angular.copy($scope.noticeRawData);
				angular.forEach($scope.noticeRawData, function(value, key){
				$scope.noticeData[key].document_url_alternate = angular.copy(value.document_url_alternate);
				$scope.noticeData[key].title = angular.copy(value.title);
			});
		
	}
	else{

				$scope.policyData = angular.copy($scope.policyRawData);
				angular.forEach($scope.policyRawData, function(value, key){
				$scope.policyData[key].teaser = angular.copy(value.marathi_teaser);
				$scope.policyData[key].title = angular.copy(value.marathi_title);
				$scope.policyData[key].content = angular.copy(value.marathi_content);
			});

				$scope.newsData = angular.copy($scope.newsRawData);
				angular.forEach($scope.newsRawData, function(value, key){
				$scope.newsData[key].english_content = angular.copy(value.marathi_content);
				$scope.newsData[key].title = angular.copy(value.marathi_title);
			});

				$scope.notificationData = angular.copy($scope.notificationRawData);
				angular.forEach($scope.notificationRawData, function(value, key){
				$scope.notificationData[key].title = angular.copy(value.marathi_title);
			});

				$scope.noticeData = angular.copy($scope.noticeRawData);
				angular.forEach($scope.noticeRawData, function(value, key){
				$scope.noticeData[key].document_url = angular.copy(value.document_url);
				$scope.noticeData[key].title = angular.copy(value.marathi_title);
			});
	}

	$scope.$watch('common_vars.language_key', function (newValue, oldValue) {
		//console.log(newValue);
		if(newValue =='mr'){
				$scope.newsData = angular.copy($scope.newsRawData);
				angular.forEach($scope.newsRawData, function(value, key){
				$scope.newsData[key].english_content = angular.copy(value.marathi_content);
				$scope.newsData[key].title = angular.copy(value.marathi_title);
			});

				$scope.policyData = angular.copy($scope.policyRawData);
				angular.forEach($scope.policyRawData, function(value, key){
				$scope.policyData[key].teaser = angular.copy(value.marathi_teaser);
				$scope.policyData[key].title = angular.copy(value.marathi_title);
				$scope.policyData[key].content = angular.copy(value.marathi_content);
			});

				$scope.notificationData = angular.copy($scope.notificationRawData);
				angular.forEach($scope.notificationRawData, function(value, key){
				$scope.notificationData[key].title = angular.copy(value.marathi_title);
			});

			$scope.noticeData = angular.copy($scope.noticeRawData);
				angular.forEach($scope.noticeRawData, function(value, key){
				$scope.noticeData[key].document_url = angular.copy(value.document_url);
				$scope.noticeData[key].title = angular.copy(value.marathi_title);
			});


		}else if(newValue=='en'){
			
				$scope.newsData = angular.copy($scope.newsRawData);
				angular.forEach($scope.newsRawData, function(value, key){
				$scope.newsData[key].english_content = angular.copy(value.english_content);
				$scope.newsData[key].title = angular.copy(value.title);
			});
				$scope.policyData = angular.copy($scope.policyRawData);
				angular.forEach($scope.policyRawData, function(value, key){
				$scope.policyData[key].teaser = angular.copy(value.teaser);
				$scope.policyData[key].title = angular.copy(value.title);
				$scope.policyData[key].content = angular.copy(value.english_content);
			});

				$scope.notificationData = angular.copy($scope.notificationRawData);
				angular.forEach($scope.notificationRawData, function(value, key){
				$scope.notificationData[key].title = angular.copy(value.title);
			});

			$scope.noticeData = angular.copy($scope.noticeRawData);
				angular.forEach($scope.noticeRawData, function(value, key){
				$scope.noticeData[key].document_url = angular.copy(value.document_url);
				$scope.noticeData[key].title = angular.copy(value.title);
			});


		}
	}, true);
/*end Multilingual*/

angular.extend($scope,{
	
itemsPerPage: 2,

	getTaluka:function(districtid){
            //alert(districtid);
            masterModel.getTalukas(districtid).success(function(response) {
                $scope.talukas=response;
                $timeout(function(){
                            angular.element('.selectpicker').selectpicker('refresh');
                          },300);                
            });
    },
    clearProductFilterList: function(){
         
             $scope.search.district ='';
             $scope.search.taluka='';
             $scope.search.producttype='';
             setTimeout(function() {
                angular.element('.selectpicker').selectpicker('refresh');
            }, 10);
             /* Code for Async Pagination, Added by Hemlata  */  

            $scope.totalProducts = 0;
            $scope.productPerPage = 30;
            getResultsPageList(1);
            $scope.pagination = {
                current: 1
            };
            $scope.pageChangedList = function(newPage) {
                getResultsPageList(newPage);
               
            };
            function getResultsPageList(pageNumber) {
                $scope.search.offset = pageNumber;
                schemeModel.getProductList($scope.search).success(function(response){
                        if (response) {
                            $scope.productDetails = response.products;
                            $scope.totalProducts = response.totalProducts;
                            $timeout(function(){
                                angular.element('.selectpicker').selectpicker('refresh');
                            },300);
                            
                        }
                });
            }

            /* Code for Async Pagination, Added by Hemlata  */
        },
        productFilterList:function(search){
        	console.log(search);
        schemeModel.getProductList($scope.search).success(function(response){
            if (response) {
                $scope.loader = false; 
                $scope.productDetails = response.products;
                $scope.totalProducts = response.totalProducts;
                $timeout(function(){
                    angular.element('.selectpicker').selectpicker('refresh');
                },300);
                
            }
        });
    },
	createCaptcha: function(){
	
				var list = document.getElementById("captcha").getElementsByTagName("canvas");
				var list1 = document.getElementById("captcha").getElementsByTagName("img");
				
				for (var k = list.length - 1; k >= 0; k--) {
					    var item = list[k];
					    item.parentNode.removeChild(item);
					  }
				
				var charsArray ="0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
				var lengthOtp = 6;
				var captcha = [];
				  for (var i = 0; i < lengthOtp; i++) {
				    //below code will not allow Repetition of Characters
				    var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
				    if (captcha.indexOf(charsArray[index]) == -1)
				      captcha.push(charsArray[index]);
				    else i--;
				  }
				  var list1 = document.getElementById("captcha").getElementsByTagName("img");
					for (var j = list1.length - 1; j >= 0; j--) {
					    var item = list1[j];
					    item.parentNode.removeChild(item);
					  }
				  $scope.captchaCode = captcha.join("");
				  var ctr=angular.copy(userModel.encryptCredentials($scope.captchaCode));
				  userModel.authenticate(ctr).success(function(response){
				  	$scope.captchaCodeuser = response.captcha;
					$scope.captoken = response.token;
				  	var canv=document.createElement('canvas');
					canv.id = "captcha1";
				  	canv.width = 120;
				  	canv.height = 30;
				  	var strng=$scope.captchaCodeuser;
				  	var ctx = canv.getContext("2d");
	   				ctx.clearRect(0, 0, ctx.width,ctx.height);;
					ctx.font = "25px Georgia";
					ctx.strokeText(strng, 0, 20);
					
				setTimeout(function(){
					document.getElementById("captcha").appendChild(canv);
				},1000);

				setTimeout(function(){
					$scope.txt = "<img src='public/assets/frontend/img/captcha_refresh.png' class='float-right'  style='height: 30px;margin-top: -20px; margin-right: -1px;cursor: pointer;' ng-click='createCaptcha()' id='ref'/>";
					var html = $compile($scope.txt)($scope);
                        angular.element(document.getElementById("captcha")).append(html);
				},1100);

				  });
								
		},

		createCaptchaLogin: function(){
	
				var list = document.getElementById("captcha").getElementsByTagName("canvas");
				var list1 = document.getElementById("captcha").getElementsByTagName("img");
				
				for (var k = list.length - 1; k >= 0; k--) {
					    var item = list[k];
					    item.parentNode.removeChild(item);
					  }
				
				  var list1 = document.getElementById("captcha").getElementsByTagName("img");
					for (var j = list1.length - 1; j >= 0; j--) {
					    var item = list1[j];
					    item.parentNode.removeChild(item);
					  }
				  userModel.authenticatelogin().success(function(response){
				  	$scope.captchaCode = response.captcha;
				  	$scope.captchaCodeuser = response.captcha;
					$scope.captoken = response.token;
				  	var canv=document.createElement('canvas');
					canv.id = "captcha1";
				  	canv.width = 120;
				  	canv.height = 30;
				  	var strng=$scope.captchaCodeuser;
				  	var ctx = canv.getContext("2d");
	   				ctx.clearRect(0, 0, ctx.width,ctx.height);;
					ctx.font = "25px Georgia";
					ctx.strokeText(strng, 0, 20);
					
				setTimeout(function(){
					document.getElementById("captcha").appendChild(canv);
				},1000);

				setTimeout(function(){
					$scope.txt = "<img src='public/assets/frontend/img/captcha_refresh.png' class='float-right'  style='height: 30px;margin-top: -20px; margin-right: -1px;cursor: pointer;' ng-click='createCaptchaLogin()' id='ref'/>";
					var html = $compile($scope.txt)($scope);
                        angular.element(document.getElementById("captcha")).append(html);
				},1100);

				  });
								
		},

	doLogin: function(user) { 
		//console.log($scope.captoken);
	    $scope.errorMsg='';
		if($scope.form.login_form.$valid){

			if($scope.captchaCode==$scope.user.captcha){
				$scope.disabled_submit = true;

			$('#login_button').prop('disabled',true);
			var data = angular.copy(user);
			data.captchaToken = $scope.captoken;
			var lcount =  "";
			data.username = angular.copy(userModel.encryptCredentials(data.username));
			userModel.getLogCount(data.username).success(function(response){
				if (response==401) {
					$scope.disabled_submit = false;
					$('#login_button').prop('disabled',false);
					$scope.errorMsg = 'Please enter correct Username and try again';

					setTimeout(function(){
						$scope.createCaptchaLogin();
						$('#errorMsg').fadeOut('slow');
					}, 4000);
				}else {
					lcount=response+1;
					if(lcount > 10){
						var rem = lcount%10;
						lcount=rem;
					}else{
						lcount=lcount;
					}

					data.password = angular.copy(userModel.encryptCredentialss(data.password,lcount));
					
					userModel.doLogin(data).success(function(response){
						if (response == 'false'){
								$scope.disabled_submit = false;
								$('#login_button').prop('disabled',false);
								$scope.errorMsg = 'Please enter correct Username and Password and try again';
			
								setTimeout(function(){
									$scope.createCaptchaLogin();
									$('#errorMsg').fadeOut('slow');
								}, 4000);
							}else if(response.response == 'not varified'){
								//$scope.errorMsg ='';
					            localStorage.setItem('aadharVarificationData', JSON.stringify(response.Beneficiary));
					            //localStorage.setItem('loginError', 'Dear user, your password is wrong. Please confirm the below details to send the new password on mobile and email.');
					           $scope.aadhar_details = response.Beneficiary;
					            $state.go('pre_user_aadhar');
					             setTimeout(function(){
												    $('#errorMsg').fadeOut('slow')
												}, 4000);
					        }else if(response.response == 'varified'){
					          	//$scope.errorMsg ='';
					          	response.User.aadhar_id=angular.copy(userModel.encryptCredentials1(response.User.aadhar_id));
					          	response.User.email=angular.copy(userModel.encryptCredentials9(response.User.email));
					          	response.User.mobile_no=angular.copy(userModel.encryptCredentials8(response.User.mobile_no));
					          	response.User.registration_id=angular.copy(userModel.encryptCredentials(response.User.registration_id));
					          	var num = response.Beneficiary.id;
					          	var str = num.toString();
					          	response.Beneficiary.id=angular.copy(userModel.encryptBen(str));
					          	response.Beneficiary.aadhar_card_no=angular.copy(userModel.encryptCredentials9(response.Beneficiary.aadhar_card_no));
					          	var ben = response.Address.beneficiary_id;
					          	var benstr =ben.toString();
					          	response.Address.beneficiary_id=angular.copy(userModel.encryptBen(benstr));
								$scope.userDetails = { User:response.User, Beneficiary: response.Beneficiary, Address: response.Address}
								// $cookies.put('auth', JSON.stringify($scope.userDetails));
								sessionStorage.authData = JSON.stringify($scope.userDetails);
					            $scope.auth.authenticated = userModel.getAuthStatus();
					            $scope.auth.authlogindetails = userModel.getUserObj();
					            
					            $scope.profileinfo = {Beneficiary: response.Beneficiary, Address: response.Address,Caste_information:response.Caste_Information }
					            	var dben = angular.copy(userModel.decryptBen($scope.profileinfo.Beneficiary.id));
					          	
							     userModel.saveinfo($scope.profileinfo).success(function(response){
					            	
							    });
							     userModel.chekforgot(data.username).success(function(response){
							     	if(response=='false'){
										$state.go('dashboard');
							     	}else{
							     		$state.go('changepassword');	
							     	}
							     });
							     	 //$state.go('dashboard');
					            // setInterval(function(){
					            //      userModel.LogoutCookie().success(function(response){
					            //       //console.log(response);
					            //       if(response == "false"){
					            //         $scope.doLogout();

					            //         console.log(response+"---"+userModel.getAuthStatus());
					            //          // clearInterval();
					            //         window.location.reload();
					            //       }
					            //     });
					            //   }, 15000);
					           
					            // window.location.reload(); 

							}else if(response.attempts<3){
								//$scope.errorMsg ='';
								$scope.attempt=response.attempts;
								$scope.disabled_submit = false;
								$('#login_button').prop('disabled',false);
								$scope.errorMsg = 'You have entered wrong username or password '+$scope.attempt+'times';
									setTimeout(function(){
										$scope.createCaptchaLogin();	
									},1000);
								 setTimeout(function(){
												    $('#errorMsg').fadeOut('slow')
												}, 4000);
								//alert($scope.errorMsg);

							}else if(response.attempts==3){
								//$scope.errorMsg ='';
								$scope.attempt=response.attempts;
								$scope.disabled_submit = false;
								$('#login_button').prop('disabled',false);
								$scope.errorMsg = 'Due to three unsuccessful login attempts,your login has been blocked.';
									setTimeout(function(){
										$scope.createCaptchaLogin();	
									},1000);
								 setTimeout(function(){
												    $('#errorMsg').fadeOut('slow')
												}, 4000);
							    //alert($scope.errorMsg);
							}else if(response === 'blocked'){
			                  // $scope.errorMsg ='';
					           $scope.disabled_submit = false;
								$('#login_button').prop('disabled',false);
								$scope.errorMsg = 'Due to three unsuccessful login attempts,your login has been blocked.For further assistance,please contact admin.';
								// setTimeout(function(){
								// 	$scope.createCaptchaLogin();	
								// },1000);

								setTimeout(function(){
												    $('#errorMsg').fadeOut('slow')
												}, 4000);
								//alert($scope.errorMsg);
								$state.reload();
					        }
						});
				//console.log('out',data.password);
	          	//data.password = angular.copy(userModel.encryptCredentials(data.password));
 				}
			});
			// alert(data.password);
		}else{
			bootbox.alert("Submit correct captcha");
			// $scope.errorMsg = 'Submit correct captcha';

			// setTimeout(function(){
			//     $('#errorMsg').fadeOut('slow')
			// }, 4000);
			$scope.login_formSubmit = true;
		}
			

		}else{
			$scope.login_formSubmit = true;

		}

	}
	});
}]);