

app.controller('pagesController', ['$scope','$rootScope','$interpolate','$stateParams','$state','pagesModel','masterModel','$sce',
	function($scope,$rootScope,$interpolate,$stateParams,$state,pagesModel ,masterModel,$sce) {
	// Get single Post data 
	masterModel.getUploadsUrl().success(function(response){
      if (angular.equals(response.status,'success')) {
        $rootScope.uploadsUrl = response.upload_url;
      }
    }); 
	
	if($state.params.pagesname){
		if($state.params.pagesname=="Success Stories"){
			var key = localStorage.getItem('language_key');
			$scope.title =( key == 'mr')?'यशोगाथा':'Success Stories';
		    pagesModel.getPageData($state.params.pagesname,key).success(function(response){
				if(response.status){

					$scope.page = response.data;
					getStoryData($scope.page,key);
	
					$scope.$watch('common_vars.language_key', function (newValue, oldValue) {
						$scope.title = (newValue == 'mr')?'यशोगाथा':'Success Stories';
						if(newValue!='' && oldValue!=''){
							pagesModel.getPageData($state.params.pagesname,newValue).success(function(response){
								if(response.status){
									$scope.page = response.data;
									getStoryData($scope.page,newValue);	
								}				
							});
						}			 
					}, true);
				}
				setTimeout(function(){
					$('html, body').animate({
							scrollTop: ($('.content').offset().top - 300)
					}, 2000);
				}, 100); 

			});
		}
		else{
			pagesModel.getPageByName($state.params.pagesname ).success(function(response){
				$scope.pages = response;
				//console.log($scope.pages);
				var key = localStorage.getItem('language_key');
	
					if(key == 'mr'){
	
						$scope.htmldata = $sce.trustAsHtml($scope.pages.marathi_content);
						$scope.title = 	$scope.pages.marathi_title;
					}
					else{
						 $scope.htmldata = $sce.trustAsHtml($scope.pages.english_content);
						 $scope.title = 	$scope.pages.title;
					}
					
					$scope.$watch('common_vars.language_key', function (newValue, oldValue) {
						if(newValue=='mr'){
	
							$scope.htmldata =  $sce.trustAsHtml($scope.pages.marathi_content);
							$scope.title = 	$scope.pages.marathi_title;
							
						}else if(newValue=='en'){
							
							$scope.title = 	$scope.pages.title;
							$scope.htmldata =  $sce.trustAsHtml($scope.pages.english_content);
						}
					}, true);
					setTimeout(function(){
							$('html, body').animate({
									scrollTop: ($('.content').offset().top - 300)
							}, 2000);
					}, 100); 
				
			});
		}
	}

	function getStoryData(data,lang){
		data.forEach(element => {
			element.lang=lang
		});

		//console.log($rootScope.uploadsUrl);
		var list = $interpolate(`<div class="box"><div class="row main-section">
			<div class="col-sm-4">
					<span class="img" ><img style="height: 172px; width: 400px;" src="`+$rootScope.uploadsUrl+`user_uploads/cms/success_stories/{{image_url_alternate}}" alt="" class="img-responsive">
			</div>
			<div class="col-sm-8 success-story">
					<div class="inner">
							<p><label>{{lang=='mr'?'लाभार्थ्याचे नाव ':'Beneficiary name '}}-</label> <strong>{{beneficiary_name}}</strong></p>
							<p><label>{{lang=='mr'?'पत्ता ':'Address '}}-</label>{{address}}</p>
							<p><label>{{lang=='mr'?'व्यवसाय ':'Business '}}-</label>{{business}}</p>
							<p><label>{{lang=='mr'?'योजनेचे नाव ':'Plan Name '}}-</label>{{plan_name}}</p>
					</div>
			</div>
			</div>
			<p style="text-align: justify;border-bottom: 1px solid #f5ae05;margin: -10px 0 15px;padding: 10px 0px;">{{english_content}}</p>
			</div>`);

		$scope.htmldata = $sce.trustAsHtml(data.map(function(item) {
			return list(item);
		}).join(''));
	}

}]);