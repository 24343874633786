app.controller('schemeController', ['$scope','$cookieStore','$cookies','$state','userModel',  function($scope,$cookieStore,$cookies,$state,userModel){

	angular.extend($scope,{
		submitted:false,
		cp: {},
		form: {},

	});
	// console.log($scope.auth.authlogindetails); 

	angular.extend($scope,{
		
		
	});

	
	
}]);
