app.controller('userController', [ '$scope','$rootScope','$cookieStore','$cookies','$state','userModel','schemeModel','masterModel','$timeout','$location', function($scope,$rootScope,$cookieStore,$cookies,$state,userModel,schemeModel,masterModel,$timeout,$location){
		 $scope.search = {};
		 if(userModel.getAuthStatus()){
			    $scope.auth = {
			        authenticated: true,
			        authlogindetails: userModel.getUserObj(),
			        myAccountUrl: false
			      };
			     setInterval(function(){
			       userModel.LogoutCookie().success(function(response){
			        var path = $location.path();
             //   console.log(path);
                if(response.trim() == 'Fail' && path!="/home"){
	                bootbox.alert("Session Timeout : Please login again");
	                $cookieStore.remove("auth");
	               // console.log($cookies.get('auth'));
	                localStorage.clear();
	                sessionStorage.clear();
	                $scope.doLogout();
                }
			      });
			    }, 30000);
			 } else {
			 	sessionStorage.clear();
		      $scope.auth = {
		      authenticated: false,
		      authlogindetails: {},
		      myAccountUrl: false
		    };		      
		  }

		   
		  if ($state.current.name == 'sanctioned_letter') {
				userModel.viewSanctionedLetter($state.params.bene_id,$state.params.app_no).success(function(response){
					$scope.sanctioned_data = response;
					})
			}

			/*if ($state.current.name == 'productadd'){
				$scope.product_photos =[{"id":0,"product_photo":"","product_photo_alternate":""}];
				
			}*/
			masterModel.getDistricts(27).success(function(response) {
	            	$scope.districts = response;
	            	$timeout(function(){
					      	angular.element('#district_id').selectpicker('refresh');
					      },300);
		        
	    		});
			
		  if ($state.current.name == 'product_management') {
				userModel.productlist().success(function(response){
					$scope.productlist = response;
					setTimeout(function(){
				               $('html, body').animate({
				                        scrollTop: ($('.content').offset().top - 300)
				                    }, 2000);
				                }, 100); 
				});

			}
			/*if ($state.current.name == 'product_details') {

				var baseurl=$location.$$absUrl.replace($location.$$url, '')
				var myurl=baseurl.replace('#','');
				var myurl1=myurl.replace('https://','');
				if(myurl1=='localhost/udyog-new/' || myurl1=='uat-udyog.mahaswayam.in/'){
					var GlobalUrl='uat-udyog.mahaswayam.in'
				}else if(myurl1=='udyog.mahaswayam.gov.in/'){
					var GlobalUrl='udyog.mahaswayam.gov.in'
				}
				$scope.s3Url='https://s3.ap-south-1.amazonaws.com/'+GlobalUrl+'/user_uploads/product_images/';
		
				userModel.productdetails($state.params.product_id).success(function(response){
					$scope.displayProduct = response.displayProduct;
					$scope.productphotos = response.productphotos;
				});

			}*/
			if ($state.current.name == 'dashboard') {
				$scope.shownewdisbursal=false;
				$scope.compare_var=false;
				$scope.compare_var1=false;
				$scope.compare_valid=false;
				$scope.multipleclaim=false;
				$scope.loader = true;
				var stc = $location.search().stc;
				if(typeof stc != 'undefined'){

					if(stc == '000'){
						userModel.updateloidetails($location.search().app_no,$location.search().bene_id).success(function(response){
							$scope.loader=true;
							if (response.status == 'success') {
								bootbox.alert({
							        message: 'Your Transaction is Successful and LOI is revalidated successfully ! CRN :'+$location.search().crn+' Please Save this CRN for future reference.',
							        /*callback: function () {
							        	$state.go('revalidate_loi', { 'app_no':$location.search().app_no});
							            //$state.go('revalidate_loi('+$location.search().app_no+')');
							        }*/
								  });
								$state.reload();
								
							}
					    });
						
					} else {
						bootbox.alert({
							        message: 'Transaction unsuccessful ! CRN :'+$location.search().crn+' Please try again after sometime.',
							        callback: function () {
							            $state.go('dashboard');
							        }
								  });
					}
				}
				//$scope.require_doc='';

				$timeout(function(){
					      	angular.element('#month_names').selectpicker('refresh');
					      },300);
        		 
        
				schemeModel.getSchemes().success(function(response){
					$scope.schemes = response;
					setTimeout(function(){
				               $('html, body').animate({
				                        scrollTop: ($('.content').offset().top - 300)
				                    }, 2000);
				                }, 100); 
				});
					$scope.search.productflag='Beneficiary';
					$scope.totalProducts = 0;
		            $scope.productPerPage = 25;
		            getResultsPageList(1);
		            $scope.pagination = {
		                current: 1
		            };
		            $scope.pageChangedList = function(newPage) {
		                getResultsPageList(newPage);
		              
		            };
		            function getResultsPageList(pageNumber) {
		                $scope.search.offset = pageNumber;
		                schemeModel.getProductList($scope.search).success(function(response){
		                            $scope.productDetails = response.products;
		                            $scope.totalProducts = response.totalProducts; 
		                            $timeout(function(){
		                                angular.element('.selectpicker').selectpicker('refresh');
		                            },300);     
		                });
		            }
				 var str = angular.copy(userModel.decryptBen($scope.auth.authlogindetails.Beneficiary.id));
				//var str = num.toString();
				ben_id=angular.copy(userModel.encryptCredentials(str));
				schemeModel.applications(ben_id).success(function(response){

					$scope.userApplication = response;
					for(var i=0; i < $scope.userApplication.application.length; i++) {
						if($scope.userApplication.application[i].scheme_id=='12'){

								$scope.userApplication.application[i]['GL_Statuss'] = 'Yes';
						}
						else{
								$scope.userApplication.application[i]['GL_Statuss'] = 'No';
						}
						
					}
						//console.log($scope.userApplication.application[0]);
					if($scope.userApplication.claims!=undefined){

						if($scope.userApplication.claims.length > 0){
							$scope.add_product_buttton = 'Yes';
						}else{
							$scope.add_product_buttton = 'No';
						}
					}else{
						$scope.add_product_buttton = 'No';
					}
					
					$scope.loader = false;
				if($scope.userApplication.application.length>0){
					/*if($scope.userApplication.application[0].revalidate_loi==1){
                      $scope.revalidate_loi=true;
					}else{
                      $scope.revalidate_loi=false;
					}

					if($scope.userApplication.application[0].revalidate_loi_pay==1){
                      $scope.revalidate_loi_pay=true;
					}else{
                      $scope.revalidate_loi_pay=false;
					}*/
				//if(!$scope.userApplication){

					schemeModel.getAppRequiredDoc($scope.userApplication.application[0].applcation_no).success(function(response){
						//alert("sanction");
						if (response.status == 'success') {
							if(response.data.length>0){
								
								$scope.require_doc = response.data;
								
								if ($scope.require_doc[0].loan_type=='BOTH') {
									if(parseInt($scope.require_doc[0].bank_Disbursal_amt_both_term)<parseInt($scope.require_doc[0].bank_sanctioned_loan_both_term)){
										$scope.shownewdisbursal=true;
										
									}

								}else{

									if(parseInt($scope.require_doc[0].bank_Disbursal_amt)<parseInt($scope.require_doc[0].bank_sanctioned_loan)){
										$scope.shownewdisbursal=true;
										
									}
								}
								
							}
							else{
								
							  
							}
							
						}
					});
				}

				});
				var bendec = userModel.decryptBen($scope.auth.authlogindetails.Beneficiary.id);
				var benNum = parseInt(bendec);
                schemeModel.checkrepeatclaimform(benNum).success(function(response){
                	$scope.repeatclaimformdata=response.data;
                	//$scope.repeatclaimformdata
	                	if(response.data.length>=1){
		                	angular.forEach($scope.repeatclaimformdata, function(value, key){
		                		 
		                		  if(value.status != "" || value.stage>1){
		            				 $scope.showblankform=true;
							      }
							});
	                	}else if(response.data.length==0){
	                		$scope.showblankform=true;
	                	}
                
					
				}); 
				masterModel.getDistricts(27).success(function(response){
					$scope.districts = response;
					setTimeout(function() {
						angular.element('#district').selectpicker('refresh');
					}, 10);
					
			    });
				
			}


			if($state.current.name == 'revalidate_loi'){
                $scope.lobarray = ['','Manufacturing','Trading','Service','Other'];
				schemeModel.applicationData($state.params.app_no).success(function(response){
					//console.log("applicationData:"+JSON.stringify(response.directors));
					$scope.eduarray = ['','Below SSC','SSC','HSC','Graduation','Post-Graduation'];
					$scope.revalidateuserdetails = response.application;
					$scope.groupDisabiltyCertificates=response['groupDisabiltyCertificates'];
					$scope.userApplicationDirectors = response.directors;
					$scope.userApplicationguarantor = response.guarantor;
					setTimeout(function(){
				               $('html, body').animate({
				                        scrollTop: ($('.content').offset().top - 300)
				                    }, 2000);
				                }, 100); 
					masterModel.getTalukas($scope.revalidateuserdetails.registered_office_district).success(function(response){
		
			           $scope.reg_talukas = response;
			           //console.log($rootScope.reg_talukas);
	                });
	                masterModel.getCities($scope.revalidateuserdetails.registered_office_taluka).success(function(response){
		
						$scope.reg_cities = response;
						//console.log($rootScope.reg_talukas);
	 				});
	 				masterModel.getTalukas($scope.revalidateuserdetails.project_location_district).success(function(response){
		
						$scope.proj_loc_talukas = response;
						//console.log($rootScope.reg_talukas);
	 				});
	 				masterModel.getCities($scope.revalidateuserdetails.project_location_taluka).success(function(response){
		
						$scope.proj_loc_cities = response;
						//console.log($rootScope.reg_talukas);
	 				});
					
				});
				
				if($scope.auth.authlogindetails.Beneficiary.photo){
					
					//console.log($rootScope.GlobalUrl);
					var GlobalUrl = $rootScope.GlobalUrl.replace("https://", "");
					$scope.img = 'https://s3.ap-south-1.amazonaws.com/'+GlobalUrl+'user_uploads/Jobseeker/Profile/'+$scope.auth.authlogindetails.Beneficiary.photo;
				}else{
					$scope.img = 'public/img/dummy.jpg';
				}

				schemeModel.getDistricts().success(function(response){
			
				if (response.status == 'success') {
					$scope.districtName = response.data;
			
				   }
			    });

   
			}
			if ($state.current.name == 'profile' || $state.current.name == 'edit_profile') {

				$scope.loader = true;

				var adhar=$scope.auth.authlogindetails.Beneficiary.aadhar_card_no;
				
				userModel.profileData(adhar).success(function(response){
                   // console.log($scope.auth.authlogindetails);
						if(response){
							$scope.loader = false;
							$scope.profileinfo = response[0];
							//$scope.profileinfo.mother_tongue=$scope.auth.authlogindetails.Beneficiary.mother_tongue_id;
							var date = $scope.profileinfo.dob;
							var arr = date.split('-');
							$scope.year = arr[0];
							$scope.month = arr[1];
							$scope.day = arr[2];
							$scope.profile_pic = response[0].photo;
							$scope.profileinfo.is_fathers_name=1;
						}
				setTimeout(function(){
				               $('html, body').animate({
				                        scrollTop: ($('.content').offset().top - 300)
				                    }, 2000);
				                }, 100); 
				setTimeout(function() {
						angular.element('#gender1').selectpicker('refresh');
					}, 10);

				setTimeout(function() {
						angular.element('#marital_status1').selectpicker('refresh');
					}, 10);
				// masterModel.getCountry().success(function(response) {
				// 	// body...
				// 	$scope.countries = response;
				// 	setTimeout(function() {
				// 		angular.element('#country').selectpicker('refresh');
				// 	}, 10);
				// });
				// 
				masterModel.getGlobalUrl().success(function(response){
			        if (angular.equals(response.status,'success')) {
			            $rootScope.GlobalUrl = response.global_url;
			            $rootScope.rojgarUrl = $rootScope.GlobalUrl.replace("https://", "");
			            // alert($rootScope.rojgarUrl)
			        }
		    	 });
				masterModel.getStates(1).success(function(response){
					$scope.states = response;
					setTimeout(function() {
						angular.element('#state').selectpicker('refresh');
					}, 10);
				});

				masterModel.getDistricts($scope.profileinfo.states_id).success(function(response){
				$scope.districts = response;
				setTimeout(function() {
					angular.element('#district').selectpicker('refresh');
				}, 10);
				
			     });

				masterModel.getTalukas($scope.profileinfo.districts_id).success(function(response){
				$scope.talukas= response;
				setTimeout(function() {
					angular.element('#taluka').selectpicker('refresh');
				}, 10);
			    });

			    masterModel.getCities($scope.profileinfo.talukas_id).success(function(response){
				$scope.cities = response;
				setTimeout(function() {
					angular.element('#city').selectpicker('refresh');
				}, 10);
			    });

			    masterModel.getNationalities().success(function(response){
					$scope.nationality = response;
					setTimeout(function() {
						angular.element('#nationality').selectpicker('refresh');
					}, 10);
				});

				masterModel.getReligions().success(function(response){
					$scope.religions = response;
					setTimeout(function() {
						angular.element('#religion').selectpicker('refresh');
					}, 10);
				});

				masterModel.getCaste().success(function(response){
					$scope.main_castes = response;
					//console.log(response);
					setTimeout(function() {
						angular.element('#main_caste').selectpicker('refresh');
					}, 10);
				});
				masterModel.getSubCastes($scope.profileinfo.castes_id).success(function(response){
					$scope.sub_castes = response;
					//------console.log(response);
					setTimeout(function() {
						angular.element('#sub_caste').selectpicker('refresh');
					}, 10);
				});
				masterModel.getmothertongue().success(function (response) {
					$scope.mother_tongue_list=response.data;
					//-------console.log(response.data);
					setTimeout(function() {
						angular.element('#mother_tongue').selectpicker('refresh');
					}, 10);
					});
                var id='';
                var ben_id = userModel.decryptBen($scope.auth.authlogindetails.Beneficiary.id);
                userModel.getTicketDetail(id, ben_id, "profile").success(function(response){
                    try {
                      if(response.status){
                        $scope.ticketData = response.data;
                        //console.log($scope.ticketData);
                         $scope.loader=false;
                      }else{
                        //console.log("error accourd"); 
                        $scope.loader=false;       
                      }
                    } catch (error) {
                      //console.log("try error: ",error);  
                      $scope.loader=false;
                    }
                    });
					
				});
				

				}


			angular.extend($scope,{
                filevalidation : true,
				imageCountArr : [],
				claimformss:[],
				//disbursal_date:"",
			currentPage: 1,
			claimcounter:0,
			itemsPerPage: 30,	
            addprod:false,
			pi_form_submitted: false,
			edit_form_submitted: false,
			contact_form_submitted: false,
			add_product_submitted: false,
			photos: {},
			AddProduct: {},
			revalidateloi:{},
			form_submitted: false,
			stepsModel: [],
			imges: [],
			claimformlen:0,
			claimform:{
				additional_disbursal:false,
				business_photo:"",
				business_photo_alternate:""
			},
			currentYear:new Date().getFullYear(),
			currentMonth:new Date().getMonth()+1,
			currentDay:'01',
			currentendDay:new Date(new Date().getFullYear(),new Date().getMonth()+1, new Date().getDate()).getDate(),
			multipleclaim:false,
			noImg: false,
			compare_var:false,
			compare_var1:false,
			compare_valid:false,
			invalidmonnam:[],
			checkmongreater:[],
			invalidemival:[],
			invalidmonth:false,
			duplmon:false,
			sixmoncompare:false,
            claimr:{
              emi_amount:0	
            },
			additional_disbursal:2,
			pic:[],
			disableemimon:true,
			monthar1:[],
			monthar:[],
			disablesubmitbtn:false,
			revalidated_loi:false,
			product_photoError : [],
            gender: [{'value':'M','option':'Male'},{'value':'F','option':'Female'}
            ,{'value':'T','option':'Transgender'}],
            category:[{'value':'1','option':'Product'},{'value':'2','option':'Service'}],
            product_photos:[{'id':'0','product_photo':'','product_photo_alternate':''}],
            no_of_months:[{'value':2,'option':'2'},{'value':3,'option':'3'},{'value':4,'option':'4'},{'value':5,'option':'5'},{'value':6,'option':'6'},{'value':7,'option':'7'},{'value':8,'option':'8'},{'value':9,'option':'9'},{'value':10,'option':'10'},{'value':11,'option':'11'},{'value':12,'option':'12'}],
			month_names:[{'value':1,'option':'January'},{'value':2,'option':'February'},{'value':3,'option':'March'},{'value':4,'option':'April'},{'value':5,'option':'May'},{'value':6,'option':'June'},{'value':7,'option':'July'},{'value':8,'option':'August'},{'value':9,'option':'September'},{'value':10,'option':'October'},{'value':11,'option':'November'},{'value':12,'option':'December'}],
			// claim: {
			// 	no_of_months:parseInt(2),
			// },
            maritalStatus: [{'value':'1','option':'Married'},{'value':'2','option':'Unmarried'},{'value':'3','option':'Widow'}
            ,{'value':'4','option':'Widower'},{'value':'5','option':'Divorcee'},{'value':'6','option':'Not Specified'}],
            claimrows:[{"id":0,"month_names":"","date_of_emi":"","emi_amount":"","interest_amount":"","proof_of_upload":"","proof_of_upload_alternate":""}
	  		 			],


	  		formfields: {
permanent_address:12,
taluka:13,
city:14,
education_details:15,
do_you_have_pan:16,
pan_no:17,
are_you_physicallay_handicap:18,
name_of_the_project:19,
final_product:20,
business_address:21,
amount_of_bank_loan_required:22,
resident_of_maharashtra_upload:23,
resident_of_maharashtra_title:24,
aadhar_card_upload:25,
aadhar_card_title:26,
income_certificate_title:27,
income_certificate_upload:28,
disability_certificate_upload:29,
disability_certificate_title:30,
other_document_upload:31,
other_document_title:32,
company_name_scheme_11:33,
company_registration_no_scheme_11:34,
type_of_company_scheme_11:35,
group_disability_scheme_11:36,
upload_group_disability_scheme_11:37,
company_director_table_scheme_11:38,
details_of_shareholders_upload:39,
company_tan_no:40,
company_pan_card:41,
registered_office_address:42,
business_name:43,
business_address_scheme_11:44,
district_scheme_11:45,
taluka_scheme_11:46,
city_scheme_11:47,
final_product_scheme_11:48,
amount_loan_scheme_11:49,
authorized_person_resolution_title:50,
authorized_person_resolution_upload:51,
registration_copy_title:52,
registration_copy_upload:53,
authorized_person_aadhar:54,
authorized_person_aadhard_upload:55,
account_holder_name:56,
bank_name:57,
branch_name:58,
ifsc_code:59,
aadhar_link_account:60,
type_of_loan:61,
loan_sanctioned_by_bank:62,
disbursal_amount:63,
rate_of_interest:64,
tenure:65,
date_of_disbursal:66,
business_address_sanction:67,
disbursal_proof_upload:68,
upload_bank_sanction_letter:69,
bank_emi_schedule_5_yrs:70,
project_report:71,
district:72,
current_address:73,
cast_leaving_certificate_title:79,
cast_leaving_certificate_upload:80
},	 		
				addproductphoto:function(){
					var blankRow = {'id':'0','product_photo':'','product_photo_alternate':''};
		 			$scope.product_photos.push(blankRow);
				},
				getTheMultipleFilesProduct: function ($files, flag, index){
					var product_photoError=true;

					for (var i = 0; i < $scope.product_photoError.length; i++) {
			                if($scope.product_photoError[i]){
			                    var product_photoError=false;
			                    break;
			                }else{
			                    var product_photoError=true;
			                }
			            }
			            if($scope.formdata == undefined){
						 		$scope.formdata = new FormData();
						 }
			            if(flag == 'product_photo') {
	
					 		$scope.product_photoErr= '';
					 		
					 		var fileExt = ["image/png","image/jpg","image/jpeg",'image/bmp'];
					 		if(fileExt.indexOf($files[0].type) != -1) {
						 		if($files[0].size <= 10485760){
						 				$scope.$apply(function(){
										$scope.product_photos[index].product_photo = angular.copy($files[0].name);
										// console.log($scope.resident_docs[index]);
										// alert("residence");
						 				$scope.formdata.append('product_photo_file'+index,$files[0]);
									});
								} else {
									$scope.product_photos[index].product_photo = '';
									$scope.product_photoErr[index] = "Please upload document less than 10MB size!";
						 		}
						 	} else {
						 		$scope.product_photoErr[index] = "Unsupported file format.";
						 	}			
					 	}
				},

				viewScheme: function(id){
					$state.go('schemes',{id:id});
					},

	            viewApplications: function(app_no,view_status,scheme_id,fund_id,save_as_draft,task_stage_id){
	 				//console.log(view_status);
	               	//console.log(task_stage_id);
	               if(scheme_id!=12){
	               	if (save_as_draft==0 && task_stage_id==1) {

						
						userModel.getDistrict(fund_id).success(function (response){
							angular.forEach($scope.districts, function (key,value) {
							if (key.value == response) {
								$state.go('schemeApply',{'scheme_id':scheme_id,'fund_id':fund_id,'district_name':key.option,'district_id':key.value});
								}
							})
						})
						
					}else{
						$state.go('candidate_applications',{app_no:app_no});
					}

	               }else{
	               	if (task_stage_id==28 && view_status!='Submit'){
						userModel.getDistrict(fund_id).success(function (response){
							angular.forEach($scope.districts, function (key,value) {
							if (key.value == response) {
								$state.go('schemeApply',{'scheme_id':scheme_id,'fund_id':fund_id,'district_name':key.option,'district_id':key.value});
								}
							})
						})
						
					}else if(task_stage_id==34 && view_status!='Submit' && view_status=='Legal Document'){
						userModel.getDistrict(fund_id).success(function (response){
							angular.forEach($scope.districts, function (key,value) {
							if (key.value == response) {
								$state.go('LegalDocument',{'scheme_id':scheme_id,'fund_id':fund_id,'district_name':key.option,'district_id':key.value});
								}
							})
						})
					}else{
						$state.go('candidate_applications',{app_no:app_no});
					}

	               }
                   

				},
				updateloidetails:function(revalidatedata){
					//alert(revalidatedata['scheme_apl_id']);
                    $scope.loader=true;
                    if($scope.form.revalidloi_form.$valid){
                      
                       if ($scope.residence_docErr) {
					 		$scope.loader=false;
							$scope.revalidateloi_submit = true;
							$scope.residence_docErr = "Please upload document less than 10MB size!";
							return;
						}
						if ($scope.aadhar_photoErr) {
					 		$scope.loader=false;
							$scope.revalidateloi_submit = true;
							$scope.aadhar_photoErr = "Please upload document less than 10MB size!";
							return;
						}
						if ($scope.income_certificateErr) {
					 		$scope.loader=false;
							$scope.revalidateloi_submit = true;
							$scope.income_certificateErr = "Please upload document less than 10MB size!";
							return;
						}
						if ($scope.other_docErr) {
					 		$scope.loader=false;
							$scope.revalidateloi_submit = true;
							$scope.other_docErr = "Please upload document less than 10MB size!";
							return;
						}
						var ben_id = userModel.decryptBen($scope.auth.authlogindetails.Beneficiary.id);
						var dec_ben_id=parseInt(ben_id);
						//$scope.revalidateloi.bene_id = angular.copy(parseInt($scope.auth.authlogindetails.Beneficiary.id));
						$scope.revalidateloi.bene_id = angular.copy(dec_ben_id);
						$scope.revalidateloi.app_no = angular.copy($state.params.app_no);
						var postData = ''
						var contentType = 'application/json';
						$scope.revalidateloi["reavalidateloi"]=revalidatedata;
						if($scope.formdata != undefined){
								
								 angular.forEach($scope.revalidateloi,function(key, value) {
				                  if(value=='reavalidateloi')
							        {
							          	key = JSON.stringify(key);
							        }
				                  $scope.formdata.append(value, key);
				                })
								postData = $scope.formdata;

                  			
								contentType = undefined;
							}else{
								postData = $scope.revalidateloi;
							}
							userModel.updateloidetails(postData,contentType).success(function(response){
							$scope.loader=true;
							if (response.status == 'success') {
								$state.go('dashboard');
								$scope.revalidatesuccessmsg="LOI  was revalidated successfully";
								setTimeout(function(){
									    $('#revalidatesuccessmsg').fadeOut('slow')
									}, 3000);
							}
						    });		
                    }else{
                       $scope.revalidateloi_submit = true;
                       $scope.loader=false;
                    }
				},
				check_loi_validity:function (loi_gen_date) {
					
			      var three_month = new Date(loi_gen_date).add(3).month();

			     // var new_three_month = new Date(three_month).add(-3).days();

			      var currDate = new Date();

			      if(three_month >= currDate){
			      	alert(123);
			      }

			      // var n = new_three_month.getFullYear();
			      // var d = new_three_month.getDate();
			      // var m = new_three_month.getMonth();

			      // console.log(n, d, m);

			      //console.log(loi_gen_date);
			      //console.log(three_month);
			      //console.log(new_three_month);
			      //console.log(six_month);
			      /*var todaydt=new Date();
			      console.log(todaydt);
			  
				  	if(todaydt>loi_generated){
					 $scope.sixmoncompare=true;
					}*/
				},
		
				SetNoOfMonths:function(){
					//alert($scope.multipleclaim);
					$scope.multipleclaim=true;
					// $scope.claim=[];
					// $scope.claim.no_of_months=$scope.no_of_months[0].value;
					// $timeout(function(){
					//       	angular.element('#no_of_months').selectpicker('refresh');
					//       },300);
					if($scope.claimrows.length==1)
					$scope.addclaimRow(2);
					//$scope.claimcounter=2;
				},
				DeleteclaimRow:function (arg) {
					//alert(arg)
					$scope.claimrows.splice(arg-1,1); 
				},
				clrclaimform:function(){
                   
                   $scope.claimform.proof_of_upload='';
                   $scope.claimform.business_photo='';
                   $scope.claimform.business_photo_alternate='';
                   $scope.claimform.additional_disbursal=false;
                   $scope.claim_submit=false;
                   $scope.addclaimRow(1,'ind');
                   $scope.multipleclaim=false;
                   $scope.invalidmonnam = false;
                   $scope.invalidmonth=false;
                   $scope.claimformlen=0;
                   $scope.monthar=[];
                   $scope.compare_var1='';
                   
                 //  console.log($scope.monthar);
				},
				setclaim_form:function(){
				},
				comparevalEmi:function(scope, index){
					$scope.validcompare(scope.claimrows[index].emi_amount,scope.lineForm.emi_amount);
					$scope.compare(scope.claimrows[index].emi_amount,scope.claimrows[index].interest_amount,scope.lineForm.emi_amount);

				},
				getInterestval:function(scope, index){
				    $scope.compare(scope.claimrows[index].emi_amount,scope.claimrows[index].interest_amount,scope.lineForm.interest_amount);
				},
                validateBlanceAmt:function(interest_amount,balanceClaimAmount){
                    //console.log(interest_amount>balanceClaimAmount);
                    if (interest_amount>balanceClaimAmount) {
                        $scope.validateBlanceAmt = true;
                        //console.log("true");
                    }else{
                       $scope.validateBlanceAmt = false; 
                    }
                },
				
                compare :function(loan_amt, disbursal_amt,element) {
					// body...
					if(loan_amt && disbursal_amt ){
						element.$setValidity('compare', 
                         parseFloat(disbursal_amt)<parseFloat(loan_amt));

					 //    }
						// if(disbursal_amt_copy>loan_amt_copy){
						// 	$scope.compare_var = true;
						// }else{
						// 	$scope.compare_var = false;
						// }
				    }
				},
				setDisbursedtotal :function(addition_disbursal) {
					$scope.disbursed_total=parseInt($scope.disbursed_loan_amount);
					//console.log($scope.disbursed_total);
						$scope.claimform.disbursed_loan_amt_new="";
						$scope.claimrows=[];
						var blankRow = {"id":0,"month_names":"","date_of_emi":"","emi_amount":"","interest_amount":"","proof_of_upload":"","proof_of_upload_alternate":""};
				  	
		  				$scope.claimrows.push(blankRow);
		  			    $scope.compare_valid = false;	
		  			    $scope.invalidmonnam = false;	
		  			    $scope.invalidmonth=false;	
		  			    $scope.compare_var1='';	
					   // console.log($scope.disbursed_total);

					},
				compare1 :function(loan_amt, disbursal_amt,sanc_amt) {
					
					if(loan_amt && disbursal_amt ){
						var loan_amt_copy = parseInt(loan_amt);
						var disbursal_amt_copy=parseInt(disbursal_amt);
						var sanction_amt_copy=parseInt(sanc_amt);
					    $scope.disbursed_total=parseInt(loan_amt)+parseInt(disbursal_amt);
						//alert($scope.disbursed_total);
						$scope.disbursed_diff=parseInt(sanction_amt_copy)-parseInt(loan_amt);
						if($scope.disbursed_total>sanction_amt_copy){
							$scope.compare_var1 = true;
						}else{
							$scope.compare_var1 = false;
						}
						
				    }
				},
				validcompare:function(emi_amt,element){
                    // console.log(emi_amt);
                   
                     var emi_amt_copy = parseFloat(emi_amt);
					 var disbursal_amt_copy=parseFloat($scope.disbursed_total)*0.01;
					 
						element.$setValidity('validcompare', 
                             emi_amt_copy<=disbursal_amt_copy)
                        if(element=='noindex'){

							 if(emi_amt_copy>disbursal_amt_copy){
							 	//alert("if");
									$scope.compare_valid = true;
								}else{
								   // alert("else");
									$scope.compare_valid = false;
								}
                        }
					//}	
                    
				},
				addclaimRow: function(claimcounter,flag) 
				  { 
				  	//alert(claimcounter);
                    $scope.claimcounter=claimcounter;
                    if(claimcounter==1 && flag=='ind'){
                    	$scope.claimrows=[];
                    	$scope.multipleclaim=false;
                    }
				  	if($scope.claimrows.length==6)
				  	{
				  		return;
				  	}
				  	
				  	var blankRow = {"id":0,"month_names":"","date_of_emi":"","emi_amount":"","interest_amount":"","proof_of_upload":"","proof_of_upload_alternate":""};
				  	
		  					$scope.claimrows.push(blankRow);
						
		 		  },
		 		  getRejectedApplicationDetails:function (app_no) {
		 		 	 $scope.loader=true;
		 		 	 schemeModel.getrejectedapplicationdetails(app_no).success(function(response){
		 		 	 		//console.log(response);
		 		 	 		$scope.rejectdirectorcomment=response.rejectdirectorcomment;
		 		 	 		$scope.rejectcommentauditor=response.rejectcommentauditor;
		 		 	 		/*$scope.applicationdata=response.applicationdata;*/
		 		 	 		var ben_id = userModel.decryptBen($scope.auth.authlogindetails.Beneficiary.id);
		 		 	 		var dec_ben_id = parseInt(ben_id);

		 		 	 		schemeModel.applications(dec_ben_id).success(function(response){
									//console.log('747');
									$scope.userApplication = response;
							});		
		 		 	 	
		 				$scope.loader=false;
 		 	 			angular.element('#rejectapplicationmodal').modal('show');
		 		 	 }).error(function (error) {
		 		 	 	$scope.loader=false;
		 		 	 });

		 		 },
		 		  getIncompleteDocumentDetails:function (app_no, task_stage_id='') {
		 		 	 $scope.loader=true;
		 		 	// console.log(app_no); return;	
		 		 	userModel.getIncompleteDocumentDetails(app_no, task_stage_id).success(function(response){
		 		 		//console.log(response.data); return;
		 		 	 		$scope.incompleteDocComments=response.data;		
		 		 	 	//alert("fh");
		 				$scope.loader=false;
 		 	 			 angular.element('#viewincdoccomments').modal('show');
		 		 	 }).error(function (error) {
		 		 	 	$scope.loader=false;
		 		 	 });

		 		 },
		 		 getApplicationDataLegal:function (scheme_id,bene_id) {
		 		 	  $scope.loader=true;
		 		 	
		 		 	schemeModel.getSchemeApplicationDataLegal(scheme_id,bene_id).success(function(response){
				 		 	 
				 		 	 if(response[2]!=null && parseInt(response[2].length)!=0)
							{
							
							   if(response[2]['legal_docs']!=null && parseInt(response[2]['legal_docs'].length)!=0)
							 		$scope.legal_docs=response[2]['legal_docs'];
							 	else
							 	$scope.legal_docs =[{"id":0,"title":"","legal_doc":"","legal_doc_alternate":""}];
							 
							   				 	 
							}
		 				$scope.loader=false;
 		 	 			 angular.element('#uploadlegaldoc').modal('show');
		 		 	 }).error(function (error) {
		 		 	 	$scope.loader=false;
		 		 	 });
		 		 },
		 		 getLoiRejctedComment:function (reject_data) {
		 		 	 $scope.loader=true;
		 		 	// console.log(app_no); return;	
		 		 	userModel.getLoiRejctedComment(reject_data).success(function(response){
		 		 		//console.log(response.data); return;
		 		 	 		$scope.loiRejectComments=response.data;		
		 		 	 	//alert("fh");
		 				$scope.loader=false;
 		 	 			 angular.element('#viewloirejectcomments').modal('show');
		 		 	 }).error(function (error) {
		 		 	 	$scope.loader=false;
		 		 	 });

		 		 },
		 		 uploadnewsanctionletter:function(sanctionrejectcount,app_no,flag,action_flag){
                    //console.log('uploadnewsanctionletter')
                    $('.modal-backdrop').remove();
                     window.scrollTo(0, 0);
                    $state.go('upload_sanction_letter',{app_no:app_no});
                    $scope.dataavailable=2;
                    $scope.requiredoc="";
                    
		 		 },
               
		 
        getClaimDetaails:function (app_no, loan_type) { 
        	$scope.ticketData={};
            if ($scope.userApplication.application[0].loan_type=="BOTH" ||
            $scope.userApplication.application[0].loan_type=="Term Loan" ||
            $scope.userApplication.application[0].loan_type=="Other") {
            	//console.log($scope.userApplication.sanction[0].disbursal_date);
            	$scope.disbursalDate=$scope.userApplication.sanction[0].disbursal_date;
            	//console.log($scope.disbursalDate);

            	var d = new Date($scope.disbursalDate);
				d.setMonth(d.getMonth() + 6	);
				d.toLocaleDateString();
				var month = '' + (d.getMonth() + 1),
			        day = '' + d.getDate();			
			    if (month.length < 2) 
			        month = '0' + month;
			    if (day.length < 2) 
			        day = '0' + day;

				$scope.sixMonthDate = d.getFullYear() + "-" + month + "-" + day;
				//console.log($scope.disbursalDate);
				//console.log($scope.sixMonthDate);
                if ($scope.userApplication.sanction[0].disbursal_date>=$scope.userApplication.application[0].loi_gen_date) {
                    $scope.disbursal_date=$scope.userApplication.sanction[0].disbursal_date;
                }else{
                    $scope.disbursal_date=$scope.userApplication.application[0].loi_gen_date;
                }
            }else if ($scope.userApplication.application[0].loan_type=="OD" || $scope.userApplication.application[0].loan_type=="CC"){

                if ($scope.userApplication.application[0].sanctioned_date>=$scope.userApplication.application[0].loi_gen_date) {
                    $scope.disbursal_date=$scope.userApplication.application[0].sanctioned_date;
                }else{
                    $scope.disbursal_date=$scope.userApplication.application[0].loi_gen_date;
                }
            }
            //$scope.instalment_period=11;
            //console.log($scope.userApplication.sanction[0]);

            //only 3 claims can apply condition
            /*if ($scope.disbursal_date<=$scope.userApplication.checkDate) {
                    $scope.disbursal_date=$scope.userApplication.checkDate;
                }*/
            $scope.loader=true;
            $scope.viewonly=0;
            //console.log(app_no)
            if (loan_type=='BOTH') {
                loan_type="BOTH-Term";
            }

            // alert(loan_type);
            schemeModel.getclaimformdetails(app_no, loan_type).success(function(response){
                //  		$scope.counter=0;
                //console.log(response.balanceClaimAmt);
                var claimedit=[];
                var i=0;
                angular.forEach(response.data, function(value, key){
               		var inputJSON = {
				       "created_date": value.created_at,
				       "current_time": new Date()
				    };
				    var startDate= new Date(inputJSON.created_date);
				    var endDate = new Date(inputJSON.current_time);
				    var diff = endDate.getTime() - startDate.getTime();
				    var days = Math.floor(diff / (60 * 60 * 24 * 1000));
				        // var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
				        // var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
				        // var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
                    value.day=days;
                    value.date_of_emi=value.emi_payment_date;
                    var date = new Date(value.emi_month);
                    value.month_names=(date.getMonth()+1)+"-"+date.getFullYear();

                    if (value.day<1 && value.status_id==0) {
                        claimedit[i] =value;
                        i++;
                    }

                 });
                $scope.claimedit = claimedit;

                //console.log($scope.require_doc[0].loan_type);

               $scope.claimform.holdClaimCount=response.holdClaimCount;
               $scope.claimform.claimProcessCount=response.claimProcessCount;

                // $scope.claimform.holdClaimCount=0;
                // $scope.claimform.claimProcessCount=0;
                $scope.claimform.declarationCount=response.declarationCount;
                $scope.instalment_period=response.instalment_period_set;
                $scope.cgstmeCount = response.cgstmeCount;
              //  console.log($scope.cgstmeCount);
                $scope.claimform.totalClaim=response.totalClaim;
                $scope.additionalSanction = response.additionalSanction;
                $scope.claimform.additional_disbursal=false;
                $scope.claimform.disbursed_loan_amt_new="";
                $scope.claimform.disbursal_proof="";
                $scope.claimform.disbursal_proof_alternate="";

                $scope.sanctionedTenure=$scope.require_doc[0].tenure;
                $scope.claimlength=response.data.length;
                    if ($scope.require_doc[0].loan_type=='CC') {
                        $scope.approveFlag=true;
                    }else {
                        $scope.approveFlag=false;
                    }
                    angular.forEach(response.additionalSanction, function (key,value) {
                                $scope.sanctionedTenure=parseInt(key.tenure)+parseInt($scope.sanctionedTenure);
                    //console.log(key.task_stage_id);
                                if (key.task_stage_id!=30) {
                                    $scope.approveFlag=false;
                                    return;
                                }
                            
                    })
                     //   console.log($scope.approveFlag);      

            
                if(response.data.length==0){
                    $scope.emicount=1;
                   // $scope.cgstmeFlag=0;
                    // $scope.disbursed_total= $scope.require_doc[0].bank_Disbursal_amt;
                    // $scope.disbursed_loan_amount=$scope.require_doc[0].bank_Disbursal_amt;
                    if ($scope.require_doc[0].loan_type=='BOTH') {
                        $scope.disbursed_loan_amount=parseInt($scope.require_doc[0].bank_Disbursal_amt_both_term);
                        $scope.disbursed_total=parseInt($scope.require_doc[0].bank_Disbursal_amt_both_term);
                    }else{
                        $scope.shownewdisbursal=true;
                        if ($scope.require_doc[0].bank_Disbursal_amt != null) {    
                        $scope.disbursed_loan_amount=parseInt($scope.require_doc[0].bank_Disbursal_amt);
                        $scope.disbursed_total=parseInt($scope.require_doc[0].bank_Disbursal_amt);
                        }else{
                          $scope.disbursed_loan_amount = 0;
                          $scope.disbursed_total = 0;
                        }
                    }
                } else {
                  //  console.log(response); // lastClaimDate
                    $scope.emicount=response.data.length+1;
                    //$scope.viewonly=1;
                    $scope.disbursal_date=response.lastClaimDate;
                    $scope.claimformd=response.data;
                    $scope.monthar=[];
             //         for (var i = 0; i < $scope.claimformd.length; i++) {
             //    	console.log($scope.cgstmeCount);
		           //  for (var j = 0; j < $scope.cgstmeCount.length; j++) {
			            		            
			          //   if ($scope.claimformd[i].claimYear==$scope.cgstmeCount[j].claimYear && $scope.cgstmeCount[j].cgstme>=1) {
			          //   	$scope.claimformd[i].cgstme=true;
			          //   }
			            
			          //   if ($scope.claimformd[i].CGSTME_amount!=null){
			          //   	$scope.claimformd[i].cgstme=false;
			          //   }
		           //  }
		           
            	// }
                    //$scope.claimlen=$scope.claimform.length;
                    //console.log($scope.claimformd.stage);
                    angular.forEach($scope.claimformd, function(value, key){
                        //console.log(value.emi_month);
                        var dateOut = new Date(value.emi_month);
                        var dtmon=dateOut.getMonth()+1;
                        var n = dtmon.toString().length;
                        var dtyear=dateOut.getFullYear();
                        if(n==1){
                            var newdt='0'+dtmon+'-'+dtyear;
                        }else{
                            var newdt=dtmon+'-'+dtyear;
                        }
                        $scope.monthar.push(newdt);

                    });

                    //$scope.claimformd.disbursed_loan_amt_new=response.data[response.data.length-1].disbursed_loan_amt_new;
                    //$scope.claimformd.disbursal_proof= response.data[0].disbursal_proof;

                    $scope.claimformd.proof_of_upload= response.data[0].upload_proof;
                    $scope.claimformd.proof_of_upload_alternate=response.data[0].upload_proof_alternate;
                    //$scope.claimformd.disbursal_proof_alternate=response.data[0].disbursal_proof_alternate;
                    $scope.disbursed_total= response.data[response.data.length-1].disbursed_loan_amt_total;
                    $scope.disbursed_loan_amount=response.data[response.data.length-1].disbursed_loan_amt_total;
                    $scope.claimformlen=$scope.claimformd.length;


                    // 		 		 	 			  if(response.data.length>=4){
                    //   								$scope.claimform.business_photo = angular.copy(response.data[response.data.length-1].business_photo);
                    // 		 		 	 			$scope.claimform.business_photo_alternate = angular.copy(response.data[response.data.length-1].business_photo_alternate);

                    // 		 		 	 			 //console.log($scope.claimform.business_photo);
                    // 		 		 	 			 //console.log($scope.claimform.business_photo_alternate);
                    // 		 		 	 		 }

                   
                        var id='';
                        userModel.getTicketDetail(id, $scope.userApplication.application[0].beneficiary_id, "claim").success(function(response){
                            try {
                              if(response.status){
                                $scope.ticketData = response.data;
                                 $scope.loader=false;
                              }else{
                                //console.log("error accourd"); 
                                $scope.loader=false;       
                              }
                            } catch (error) {
                              //console.log("try error: ",error);  
                              $scope.loader=false;
                            }
                        });
                }
               
               // console.log(approveFlag);
                //console.log(response.data[response.data.length-1].business_photo);
                //console.log($scope.claimform.business_photo);

                if($scope.require_doc[0].loan_type!="BOTH" && parseInt($scope.disbursed_loan_amount)<parseInt($scope.require_doc[0].bank_sanctioned_loan)){
                    $scope.shownewdisbursal=true;
                }else if($scope.require_doc[0].loan_type=="BOTH" && parseInt($scope.disbursed_loan_amount)<parseInt($scope.require_doc[0].bank_sanctioned_loan_both_term)){
                    $scope.shownewdisbursal=true;
                }
                //console.log(response.balanceClaimAmt);
                $scope.balanceClaimAmt=response.balanceClaimAmt;
                //$scope.balanceClaimAmt=0;
                
                $scope.loader=false;
               // console.log($scope.claimform.declarationCount);
               if ($scope.claimform.declarationCount==0) {
               	   angular.element('#ClaimModel').modal('show');
               }else{
                    angular.element('#ClaimDeclarationModel').modal('show');
               }
                // else
                // { 
                // 	 //$scope.viewonly=0;
                // 	 $scope.claimformss=response.data;
                // 	 $scope.loader=false;
                //      angular.element('#ClaimModel').modal('show');
                // }

            }).error(function (error) {
                $scope.loader=false;
            });

        }, 
        getClaimDetailsCC:function (app_no, loan_type) {
            $scope.ticketData={};
            if ($scope.userApplication.application[0].loan_type=="BOTH"){

                if ($scope.userApplication.application[0].both_cc_sanctioned_date>=$scope.userApplication.application[0].loi_gen_date) {
                    $scope.santioned_date=$scope.userApplication.application[0].both_cc_sanctioned_date;
                }else{
                    $scope.santioned_date=$scope.userApplication.application[0].loi_gen_date;
                }


                //only 3 claims can apply condition
                /*if ($scope.santioned_date<=$scope.userApplication.checkDate) {
                    $scope.santioned_date=$scope.userApplication.checkDate;
                }*/
            }

            $index=0;
            $scope.loader=true;
            $scope.viewonly=0;
            //console.log(app_no)
            if (loan_type=='BOTH') {
                loan_type="BOTH-CC";
            }
            // console.log(app_no, loan_type); //return;
            schemeModel.getclaimformdetailscc(app_no, loan_type).success(function(response){
                $scope.loader=false;
               //  console.log(response);
                // return;
                var claimedit=[];
                var i=0;
                angular.forEach(response.data, function(value, key){
                    var inputJSON = {
                       "created_date": value.created_at,
                       "current_time": new Date()
                    };
                    var startDate= new Date(inputJSON.created_date);
                    var endDate = new Date(inputJSON.current_time);
                    var diff = endDate.getTime() - startDate.getTime();
                    var days = Math.floor(diff / (60 * 60 * 24 * 1000));
                        // var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
                        // var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
                        // var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
                    value.day=days;
                    value.date_of_emi=value.emi_payment_date;
                    var date = new Date(value.emi_month);
                    value.month_names=(date.getMonth()+1)+"-"+date.getFullYear();
                    
                    if (value.day<1 && value.status_id==0) {
                        claimedit[i] =value;
                        i++;
                    }

                 });
                $scope.claimedit = claimedit;
//console.log(claimedit);
                $scope.claimform.disbursed_loan_amt_new="";
                $scope.claimform.disbursal_proof="";
                $scope.claimform.disbursal_proof_alternate="";
                $scope.claimform.disbursed_loan_amt_old=0;
                $scope.claimform.disbursed_loan_amt_total=0;
                $scope.claimform.holdClaimCount=response.holdClaimCount;
                $scope.claimform.claimProcessCount=response.claimProcessCount;
                // $scope.claimform.holdClaimCount=0;
                // $scope.claimform.claimProcessCount=0;
                $scope.claimform.declarationCount=response.declarationCount;
               // console.log($scope.claimform.claimProcessCount);
                $scope.claimform.totalClaim=response.totalClaim;
//console.log(response);
                
                $scope.sanctionedTenure=$scope.require_doc[0].tenure_both_cc;
                $scope.claimlength=response.data.length;
                $scope.approveFlag=true;
                angular.forEach(response.additionalSanction, function (key,value) {
                    $scope.sanctionedTenure=parseInt(key.tenure)+parseInt($scope.sanctionedTenure);
                    //console.log(key.task_stage_id);
                    if (key.task_stage_id!=30) {
                        $scope.approveFlag=false;
                        return;
                    }
                            
                })
                        // console.log(response.additionalSanction);     
                        // console.log($scope.sanctionedTenure);     
                        // console.log($scope.claimlength);     
                        // console.log($scope.approveFlag);     

                if(response.data.length==0){
                    $scope.disbursed_total= 0;
                    $scope.disbursed_loan_amount=0;
                    $scope.emicount=1;
                } else {
                    $scope.emicount=response.data.length+1;
                    //$scope.viewonly=1;
                    $scope.claimformd1=response.data;
                    $scope.claimlength=response.data.length;
                    //alert($scope.claimlength);
                    $scope.santioned_date=response.lastClaimDate;
                    $scope.monthar=[];
                    //console.log($scope.monthar);
                    //$scope.claimlen=$scope.claimform.length;
                    //console.log($scope.claimformd.stage);
                    angular.forEach($scope.claimformd1, function(value, key){
                        //console.log(value.emi_month);
                        var dateOut = new Date(value.emi_month);
                        var dtmon=dateOut.getMonth()+1;
                        var n = dtmon.toString().length;
                        var dtyear=dateOut.getFullYear();
                        if(n==1){
                            var newdt='0'+dtmon+'-'+dtyear;
                        }else{
                            var newdt=dtmon+'-'+dtyear;
                        }
                        //console.log(newdt);
                        $scope.monthar.push(newdt);

                    });
                    //$scope.claimformd1.disbursed_loan_amt_new=response.data[0].disbursed_loan_amt_new;
                    //$scope.claimformd1.disbursal_proof= response.data[0].disbursal_proof;
                    $scope.claimformd1.proof_of_upload= response.data[0].upload_proof;
                    $scope.claimformd1.proof_of_upload_alternate=response.data[0].upload_proof_alternate;
                    //$scope.claimformd1.disbursal_proof_alternate=response.data[0].disbursal_proof_alternate;
                    //$scope.disbursed_total= response.data[0].disbursed_loan_amt_total;
                    //$scope.disbursed_loan_amount=response.data[0].disbursed_loan_amt_total;
                    $scope.claimformlen=$scope.claimformd1.length;
                    //console.log(response.data.length);
                    // 	if(response.data.length>=4){

                    // $scope.claimform.business_photo = angular.copy(response.data[response.data.length-1].business_photo);
                    // 	$scope.claimform.business_photo_alternate = angular.copy(response.data[response.data.length-1].business_photo_alternate);

                    // 	// console.log($scope.claimform.business_photo);
                    // 	// console.log($scope.claimform.business_photo_alternate);
                    //  }
                    var id='';
                        userModel.getTicketDetail(id, $scope.userApplication.application[0].beneficiary_id, "claim").success(function(response){
                            try {
                              if(response.status){
                                $scope.ticketData = response.data;
                                 $scope.loader=false;
                              }else{
                                //console.log("error accourd"); 
                                $scope.loader=false;       
                              }
                            } catch (error) {
                              //console.log("try error: ",error);  
                              $scope.loader=false;
                            }
                        });

                }
                // 		 	if(parseInt($scope.disbursed_loan_amount)<parseInt($scope.require_doc[0].bank_sanctioned_loan)){
                // $scope.shownewdisbursal=true;
                //    }
                $scope.loader=false;
                //console.log(response.balanceClaimAmt);
                $scope.balanceClaimAmt=response.balanceClaimAmt;
                //$scope.balanceClaimAmt=0;
                if ($scope.claimform.declarationCount==0) {
                    angular.element('#ClaimModelCC').modal('show');
               }else{
                    angular.element('#ClaimDeclarationModelCC').modal('show');
               }

            }).error(function (error) {
                $scope.loader=false;
            });

        },

		 		  monthcompare:function(monthval,index){
		 		  		$scope.invalidmonnam=[];
		 		 	 // console.log(monthval,index);
		 		 	 var d1 = monthval.split("-");
		 		 
		 		 	$scope.claimrows[index].date_of_emi='';
		 		 	for(var i=0,len=$scope.monthar.length;i<len;i++) {
		 		 		
		 		 		var d2 = $scope.monthar[i].split("-");
		 		 		
					  if($scope.monthar[i] == monthval && d1[1]==d2[1]) {	
					  	 		 	    
		 		 	     $scope.invalidmonnam[index]=true;
		 		 	     $scope.invalidmonth=true;
		 		 	    // console.log($scope.invalidmonnam);
		 		 	    // console.log($scope.invalidmonth);
					   	  return;
					   	 // console.log("end");
					   }else{
					   	 $scope.invalidmonnam[index]=false; 
					   	 $scope.invalidmonth=false;
					   }
                      if(monthval<$scope.monthar[i] && (d1[1]==d2[1] || d1[1]<d2[1])){
                      	
                         $scope.checkmongreater[index]=true;
		 		 	     $scope.invalidmonth=true;
		 		 	      return;
					  }else if(monthval>$scope.monthar[i] && (d1[1]<d2[1])){
                      	
                         $scope.checkmongreater[index]=true;
		 		 	     $scope.invalidmonth=true;
		 		 	      return;
					  } else{
					  	
                         $scope.checkmongreater[index]=false;
		 		 	     $scope.invalidmonth=false;
					  }
					  // console.log( $scope.invalidmonnam[index]);
		 		 	//     console.log($scope.invalidmonth);
//console.log("endif");
					}
//console.log("endif");
		 		 },

		 		 compareemimon:function(emimonth,emidate,index){
		 		 	//alert(disbursal_date);
		 		   var emimon=emimonth.substring(0,2);
		 		   var eMonth = emidate.substring(7, 5);
		 		    if(emimon!=eMonth){
		 		   	  $scope.invalidemival[index]=true; 
					  $scope.invalidemivalue=true;
		 		    }else{
		 		   	  $scope.invalidemival[index]=false; 
					  $scope.invalidemivalue=false;
		 		    }

		 		 },
		 		 emimonthval:function(monthval,disbursal_date){
		 		 	   $scope.disbursal_date_month=disbursal_date.substring(7, 5);
		 		 	   $scope.disbursal_date_day = disbursal_date.substring(10, 8);
		 		 	   $scope.disbursal_date_year = disbursal_date.substring(4,0);
	 
		 		 	   $scope.currentYear = monthval.substr(monthval.length - 4);
			           $scope.selectedMonth = monthval.substring(0, 2);
			           var date = new Date();
					   $scope.currentMonth = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
                       $scope.currentday=(date.getDate() < 10 ? '0' : '') + date.getDate();
                       if($scope.currentMonth==$scope.selectedMonth){
                       	 $scope.currentendDay=$scope.currentday;
                       }else{
                       	   	if($scope.selectedMonth=='02'){
				           	     var isLeap = (($scope.currentYear % 4) == 0 && (($scope.currentYear % 100) != 0 || ($scope.currentYear % 400) == 0));
				           	   if(isLeap){
								$scope.currentendDay = '29';
		                       }else{
		                       	 $scope.currentendDay = '28';
		                       }
				           	}else{
				           	   if($scope.selectedMonth=='04'||$scope.selectedMonth=='06'||$scope.selectedMonth=='09'||$scope.selectedMonth=='11'){
		                       	$scope.currentendDay = '30';
		                       }else if($scope.selectedMonth=='12'||$scope.selectedMonth=='01'||$scope.selectedMonth=='03'||$scope.selectedMonth=='05'||$scope.selectedMonth=='07'||$scope.selectedMonth=='08'||$scope.selectedMonth=='10'){
	                             $scope.currentendDay = '31';  
		                       }
			           		}
                       }
		
			           $scope.disableemimon=false;
				 },
		 		submitClaimDeclaration:function(){
         
                    $scope.claim_submit=true;
                    if ($scope.form.claimDeclarationForm.$valid) {
                         $scope.claim_submit=false;
                         $scope.loader=true;
                        userModel.updateClaimDeclaration($scope.claimformd).success(function(response){
                             //$scope.loader=true;
                             $scope.disablesubmitbtn=true;
                            if (response.status == 'success') {
                                $scope.loader=false;
                                $scope.disablesubmitbtn=false;
                                $('#ClaimDeclarationModel').modal('hide');
                                bootbox.alert("Claim Declration submitted successfully");
                                   
                            }else if(response['status']==false) {
                                //alert("error");
                                //console.log("erromsg");
                                bootbox.alert(response['errorMsg']); 
                                $scope.loader=false;
                                $scope.disablesubmitbtn=false;
                            }
                        }).error(function () {
                            // body...
                            $scope.disablesubmitbtn=false;
                            bootbox.alert('Some internal error occurred in saving scheme application.')
                            $scope.loader=false;
                        });
                    }
                },
                submitClaimCcDeclaration:function(){
		 
		 		    $scope.claim_submit=true;
		 		 	if ($scope.form.claimDeclarationForm1.$valid) {
		 		 		 $scope.claim_submit=false;
                         $scope.loader=true;
                        userModel.updateClaimDeclaration($scope.claimformd1).success(function(response){
                           // console.log(response);
                            // $scope.loader=true;
                             $scope.disablesubmitbtn=true;
                            if (response.status == 'success') {
                                $scope.loader=false;
                                $scope.disablesubmitbtn=false;
                                $('#ClaimDeclarationModelCC').modal('hide');
                                bootbox.alert("Claim Declration submitted successfully");
                                   
                            }else if(response['status']==false) {
                                //alert("error");
                                //console.log("erromsg");
                                bootbox.alert(response['errorMsg']); 
                                $scope.loader=false;
                                $scope.disablesubmitbtn=false;
                            }
                        }).error(function () {
                            // body...
                            $scope.disablesubmitbtn=false;
                            bootbox.alert('Some internal error occurred in saving scheme application.')
                            $scope.loader=false;
                        });
		 		 	}
		 		},
		 		 submit_claim_form:function(){

		 		 	 if ($scope.form.claimform.$valid && $scope.filevalidation) {
                        	$scope.loader=true;
                        	$scope.disablesubmitbtn=true;
                            if ($scope.ticketData!=undefined && $scope.ticketData.length>0 && $scope.ticketData[0].ticket_category==4) {
                            //console.log($scope.claimedit);
                            var ticket = [];
                            angular.forEach($scope.ticketData, function(value, key){
                                //console.log(key);
                                ticket[key]=value;
                            });
                            //console.log(ticket);
                                bootbox.prompt({ 
                                    title:'Editing will send the Claim for Further Process. Do You Have Any Remark?',     
                                    inputType: 'textarea',
                                    buttons: {
                                        confirm: {
                                            label: 'Submit',
                                            className: 'btn-primary',
                                        },
                                    },
                callback : function(result){
                    //console.log(result);
                    if(result)
                    { //console.log(result);
                        $scope.loader=true;
                        var postData = ''
                        var contentType = 'application/json';
                        
                        $scope.claimform["claimr"]=$scope.claimedit;
                        $scope.claimform["comment"]=result;
                        $scope.claimform["ticket"]=$scope.ticketData;
                         
                            if($scope.formdata != undefined){   
                                angular.forEach($scope.claimform,function(key, value) {
                                    if(value=='claimr')
                                    {
                                        key = JSON.stringify(key);
                                    }
                                    if(value=='ticket')
                                    {
                                        key = JSON.stringify(key);
                                    }    
                                   // console.log(key);
                                    $scope.formdata.append(value, key);
                                })
                               
                               postData = $scope.formdata;
                               contentType = undefined;
                            }else{

                                var jasonClaimData=JSON.stringify($scope.claimedit);
                                var jasonTicketData=JSON.stringify($scope.ticketData);
                                //console.log(jasonData);
                                $scope.claimform["claimr"]=jasonClaimData;
                                $scope.claimform["ticket"]=jasonTicketData;
                                postData = $scope.claimform;
                            }
                          //  console.log($scope.claimform); //return;
                            
                            userModel.editClaimFormDetails(postData,contentType).success(function(response){
                                 
                                $scope.loader=true;
                                $scope.disablesubmitbtn=true;
                                if (response.status == 'success') {
                                    $scope.loader=false;
                                    $scope.disablesubmitbtn=false;
                                    $('#ClaimModel').modal('hide');
                                    $scope.claimform.business_photo='';
                                    $scope.claimform.business_photo_alternate='';
                                    $scope.claimform.additional_disbursal=false;
                                    $scope.monthar=[];
                          //          console.log($scope.claimform);
                                    $scope.claimform.proof_of_upload="";
                                    $scope.claimform.proof_of_upload_alternate="";
                          //          console.log($scope.claimform.proof_of_upload);
                                    $scope.claimrows=[{"id":0,"month_names":"","date_of_emi":"","emi_amount":"","interest_amount":"","proof_of_upload":"","proof_of_upload_alternate":""}];
                                    //console.log($scope.claimrows); return;
                                    //angular.element('#ClaimModel').modal('reset');
                                    bootbox.alert($scope.claimformsubmitmsg)
                                    setTimeout(function() {
                                         $('#successMSgchange').fadeOut('slow');
                                    },3000);
                                    
                               $scope.claimformdetails = "";
                           }else if(response['status']==false) {
                                //alert("error");
                                //console.log("erromsg");
                                bootbox.alert(response['errorMsg']); 
                                $scope.loader=false;
                                $scope.disablesubmitbtn=false;
                            }
                           }).error(function () {
                    // body...
                    $scope.disablesubmitbtn=false;
                    bootbox.alert('Some internal error occurred in saving scheme application.')
                        $scope.loader=false;
                });
                    }else if(result=="" && result!=null)
                    {
                        $("#paraID").remove()
                        $(".bootbox-body").addClass("has-error");
                        $(".bootbox-body").after("<p class='text-danger' id='paraID'>Please enter Remark.</p>");
                        return false;
                    }
                }

            });
                            }else{
                                if ($scope.compare_var) {
                            
                                    $scope.loader=false;
                                    $scope.disablesubmitbtn=false;
                                    $scope.compare_var=true;
                                    return;
                            }
                        if ($scope.compare_var1) {
                            
                            $scope.loader=false;
                            $scope.disablesubmitbtn=false;
                            $scope.compare_var1=true;
                            return;
                        }
                        if($scope.compare_valid){
                            //alert(3);
                            $scope.loader=false;
                            $scope.disablesubmitbtn=false;
                            $scope.compare_valid=true;
                            return;
                            
                        }
                        
                        if($scope.invalidmonth||$scope.invalidemivalue){
                            
                            //alert(4);
                            $scope.loader=false;
                            $scope.disablesubmitbtn=false;
                            //$scope.invalidmonnam=true;
                            return;
                            
                        }
                        //console.log($scope.business_photo);
                          // alert("You have selected duplicate months for filling up EMI.Please select some other month.");
                           $scope.monthar1=[];
                            angular.forEach($scope.claimrows, function(value, key){
                              $scope.monthar1.push(value.month_names);
                           });
                             for (var i = 0; i < $scope.monthar1.length -1; i++)

                              {
                               // console.log($scope.monthar1.length);
                                
                                      if ($scope.require_doc[0].loan_type=='BOTH' && ($scope.claimform.totalClaim + $scope.monthar1.length) > $scope.require_doc[0].tenure_both_term) {

                                      var claimCount = $scope.require_doc[0].tenure_both_term - $scope.claimform.totalClaim;
                                      bootbox.alert("You can apply "+ claimCount +" claims, User is trying to apply more than "+ claimCount +" claims as its exceeding the limit.");
                                      $scope.loader=false;
                                      $scope.disablesubmitbtn=false;
                                      return;
                                      }else if($scope.require_doc[0].loan_type!='BOTH' &&($scope.claimform.totalClaim + $scope.monthar1.length) > $scope.require_doc[0].tenure){

                                      var claimCount = $scope.require_doc[0].tenure - $scope.claimform.totalClaim;
                                      bootbox.alert("You can apply "+ claimCount +" claims, User is trying to apply more than "+ claimCount +" claims as its exceeding the limit.");
                                      $scope.loader=false;
                                      $scope.disablesubmitbtn=false;
                                      return;
                                      }
                                      
                                
                                  $scope.prevmon=$scope.monthar1[i].substring(0,2);
                                  $scope.prevyr=$scope.monthar1[i].substr($scope.monthar1[i].length - 4);
                                  $scope.nxtmon=$scope.monthar1[i+1].substring(0,2);
                                  $scope.nxtyr=$scope.monthar1[i+1].substr($scope.monthar1[i+1].length - 4);
                                  var prev=$scope.prevyr+"-"+$scope.prevmon +"-"+ "01" ;    
                                  var prevdate=new Date(prev);
                                  //d1.getTime() === d2.getTime();
                                  var nxt=$scope.nxtyr+"-"+$scope.nxtmon +"-"+ "01" ;   
                                  var nxtdate=new Date(nxt);  
                                  if(nxtdate.getTime()<prevdate.getTime()){
                                    alert($scope.ascendingmontherror);
                                    $scope.disablesubmitbtn=false;
                                    //alert(5);
                                    return;
                                 }
                                
                                if ($scope.monthar1[i+1] == $scope.monthar1[i])
                                {
                                  alert($scope.duplicatemonerror);
                                  $scope.duplmon=true;
                                  $scope.loader=false;
                                  $scope.disablesubmitbtn=false;
                                  return;
                                }
                              }
                          /* if($scope.duplmon){
                            alert("sfsfd");
                            $scope.loader=false;
                            $scope.duplmon=true;
                            return;
                            
                           }*/
                            $scope.monthar1=[];
                            angular.forEach($scope.claimrows, function(value, key){
                              $scope.monthar1.push(value.month_names);
                           });
                            
                           $scope.claim_submit = false;
                           $scope.disablesubmitbtn=true;
                           $scope.loader=true;
                           var postData = ''
                           var contentType = 'application/json';
                           var disbursed_old_amt=$scope.disbursed_loan_amount;
                               $scope.claimform.disbursed_old_amt=disbursed_old_amt;
                               
                           $scope.claimform["claimr"]=$scope.claimrows;
                          // console.log($scope.claimrows);
                               var schemeapplid=$scope.userApplication.application[0].applcation_no;
                               $scope.claimform.schemeapplid=schemeapplid;
                               if($scope.claimform.business_photo!=undefined && $scope.claimform.business_photo !=""){
                               //   console.log("business");
                               $scope.claimform.business_photo=$scope.claimform.business_photo;
                               $scope.claimform.business_photo_alternate=$scope.claimform.business_photo_alternate;
                                 }
                               var schemeid=$scope.userApplication.application[0].scheme_id;
                               $scope.claimform.scheme_id=schemeid;
                               $scope.claimform.beneficiary_id=$scope.userApplication.application[0].beneficiary_id;
                               var loan_type=$scope.userApplication.application[0].loan_type;
                               if(loan_type=="BOTH"){
                                $scope.claimform.loan_type="BOTH-Term";
                               }else{
                                $scope.claimform.loan_type= loan_type;
                               }
                                


                            if($scope.formdata != undefined){   
                               angular.forEach($scope.claimform,function(key, value) {
                                          if(value=='claimr')
                                            {
                                                
                                                key = JSON.stringify(key);
                                            }
                                            
                                          $scope.formdata.append(value, key);
                                        })
                               
                               postData = $scope.formdata;
                               contentType = undefined;
                            }else{
                                $scope.claimform["claimr"]=$scope.claimrows;
                                var schemeapplid=$scope.userApplication.application[0].applcation_no;
                                $scope.claimform.schemeapplid=schemeapplid;
                                var schemeid=$scope.userApplication.application[0].scheme_id;
                                $scope.claimform.scheme_id=schemeid;

                                postData = $scope.claimform;
                            }
                          //  console.log($scope.claimform); //return;
                          //  console.log($scope.claimrows); //return;
                            
                            userModel.claimformdetails(postData,contentType).success(function(response){
                                 
                                $scope.loader=true;
                                $scope.disablesubmitbtn=true;
                                if (response.status == 'success') {
                                    $scope.loader=false;
                                    $scope.disablesubmitbtn=false;
                                    $('#ClaimModel').modal('hide');
                                    $scope.claimform.business_photo='';
                                    $scope.claimform.business_photo_alternate='';
                                    $scope.claimform.additional_disbursal=false;
                                    $scope.monthar=[];
                          //          console.log($scope.claimform);
                                    $scope.claimform.proof_of_upload="";
                                    $scope.claimform.proof_of_upload_alternate="";
                          //          console.log($scope.claimform.proof_of_upload);
                                    $scope.claimrows=[{"id":0,"month_names":"","date_of_emi":"","emi_amount":"","interest_amount":"","proof_of_upload":"","proof_of_upload_alternate":""}];
                                    //console.log($scope.claimrows); return;
                                    //angular.element('#ClaimModel').modal('reset');
                                    bootbox.alert($scope.claimformsubmitmsg)
                                    setTimeout(function() {
                                         $('#successMSgchange').fadeOut('slow');
                                    },3000);
                                    
                               $scope.claimformdetails = "";
                           }else if(response['status']==false) {
                                //alert("error");
                                //console.log("erromsg");
                                bootbox.alert(response['errorMsg']); 
                                $scope.loader=false;
                                $scope.disablesubmitbtn=false;
                            }
                           }).error(function () {
                    // body...
                    $scope.disablesubmitbtn=false;
                    bootbox.alert('Some internal error occurred in saving scheme application.')
                        $scope.loader=false;
                });
                            }
		 		 	 	
                    }else{
                       $scope.claim_submit = true;
                       $scope.loader=false;
                        $scope.disablesubmitbtn=false;
                       //alert("false");
                    }
		 		 },	

		 		  submit_claim_form_cc:function(){
		 		 	 if ($scope.form.claimform1.$valid && $scope.filevalidation) {
                        	$scope.loader=true;
                        	$scope.disablesubmitbtn=true;
                            if ($scope.ticketData!=undefined && $scope.ticketData.length>0 &&$scope.ticketData[0].ticket_category==4) {
                            //console.log($scope.claimedit);
                            var ticket = [];
                            angular.forEach($scope.ticketData, function(value, key){
                                //console.log(key);
                                ticket[key]=value;
                            });
                            //console.log(ticket);
                                bootbox.prompt({ 
                                    title:'Editing will send the Claim for Further Process. Do You Have Any Remark?',     
                                    inputType: 'textarea',
                                    buttons: {
                                        confirm: {
                                            label: 'Submit',
                                            className: 'btn-primary',
                                        },
                                    },
                callback : function(result){
                    //console.log(result);
                    if(result)
                    { //console.log(result);
                        $scope.loader=true;
                        var postData = ''
                        var contentType = 'application/json';
                        
                        $scope.claimform["claimr"]=$scope.claimedit;
                        $scope.claimform["comment"]=result;
                        $scope.claimform["ticket"]=$scope.ticketData;
                         
                            if($scope.formdata != undefined){   
                                angular.forEach($scope.claimform,function(key, value) {
                                    if(value=='claimr')
                                    {
                                        key = JSON.stringify(key);
                                    }
                                    if(value=='ticket')
                                    {
                                        key = JSON.stringify(key);
                                    }    
                                   // console.log(key);
                                    $scope.formdata.append(value, key);
                                })
                               
                               postData = $scope.formdata;
                               contentType = undefined;
                            }else{

                                var jasonClaimData=JSON.stringify($scope.claimedit);
                                var jasonTicketData=JSON.stringify($scope.ticketData);
                                //console.log(jasonData);
                                $scope.claimform["claimr"]=jasonClaimData;
                                $scope.claimform["ticket"]=jasonTicketData;
                                postData = $scope.claimform;
                            }
                            
                            userModel.editClaimFormDetails(postData,contentType).success(function(response){
                                 
                               // $scope.loader=true;
                                $scope.disablesubmitbtn=true;
                                if (response.status == 'success') {
                                    $scope.loader=false;
                                    $scope.disablesubmitbtn=false;
                                    $('#ClaimModelCC').modal('hide');
                                    $scope.claimform.business_photo='';
                                    $scope.claimform.business_photo_alternate='';
                                    $scope.claimform.additional_disbursal=false;
                                    $scope.monthar=[];
                                    $scope.claimform.proof_of_upload="";
                                    $scope.claimform.proof_of_upload_alternate="";
                                    $scope.claimrows=[{"id":0,"month_names":"","date_of_emi":"","emi_amount":"","interest_amount":"","proof_of_upload":"","proof_of_upload_alternate":""}];
                                    
                                    bootbox.alert($scope.claimformsubmitmsg)
                                    setTimeout(function() {
                                         $('#successMSgchange').fadeOut('slow');
                                    },3000);
                                    
                               $scope.claimformdetails = "";
                           }else if(response['status']==false) {
                                //alert("error");
                                //console.log("erromsg");
                                bootbox.alert(response['errorMsg']); 
                                $scope.loader=false;
                                $scope.disablesubmitbtn=false;
                            }
                           }).error(function () {
                    // body...
                    $scope.disablesubmitbtn=false;
                    bootbox.alert('Some internal error occurred in saving scheme application.')
                        $scope.loader=false;
                });
                    }else if(result=="" && result!=null)
                    {
                        $("#paraID").remove()
                        $(".bootbox-body").addClass("has-error");
                        $(".bootbox-body").after("<p class='text-danger' id='paraID'>Please enter Remark.</p>");
                        return false;
                    }
                }

            });
                            }else{
		 		 	 	if ($scope.compare_var) {
		 		 	 		
						 	$scope.loader=false;
						 	 $scope.disablesubmitbtn=false;
						 	$scope.compare_var=true;
						 	return;
			 			}
			 			if ($scope.compare_var1) {
			 				
						 	$scope.loader=false;
						 	 $scope.disablesubmitbtn=false;
						 	$scope.compare_var1=true;
						 	return;
			 			}
			 			if($scope.compare_valid){
			 				//alert(3);
			 				$scope.loader=false;
			 				 $scope.disablesubmitbtn=false;
						 	$scope.compare_valid=true;
						 	return;
			 				
			 			}
			 			
			 			if($scope.invalidmonth||$scope.invalidemivalue){
			 				
			 				//alert(4);
			 				$scope.loader=false;
			 				 $scope.disablesubmitbtn=false;
						 	//$scope.invalidmonnam=true;
						 	return;
			 				
			 			}
			 			
			 			  // alert("You have selected duplicate months for filling up EMI.Please select some other month.");
			 			   $scope.monthar1=[];
						    angular.forEach($scope.claimrows, function(value, key){
						      $scope.monthar1.push(value.month_names);
						   });
							 for (var i = 0; i < $scope.monthar1.length -1; i++)

							  {
                                if ($scope.claimform.totalClaim + $scope.monthar1.length > $scope.require_doc[0].tenure_both_cc)
                                {
                                      var claimCount = $scope.require_doc[0].tenure_both_cc - $scope.claimform.totalClaim;
                                      bootbox.alert("You can apply "+ claimCount +" claims, User is trying to apply more than "+ claimCount +" claims as its exceeding the limit.");
                                      $scope.duplmon=true;
                                      $scope.loader=false;
                                      $scope.disablesubmitbtn=false;
                                      return;
                                }
					              $scope.prevmon=$scope.monthar1[i].substring(0,2);
			                      $scope.prevyr=$scope.monthar1[i].substr($scope.monthar1[i].length - 4);
			                      $scope.nxtmon=$scope.monthar1[i+1].substring(0,2);
			                      $scope.nxtyr=$scope.monthar1[i+1].substr($scope.monthar1[i+1].length - 4);
							      var prev=$scope.prevyr+"-"+$scope.prevmon +"-"+ "01" ;	
                                  var prevdate=new Date(prev);
                                  //d1.getTime() === d2.getTime();
                                  var nxt=$scope.nxtyr+"-"+$scope.nxtmon +"-"+ "01" ;	
                                  var nxtdate=new Date(nxt);  
                                  if(nxtdate.getTime()<prevdate.getTime()){
                                 	alert($scope.ascendingmontherror);
                                 	$scope.disablesubmitbtn=false;
                                 	//alert(5);
                                 	return;
                                 }
							  	
							    if ($scope.monthar1[i+1] == $scope.monthar1[i])
							    {
							      alert($scope.duplicatemonerror);
							      $scope.duplmon=true;
							      $scope.loader=false;
							       $scope.disablesubmitbtn=false;
							      return;
							    }
							  }
                          /* if($scope.duplmon){
			 				alert("sfsfd");
			 				$scope.loader=false;
						 	$scope.duplmon=true;
						 	return;
			 				
			 			   }*/
			 			    $scope.monthar1=[];
						    angular.forEach($scope.claimrows, function(value, key){
						      $scope.monthar1.push(value.month_names);
						   });
						    
	                       $scope.claim_submit = false;
	                       $scope.disablesubmitbtn=true;
			 			   $scope.loader=true;
	                       var postData = ''
						   var contentType = 'application/json';
						   var disbursed_old_amt=$scope.disbursed_loan_amount;
		                       $scope.claimform.disbursed_old_amt=disbursed_old_amt;
		                       
			 		 	   $scope.claimform["claimr"]=$scope.claimrows;
						       var schemeapplid=$scope.userApplication.application[0].applcation_no;
		                       $scope.claimform.schemeapplid=schemeapplid;
		                       var schemeid=$scope.userApplication.application[0].scheme_id;
		                       $scope.claimform.scheme_id=schemeid;
		                       var loan_type=$scope.userApplication.application[0].loan_type;
		                       $scope.claimform.beneficiary_id=$scope.userApplication.application[0].beneficiary_id;
		                       if(loan_type=="BOTH"){
		                       	$scope.claimform.loan_type="BOTH-CC";
		                       }else{
		                       	$scope.claimform.loan_type= loan_type;
		                       }
		                      	


                            if($scope.formdata != undefined){	
		                       angular.forEach($scope.claimform,function(key, value) {
							   	          if(value=='claimr')
									        {
									        	
									          	key = JSON.stringify(key);
									        }
									        
							   	          $scope.formdata.append(value, key);
						                })
							   
							   postData = $scope.formdata;
							   contentType = undefined;
						    }else{
						    	$scope.claimform["claimr"]=$scope.claimrows;
						    	var schemeapplid=$scope.userApplication.application[0].applcation_no;
		                        $scope.claimform.schemeapplid=schemeapplid;
		                        var schemeid=$scope.userApplication.application[0].scheme_id;
		                        $scope.claimform.scheme_id=schemeid;

						    	postData = $scope.claimform;
						    }
						   // console.log($scope.claimform); //return;
                            userModel.claimformdetails(postData,contentType).success(function(response){
						    	$scope.loader=true;
						    	 $scope.disablesubmitbtn=true;
				                if (response.status == 'success') {
                                    $scope.claimform.business_photo='';
                                    $scope.claimform.business_photo_alternate='';
                                    $scope.monthar=[];
                                    //console.log($scope.claimform);
                                    $scope.claimform.proof_of_upload="";
                                    $scope.claimform.proof_of_upload_alternate="";
                                    //console.log($scope.claimform.proof_of_upload);
                                    $scope.claimrows=[{"id":0,"month_names":"","date_of_emi":"","emi_amount":"","interest_amount":"","proof_of_upload":"","proof_of_upload_alternate":""}];
				                	$scope.loader=false;
				                	$scope.disablesubmitbtn=false;
				                	$('#ClaimModelCC').modal('hide');
				                	bootbox.alert($scope.claimformsubmitmsg)
				                	setTimeout(function() {
										 $('#successMSgchange').fadeOut('slow');
									},3000);

					           $scope.claimformdetails = "";
					       }else if(response['status']==false) {
		                        //alert("error");
		                        //console.log("erromsg");
		                        bootbox.alert(response['errorMsg']); 
		                        $scope.loader=false;
		                        $scope.disablesubmitbtn=false;
		                    }
                            //console.log($scope.claimform.proof_of_upload);
				           }).error(function () {
					// body...
					$scope.disablesubmitbtn=false;
					bootbox.alert('Some internal error occurred in saving scheme application.')
						$scope.loader=false;
				});
                       }
                    }else{
                       $scope.claim_submit = true;
                       $scope.loader=false;
                        $scope.disablesubmitbtn=false;
                       //alert("false");
                    }
		 		 },
				uploadSanctionLetter: function(app_no){
					//console.log('uploadSanctionLetter');
		    		$state.go('upload_sanction_letter',{app_no:app_no});
				},				
				
				viewProfile: function(aadhar_id){
					$state.go('profile');
				},
				changePassword: function(aadhar_id){
					$state.go('password');
				},
				addProduct: function(aadhar_id){
					$state.go('product');
				},
				getSubCasteCategory:function(caste_id){
                 // alert(caste_id);
                  masterModel.getSubCastes(caste_id).success(function(response){
					$scope.sub_castes = response;
					
						setTimeout(function() {
							angular.element('#sub_caste').selectpicker('refresh');
						}, 300);
					});
				},
				printApplication: function(printdiv) {
					//alert(printdiv);
						  $('.open').hide();
						  var printContent = document.getElementById(printdiv).innerHTML;
						  var popupWin = window.open('', '_blank', 'width=300,height=300');
						  popupWin.document.open();
						  popupWin.document.write('<html><body onload="window.print()">' + printContent + '</body></html>');
						  popupWin.document.close();

						} ,
				opendoc: function(data,flag){
                     
					var windowObjectReference;
					var strWindowFeatures = "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
					switch(flag)
				   	{
				   		case('product_photo'):
					   	 	windowObjectReference = window.open($rootScope.uploadsUrl+"user_uploads/product_images/"+data);
					   	 	break;
					   	 case('photo'):
					   	 	windowObjectReference = window.open($rootScope.uploadsUrl+"user_uploads/scheme_application/photo/"+data);
					   	 	break;
					   	 case('residence_doc'):
					   	 	windowObjectReference = window.open($rootScope.uploadsUrl+"user_uploads/scheme_application/residence_doc/"+data);
					   	 	break;
					   	 case('aadhar_photo'):
					   	 	windowObjectReference = window.open($rootScope.uploadsUrl+"user_uploads/scheme_application/aadhar_photo/"+data);
					   		 break;
					   	 case('document'):
					   	 	windowObjectReference = window.open($rootScope.uploadsUrl+"user_uploads/scheme_application/document/"+data);
					   	 	break;
					   	 case('other_doc'):
					   	 	windowObjectReference = window.open($rootScope.uploadsUrl+"user_uploads/scheme_application/other_doc/"+data);
					   	 	break;
					   	 case('disability_certificate'):
					   	 	windowObjectReference = window.open($rootScope.uploadsUrl+"user_uploads/scheme_application/disablity_certificates/"+data);
					   	 	break;
					   	case('both_term_sanction_letter'): 	
						case('sanction_letter'):
					   	 	windowObjectReference = window.open($rootScope.uploadsUrl+"user_uploads/scheme_application/new_scheme_doc/"+data);
					   	 	break;
                        case('disbursal_proof1'):
                            windowObjectReference = window.open($rootScope.uploadsUrl+"user_uploads/scheme_application/claim_doc/"+data);
					   	 	break;
					   	case('proof_of_upload1'):
                            windowObjectReference = window.open($rootScope.uploadsUrl+"user_uploads/scheme_application/claim_doc/"+data);
					   	 	break; 	
					   	 case('business_photo'):
					   	 windowObjectReference = window.open($rootScope.uploadsUrl+"user_uploads/scheme_application/claim_doc/"+data);
					   	 	break; 	
					   	 //scheme 2 documents
					   	 case('group_disability_certificate'):
					   	 case('share_holders'):
					   	 case('moa_doc'):
					   	 case('bal_sheet'):
					   	 case('pandl_doc'):
					   	 case('bank_stat'):
					   	 case('bank_credit'):
					   	 case('repayment_doc'):
					   	 case('npa'):
					   	 case('IT_return'):
					   	 case('gst_return'):
					   	 case('project_report'):
					   	 case('deposite_stat'):
					   	 case('quotation'):
					   	 case('contract'):
					   	 case('apr_doc'):
					   	 case('com_reg_doc'):
					   	 case('license'):
					   	 case('land_doc'):
					   	 case('both_term_bank_emi_schedule'):
					   	 case('machinary_doc'):
					   	 	windowObjectReference = window.open($rootScope.uploadsUrl+"user_uploads/scheme_application/new_scheme_doc/"+data);
					   	 	break;					   	 
				   	}	
						
					
				},
				    //function for check attachment size and extention match
    //validateFile: function(element, maxSize = 5, extentionsArray = ['jpeg','jpg','png','gif'], isShowError = 0) {
    validateFile: function(element, flag, index, isShowError = 1) {
        //console.log(element);
        var val = element[0].name; //get file value
        val = val.split("\\");
        val = val[val.length-1];

        var ext = val.substring(val.lastIndexOf('.') + 1).toLowerCase(); // get file extention 
         //console.log('ext',ext);
        // Find no multiple Extensions
        var fileNameArrayExt = val.split('.');

        if (fileNameArrayExt.length > 2) {
            var msg = 'Files with multiple extensions or dots (.) are Not allowed.';
            //console.log(msg);
            if (isShowError) {
                $scope.showFileError(element, msg, flag, index);
            }

            return false;
        }

        var fileNameArraySpace = val.split(' ');
         //console.log('fileNameArraySpace',fileNameArraySpace);
        if (fileNameArraySpace.length > 1) {
            var msg = 'Files with spaces are Not allowed.';
            //console.log(msg);
            if (isShowError) {
                $scope.showFileError(element, msg, flag, index);
            }

            return false;
        }

        var fileNameArrayDoubleDots = val.split('..');
        if (fileNameArrayDoubleDots.length > 1) {
            var msg = 'Files with double dots (.) are Not allowed.';
            //console.log(msg);
            if (isShowError) {
                $scope.showFileError(element, msg, flag, index);
            }

            return false;
        }

        var fileName = val.split('.')[0];
        if ($scope.checkSpecialCharsTitle(fileName)) {
            var msg = 'Special characters are not allowed in file name.';
            //console.log(msg);
            if (isShowError) {
                $scope.showFileError(element, msg, flag, index);
            }

            return false;
        }

        $scope.showFileError(element, '', flag ,index);
        return true;
    },
            /**
        * @author Hemlata
        * Finds special characters in string
        * @return true/false
        */
    checkSpecialCharsTitle: function (argument1) {
        var format1 = /[!@#$%^&*+\=\[\]{};:"\\|<>\/?]/;
        return format1.test(argument1);
    },
        // show error for file input
 	showFileError: function(element, msg = '', flag, index, hide=0) {
    // console.log('inside showFileError',msg);
    // console.log(flag);
    // find out identifier to give to error element
        switch (flag) {
                    
            case 'business_photo':
                if (msg != '') {
                    $scope.business_photoDocError = msg;
                    return true;
                 }else{
                    $scope.business_photoDocError='';
                 }
                break;  
            case 'product_photo':
                if (msg != '') {
                    $scope.product_photoDocError = msg;
                    return true;
                 }else{
                    $scope.product_photoDocError='';
                 }
                break;         
                  
            case 'proof_upload':
                if (msg != '') {
                    $scope.proof_uploadDocError = msg;
                    return true;
                 }else{
                    $scope.proof_uploadDocError='';
                 }
                break;
            case 'disbursal_proof':
                if (msg != '') {
                    $scope.disbursal_proofDocError = msg;
                    return true;
                 }else{
                    $scope.disbursal_proofDocError='';
                 }
                break;  
             default:
                return false;
        }
            //console.log(Msg);
},
				
				getTheFiles: function ($files, flag) {
					//console.log($files);
					var proof_uploadDoc=business_photoDoc =disbursal_proofDoc= product_photoDoc= true;

                    $scope.filevalidation=$scope.validateFile($files, flag, 0);

                    if($scope.proof_uploadDocError){
                        var proof_uploadDoc=false;
                    }else{
                        var proof_uploadDoc=true;
                    }
                    if($scope.business_photoDocError){
                        var business_photoDoc=false;
                    }else{
                        var business_photoDoc=true;
                    }
                    if($scope.product_photoDocError){
                        var product_photoDoc=false;
                    }else{
                        var product_photoDoc=true;
                    }
                    if($scope.disbursal_proofDocError){
                        var disbursal_proofDoc=false;
                    }else{
                        var disbursal_proofDoc=true;
                    }
                    
                  
                      // console.log(residenceDocError);
                      // console.log(incomeDocError);
                       //console.log(aadharDocError);

                    if (!disbursal_proofDoc
                        || !business_photoDoc
                        || !proof_uploadDoc) {
                         $scope.filevalidation=false;
                    // console.log($filevalidation);
                        //return $scope.filevalidation;
                    }
					$scope.photoErr = '';
					$scope.doc={};
			         if($scope.formdata == undefined){
		 		          $scope.formdata = new FormData();
				 	      }
				 	if (flag == 'photo') {
				 		$scope.photoErr = '';
				 		var fileExt = ["image/png","image/jpg","image/jpeg"];
				 		if(fileExt.indexOf($files[0].type) != -1) {
					 		if($files[0].size <= 2097152){
					 			$scope.$apply(function(){
									$scope.profileinfo.photo = angular.copy($files[0].name);
									 $scope.formdata.append('photo_file',$files[0]);
								});
							} else {
					 			$scope.photoErr = "Please upload photo less than 2MB size!";
					 		}
					 	} else {
					 		$scope.photoErr = "Unsupported file format.";
					 	}
					 	//console.log($files[0]);
					 	
				 	}
				    if (flag == 'document') {
				 		$scope.documentErr = '';
				 		var fileExt = ["image/png","image/jpg","image/jpeg","application/pdf"];
				 		if(fileExt.indexOf($files[0].type) != -1) {
					 		if($files[0].size <= 10485760){
					 			$scope.$apply(function(){
									$scope.doc.document = angular.copy($files[0].name);
					 				$scope.formdata.append('document_file',$files[0]);
								});
							} else {
								$scope.doc.document = '';
								$scope.documentErr = "Please upload document less than 2MB size!";
					 		}
					 	} else {
					 		$scope.documentErr = "Unsupported file format.";
					 	}
					} 	
					if (flag == 'disbursal_proof') {
				 		$scope.disbursal_proofErr = '';
				 		var fileExt = ["image/png","image/jpg","image/jpeg","application/pdf"];
				 		if(fileExt.indexOf($files[0].type) != -1) {
				 			//alert($files[0].size);
					 		if($files[0].size <= 10485760){
					 			$scope.$apply(function(){
									$scope.claimform.disbursal_proof = angular.copy($files[0].name);
									//$scope.requiredoc.sanctioned_letter = angular.copy($files[0].name);
					 				$scope.formdata.append('disbursal_proof_file',$files[0]);
					 				//console.log($scope.formdata);
								});
							} else {
								$scope.claimform.disbursal_proof = '';
								//$scope.requiredoc.sanctioned_letter = '';
					 			$scope.disbursal_proofErr = "Please upload document less than 10MB size!";
					 		}
					 	} else {
					 		$scope.disbursal_proofErr = "Unsupported file format.";
					 	}
		 			} 
		 			if (flag == 'proof_upload') {
				 		$scope.upload_proofErr = '';
				 		var fileExt = ["image/png","image/jpg","image/jpeg","application/pdf"];
				 		// console.log($files[0]);
				 		// console.log($files[0].size <= 10485760);
				 		//10485760 bytes = 10 mb
				 		if(fileExt.indexOf($files[0].type) != -1) {
				 			//alert($files[0].size);
					 		if($files[0].size <= 10485760){
					 			$scope.$apply(function(){
									$scope.claimform.proof_of_upload = angular.copy($files[0].name);
									//$scope.requiredoc.sanctioned_letter = angular.copy($files[0].name);
					 				$scope.formdata.append('proof_of_upload_file',$files[0]);
					 				//console.log($scope.formdata);
								});
							} else {
								$scope.claimform.proof_of_upload = '';
								//$scope.requiredoc.sanctioned_letter = '';
					 			$scope.upload_proofErr = "Please upload document less than 10MB size!";
					 		}
					 	} else {
					 		$scope.upload_proofErr = "Unsupported file format.";
					 	}
		 			}
		 			if (flag == 'business_photo') {
		 				
				 		$scope.business_photoErr = '';
				 		var fileExt = ["image/png","image/jpg","image/jpeg","application/pdf"];
				 		if(fileExt.indexOf($files[0].type) != -1) {
				 			//alert($files[0].size);
					 		if($files[0].size <= 10485760){
					 			$scope.$apply(function(){
									$scope.claimform.business_photo = angular.copy($files[0].name);
									//$scope.requiredoc.sanctioned_letter = angular.copy($files[0].name);
					 				$scope.formdata.append('business_photo_file',$files[0]);
					 				//console.log($scope.formdata);
								});
							} else {
								$scope.claimform.business_photo = '';
								//$scope.requiredoc.sanctioned_letter = '';
					 			$scope.business_photoErr = "Please upload document less than 10MB size!";
					 		}
					 	} else {
					 		$scope.business_photoErr = "Unsupported file format.";
					 	}
		 			} 

		 			if (flag == 'product_photo') {
		 				
				 		$scope.product_photoErr = '';
				 		var fileExt = ["image/png","image/jpg","image/jpeg","application/pdf"];
				 		if(fileExt.indexOf($files[0].type) != -1) {
					 		if($files[0].size <= 10485760){
					 			$scope.$apply(function(){
									$scope.addproducts.product_photo = angular.copy($files[0].name);
					 				$scope.formdata.append('product_photo_file',$files[0]);
								});
							} else {
								$scope.addproducts.product_photo = '';
								//$scope.requiredoc.sanctioned_letter = '';
					 			$scope.product_photoErr = "Please upload document less than 10MB size!";
					 		}
					 	} else {
					 		$scope.product_photoErr = "Unsupported file format.";
					 	}
		 			}	
				 	
				 },
				 /*getTheFiles: function ($files, flag) {
					//console.log($files);
					$scope.photoErr = '';
					$scope.doc={};
			         if($scope.formdata == undefined){
		 		          $scope.formdata = new FormData();
				 	      }
				 	if (flag == 'photo') {
				 		$scope.photoErr = '';
				 		var fileExt = ["image/png","image/jpg","image/jpeg"];
				 		if(fileExt.indexOf($files[0].type) != -1) {
					 		if($files[0].size <= 2097152){
					 			$scope.$apply(function(){
									$scope.profileinfo.photo = angular.copy($files[0].name);
									 $scope.formdata.append('photo_file',$files[0]);
								});
							} else {
					 			$scope.photoErr = "Please upload photo less than 2MB size!";
					 		}
					 	} else {
					 		$scope.photoErr = "Unsupported file format.";
					 	}
					 	//console.log($files[0]);
					 	
				 	}
				    if (flag == 'document') {
				 		$scope.documentErr = '';
				 		var fileExt = ["image/png","image/jpg","image/jpeg","application/pdf"];
				 		if(fileExt.indexOf($files[0].type) != -1) {
					 		if($files[0].size <= 10485760){
					 			$scope.$apply(function(){
									$scope.doc.document = angular.copy($files[0].name);
					 				$scope.formdata.append('document_file',$files[0]);
								});
							} else {
								$scope.doc.document = '';
								$scope.documentErr = "Please upload document less than 2MB size!";
					 		}
					 	} else {
					 		$scope.documentErr = "Unsupported file format.";
					 	}
					} 	
					if (flag == 'disbursal_proof') {
				 		$scope.disbursal_proofErr = '';
				 		var fileExt = ["image/png","image/jpg","image/jpeg","application/pdf"];
				 		if(fileExt.indexOf($files[0].type) != -1) {
				 			//alert($files[0].size);
					 		if($files[0].size <= 10485760){
					 			$scope.$apply(function(){
									$scope.claimform.disbursal_proof = angular.copy($files[0].name);
									//$scope.requiredoc.sanctioned_letter = angular.copy($files[0].name);
					 				$scope.formdata.append('disbursal_proof_file',$files[0]);
					 				//console.log($scope.formdata);
								});
							} else {
								$scope.claimform.disbursal_proof = '';
								//$scope.requiredoc.sanctioned_letter = '';
					 			$scope.disbursal_proofErr = "Please upload document less than 10MB size!";
					 		}
					 	} else {
					 		$scope.disbursal_proofErr = "Unsupported file format.";
					 	}
		 			} 
		 			if (flag == 'proof_upload') {
				 		$scope.upload_proofErr = '';
				 		var fileExt = ["image/png","image/jpg","image/jpeg","application/pdf"];
				 		if(fileExt.indexOf($files[0].type) != -1) {
				 			//alert($files[0].size);
					 		if($files[0].size <= 10485760){
					 			$scope.$apply(function(){
									$scope.claimform.proof_of_upload = angular.copy($files[0].name);
									//$scope.requiredoc.sanctioned_letter = angular.copy($files[0].name);
					 				$scope.formdata.append('proof_of_upload_file',$files[0]);
					 				//console.log($scope.formdata);
								});
							} else {
								$scope.claimform.proof_of_upload = '';
								//$scope.requiredoc.sanctioned_letter = '';
					 			$scope.upload_proofErr = "Please upload document less than 10MB size!";
					 		}
					 	} else {
					 		$scope.upload_proofErr = "Unsupported file format.";
					 	}
		 			} 	
		 				
				 	
				 },*/
				 revalidateloiFiles: function ($files, flag) {
					//console.log($files);
					if($scope.formdata == undefined){
				 		$scope.formdata = new FormData();
				 	}
					if (flag == 'residence_doc') {
				 		$scope.residence_docErr = '';
				 		//console.log($files[0].type);
				 		var fileExt = ["image/png","image/jpg","image/jpeg",'image/bmp'];
				 		if(fileExt.indexOf($files[0].type) != -1) {
					 		if($files[0].size <= 10485760){
					 			$scope.$apply(function(){
									$scope.revalidateuserdetails.residence_doc = angular.copy($files[0].name);
					 				$scope.formdata.append('residence_doc_file',$files[0]);
								});
							} else {
								$scope.revalidateuserdetails.residence_doc = '';
								$scope.residence_docErr = "Please upload document less than 10MB size!";
					 		}
					 	} else {
					 		$scope.residence_docErr = "Unsupported file format.";
					 	}
				 	} 
				 	if (flag == 'aadhar_photo') {
				 		$scope.aadhar_photoErr = '';
				 		var fileExt = ["image/png","image/jpg","image/jpeg",'image/bmp'];
				 		if(fileExt.indexOf($files[0].type) != -1) {
					 		if($files[0].size <= 10485760){
					 			$scope.$apply(function(){
									$scope.revalidateuserdetails.aadhar_photo = angular.copy($files[0].name);
					 				$scope.formdata.append('aadhar_photo_file',$files[0]);
								});
							} else {
								$scope.revalidateuserdetails.aadhar_photo = '';
								$scope.aadhar_photoErr = "Please upload document less than 10MB size!";
					 		}
					 	} else {
					 		$scope.aadhar_photoErr = "Unsupported file format.";
					 	}
				 	}
				 	if (flag == 'income_certificate') {
				 		$scope.income_certificateErr = '';
				 		var fileExt = ["image/png","image/jpg","image/jpeg",'image/bmp'];
				 		if(fileExt.indexOf($files[0].type) != -1) {
					 		if($files[0].size <= 10485760){
					 			$scope.$apply(function(){
									$scope.revalidateuserdetails.document = angular.copy($files[0].name);
					 				$scope.formdata.append('income_certificate',$files[0]);
								});
							} else {
								$scope.revalidateuserdetails.document = '';
								$scope.income_certificateErr = "Please upload document less than 10MB size!";
					 		}
					 	} else {
					 		$scope.income_certificateErr = "Unsupported file format.";
					 	}
				 	}
				 	if (flag == 'other_doc') {
				 		$scope.other_docErr = '';
				 		var fileExt = ["image/png","image/jpg","image/jpeg",'image/bmp'];
				 		if(fileExt.indexOf($files[0].type) != -1) {
					 		if($files[0].size <= 10485760){
					 			$scope.$apply(function(){
									$scope.revalidateuserdetails.other_doc = angular.copy($files[0].name);
					 				$scope.formdata.append('other_doc',$files[0]);
								});
							} else {
								$scope.revalidateuserdetails.other_doc = '';
								$scope.other_docErr = "Please upload document less than 10MB size!";
					 		}
					 	} else {
					 		$scope.other_docErr = "Unsupported file format.";
					 	}
				 	}
				 		
				 },
				 
		UploadAdditionalDoc: function(doc,type,app_no,task_stage_id) {
				//alert(doc);
				//console.log(app_no);
				//return false;
			if ($scope.form.uploadDoc.$valid) {
				
				 if ($scope.documentErr != '') {
					$scope.form_submited = true;
					$scope.documentErr = "Please upload document less than 10MB size!";
					return;
				}
				else{
					$scope.form_submited = false;
				}
				    angular.element('#upload').attr('disabled',true);
					$scope.doc.app_no = angular.copy(app_no);
					$scope.doc.type = angular.copy(type);
					$scope.doc.stage_id = angular.copy(task_stage_id);

					var postData = ''
					var contentType = 'application/json';
					if($scope.formdata != undefined){
						for ( var key in $scope.doc) {
					    	$scope.formdata.append(key, $scope.doc[key]);
						}
						postData = $scope.formdata;
						contentType = undefined;
					}else{
						postData = $scope.doc;
					}
					   userModel.UploadAdditionalDoc(postData,contentType).success(function(response) {
							if (response.status == 'success') {
									//alert();
									setTimeout(function(){
									angular.element('#uploadsanctionletter').fadeOut('slow');
									$('.modal').modal('hide');
								    },3000);
								    var ben_id = userModel.decryptBen($scope.auth.authlogindetails.Beneficiary.id);
		 		 	 				var dec_ben_id = parseInt(ben_id);
									schemeModel.applications(dec_ben_id).success(function(response){
					$scope.userApplication = response;
					//console.log('1918');
					schemeModel.getAppRequiredDoc($scope.userApplication.application[0].applcation_no).success(function(response){
			
						if (response.status == 'success') {
							$scope.require_doc = response.data;
							//console.log($scope.require_doc);
						}else{
							//alert("scheme Required Doc Not Available");
						}
					});
					//$scope.sanction = response.sanction;
				});
								angular.element('#upload').removeAttr('disabled');
								$scope.documentSuccess = 'Document uploaded successfully.';
								//$scope.UpdateStage($scope.doc.app_no,type);
								userModel.getDocument($scope.doc.app_no,'').success(function(response){
									$scope.additional_doc = response.data;
									angular.forEach(response.data,function (value,key) {
										$scope.additional_doc[key].type = $scope.additional_doc[key].document_type.split('_').join(' ');
									})
								})
								
									setTimeout(function(){
									angular.element('#documentSuccess').fadeOut('slow');
									$('.modal').modal('hide');
								    },3000);
						
							} else {
								angular.element('#upload').removeAttr('disabled');
								$scope.documentErr = "Error occurred, please try after sometime.";

								setTimeout(function(){
									angular.element('#documentErr').hide();
									$('.modal').modal('hide');
								    },3000);
							}
					   }).error(function () {
							angular.element('#upload').removeAttr('disabled');
							bootbox.alert('Some internal error occurred in saving scheme application.')
					   });							
						
					}else {
				         $scope.form_submited = true;
			        }
		},
				User: function(userdetail){
					console.log($scope.ticketData);
					$scope.loader = false;
					$scope.success = '';
					$scope.error = '';
					

					//$scope.userdetailloading = false;
					$scope.resgister = $scope.auth.authlogindetails.User.registration_id;

					if ($scope.form.edit_form.$valid) {
						
						if ($scope.photoErr) {
							$scope.edit_form_submitted = true;
							$scope.photoErr = "Please upload photo less than 2MB size!";
							return;
						}
						$scope.loader = true;
						//$scope.userdetailloading = true;
						angular.element('#userinfo').attr('disabled',true);
						$scope.edit_form_submitted = false;
						
						var postData = ''
						var contentType = 'application/json';

						userdetail.year = $scope.year;
						userdetail.month = $scope.month;
						userdetail.day = $scope.day;
						userdetail.resgister = $scope.resgister;

						if($scope.formdata != undefined){

							for ( var key in userdetail) {
						    	$scope.formdata.append(key, userdetail[key]);
							}
							postData = $scope.formdata;
							contentType = undefined;

							userModel.profilePhoto(postData, contentType).success(function(response){
								$scope.photoErr = response.status;
								$scope.profile_pic = response.data;
								//localStorage.setItem('profile_pic', response.data);
      							//$scope.auth.authlogindetails.Beneficiary.photo = response.data;
      							userModel.profileUpdate(postData, contentType).success(function(response) {
		        			
										if(response.message == 'sucess'){
											$scope.loader = false;
											//$scope.userdetailloading = false;
											$scope.success = 'User Details Updated successfully..';
											setTimeout(function(){
												    $('#success').fadeOut('slow')
												}, 3000);
											angular.element('#userinfo').removeAttr('disabled');
											$scope.newdata = userModel.getUserObj();
											$scope.newdata.Beneficiary.mobile_no = userdetail.mobile_no;
											$scope.newdata.Beneficiary.email = userdetail.email;
											$scope.newdata.Beneficiary.photo = $scope.profile_pic;

											$scope.userDetails = { User:$scope.newdata.User, Beneficiary: $scope.newdata.Beneficiary, Address: $scope.newdata.Address};
											// $cookies.put('auth', JSON.stringify($scope.userDetails));
											sessionStorage.authData = JSON.stringify($scope.userDetails);
											$scope.updateinfo = {Beneficiary: $scope.newdata.Beneficiary, Address: $scope.newdata.Address };
											//console.log($scope.updateinfo.Beneficiary.id);
											window.location.reload(true); 
													 userModel.saveinfo($scope.updateinfo).success(function(response){
									            	
											    })		
										}
										else{
											$scope.loader = false;
											//$scope.userdetailloading = false;
											angular.element('#userinfo').removeAttr('disabled');
											$scope.error = 'Error occured Please try again later..';
											setTimeout(function(){
												    $('#error').fadeOut('slow')
												}, 3000);
										}
						            })
							})	
						}else{
                            bootbox.prompt({ 
                                title:'Editing will send the Claim for Further Process. Do You Have Any Remark?',     
                                inputType: 'textarea',
                                buttons: {
                                    confirm: {
                                        label: 'Submit',
                                        className: 'btn-primary',
                                    },
                                },
                                callback : function(result){
                                    //console.log(result);
                                    if(result)
                                    { 
                                        userdetail.ticket=JSON.stringify($scope.ticketData);
                                        userdetail.comment=result;
                                        postData = angular.copy(userdetail);
                                        contentType = 'application/json';
                                        userModel.profileUpdate(postData, contentType).success(function(response) {
                                            //console.log(response);
                                        if(response.message == 'sucess'){
                                            $scope.loader = false;
                                            //$scope.userdetailloading = false;
                                            $scope.success = 'User Details Updated successfully..';
                                            setTimeout(function(){
                                                    $('#success').fadeOut('slow')
                                                }, 3000);
                                            angular.element('#userinfo').removeAttr('disabled');
                                            $scope.newdata = userModel.getUserObj();
                                            $scope.newdata.Beneficiary.mobile_no = userdetail.mobile_no;
                                            $scope.newdata.Beneficiary.email = userdetail.email;

                                            $scope.userDetails = { User:$scope.newdata.User, Beneficiary: $scope.newdata.Beneficiary, Address: $scope.newdata.Address};
                                            // $cookies.put('auth', JSON.stringify($scope.userDetails));
                                            sessionStorage.authData = JSON.stringify($scope.userDetails);
                                            $scope.updateinfo = {Beneficiary: $scope.newdata.Beneficiary, Address: $scope.newdata.Address };
                                                        //console.log($scope.updateinfo.Beneficiary);
                                                     userModel.saveinfo($scope.updateinfo).success(function(response){
                                                        window.location.reload(true); 
                                                })      
                                        }
                                        else{
                                            $scope.loader = false;
                                            //$scope.userdetailloading = false;
                                            angular.element('#userinfo').removeAttr('disabled');
                                            $scope.error = 'Error occured Please try again later..';
                                            setTimeout(function(){
                                                    $('#error').fadeOut('slow')
                                                }, 3000);
                                        }
                                    })
                                    }else if(result=="" && result!=null)
                                    {
                                        $("#paraID").remove()
                                        $(".bootbox-body").addClass("has-error");
                                        $(".bootbox-body").after("<p class='text-danger' id='paraID'>Please enter Remark.</p>");
                                        return false;
                                    }
                                }

                            });
							
						}

					}else{
						$scope.loader = false;
						//$scope.userdetailloading = false;
						$scope.edit_form_submitted = true;
					}


				},
				profileUpdate: function(profileinfo){
					//console.log("profileUpdate");
					$scope.successMsg = '';
					$scope.errorMsg = '';
					
                $scope.resgister = $scope.auth.authlogindetails.User.registration_id;
                if ($scope.form.pi_form.$valid) {
					$scope.loader = true;
                	//$scope.loader = false;
                angular.element('#update').attr('disabled',true);
                $scope.personaldetailloading = true;
        		$scope.pi_form_submitted = false;
        		reauthenticate = "No";

        		profileinfo.year = $scope.year;
				profileinfo.month = $scope.month;
				profileinfo.day = $scope.day;
				profileinfo.resgister = $scope.resgister;
				//console.log(profileinfo);
	        	userModel.aadharDetails(profileinfo,reauthenticate).success(function(response){  
	        	    		
	        		if(response.Ret == "y"){
		        		userModel.profileUpdate(profileinfo,'application/json').success(function(response) {
							
							if (response.message =='sucess') {
								$scope.loader = false;
								$scope.successMsg = 'Aadhar details verified and profile updated successfully';
								setTimeout(function(){
				                    $('html, body').animate({
				                        scrollTop: ($('.alert').offset().top - 300)
				                    }, 2000);
				                }, 100); 
				                setTimeout(function(){
									    $('#successMsg').fadeOut('slow')
									}, 3000);
				             angular.element('#update').removeAttr('disabled');
				             $scope.personaldetailloading = false;

							} else {
								angular.element('#update').removeAttr('disabled');
								$scope.personaldetailloading = false;
								
								$scope.pi_form_submitted = false;
								setTimeout(function(){
				                    $('html, body').animate({
				                        scrollTop: ($('.alert').offset().top - 300)
				                    }, 2000);
				                }, 100); 
				                setTimeout(function(){
									    $('#errorMsg').fadeOut('slow')
									}, 3000);
				                $scope.errorMsg = 'Error occurred, please try after sometime';
							}
						}); 
					}
					else{
			 	 		if(response.ResponseCode == 1001){
							angular.element('#update').removeAttr('disabled');
							$scope.personaldetailloading = false;
							$scope.errorMsg = "Please enter correct name and aadhar ID";
							$scope.pi_form_submitted = false;
							setTimeout(function(){
			                    $('html, body').animate({
			                        scrollTop: ($('.alert').offset().top - 300)
			                    }, 2000);
			                }, 100); 
			                setTimeout(function(){
									    $('#errorMsg').fadeOut('slow')
									}, 3000);
						} else if( response.ResponseCode == 100) {
							angular.element('#update').removeAttr('disabled');
							$scope.personaldetailloading = false;
							$scope.errorMsg = "Either of your Name or DOB is not matching as mentioned in Aadhaar Card";
							$scope.pi_form_submitted = false;
							setTimeout(function(){
			                    $('html, body').animate({
			                        scrollTop: ($('.alert').offset().top - 300)
			                    }, 2000);
			                }, 100); 
			                setTimeout(function(){
									    $('#errorMsg').fadeOut('slow')
									}, 3000);
						} else if (response.ResponseCode >=930 && response.ResponseCode <=939){
							angular.element('#update').removeAttr('disabled');
							$scope.personaldetailloading = false;
							$scope.errorMsg = "Technical Exception";
							$scope.pi_form_submitted = false;
							setTimeout(function(){
			                    $('html, body').animate({
			                        scrollTop: ($('.alert').offset().top - 300)
			                    }, 2000);
			                }, 100); 
			                setTimeout(function(){
									    $('#errorMsg').fadeOut('slow')
									}, 3000);	
						} else if(response.ResponseCode == 1008){
							$scope.loader = false;
							angular.element('#update').removeAttr('disabled');
							$scope.personaldetailloading = false;
							$scope.errorMsg = "Please enter correct Aadhar ID";
							$scope.pi_form_submitted = false;
							setTimeout(function(){
			                    $('html, body').animate({
			                        scrollTop: ($('.alert').offset().top - 300)
			                    }, 2000);
			                }, 100); 	
			                setTimeout(function(){
									    $('#errorMsg').fadeOut('slow')
									}, 3000);
						} else if(response.ResponseCode == 998){
							angular.element('#update').removeAttr('disabled');
							$scope.personaldetailloading = false;
							$scope.errorMsg = "Ensure you have entered correct Aadhaar number.  Please retry with correct Aadhaar number after sometime.";
							$scope.pi_form_submitted = false;
							setTimeout(function(){
			                    $('html, body').animate({
			                        scrollTop: ($('.alert').offset().top - 300)
			                    }, 2000);
			                }, 100); 
			                setTimeout(function(){
									    $('#errorMsg').fadeOut('slow')
									}, 3000);
						} else if(response.ResponseCode == 511){
							angular.element('#update').removeAttr('disabled');
							$scope.personaldetailloading = false;
							$scope.errorMsg = "Technical Exception";
							$scope.pi_form_submitted = false;
							setTimeout(function(){
			                    $('html, body').animate({
			                        scrollTop: ($('.alert').offset().top - 300)
			                    }, 2000);
			                }, 100);
			                setTimeout(function(){
									    $('#errorMsg').fadeOut('slow')
									}, 3000);
						} else if(response.ResponseCode == 1002){
							angular.element('#update').removeAttr('disabled');
							$scope.personaldetailloading = false;
							$scope.errorMsg = "Aadhaar verification is failed due to server error, please try after sometime";
							$scope.pi_form_submitted = false;
							setTimeout(function(){
			                    $('html, body').animate({
			                        scrollTop: ($('.alert').offset().top - 300)
			                    }, 2000);
			                }, 100);
			                setTimeout(function(){
									    $('#errorMsg').fadeOut('slow')
									}, 3000);
						} else if(response.ResponseCode == 997){
							$scope.loader = false;
							angular.element('#update').removeAttr('disabled');
							$scope.personaldetailloading = false;
		 		            var responseCode = response.ResponseCode;      	
						} else {
							$scope.loader = false;
							angular.element('#update').removeAttr('disabled');
							$scope.personaldetailloading = false;
							$scope.errorMsg = "Please enter correct details for aadhar authentication.";
							$scope.pi_form_submitted = false;
							setTimeout(function(){
			                    $('html, body').animate({
			                        scrollTop: ($('.alert').offset().top - 300)
			                    }, 2000);
			                }, 100); 
			                setTimeout(function(){
									    $('#errorMsg').fadeOut('slow')
									}, 3000);
						}
					}
				});	     		
        	} else {
		        		$scope.pi_form_submitted = true;
		        		setTimeout(function(){
		                    $('html, body').animate({
		                        scrollTop: ($('.has-error:first').offset().top - 300)
		                    }, 2000);
		                }, 100); 
		        	}
				},

				ContactForm: function(contactinfo){
					$scope.successmsg = '';
					$scope.errormsg = '';
					$scope.resgister = $scope.auth.authlogindetails.User.registration_id;
					if ($scope.form.contact_form.$valid) {
					$scope.loader=true;

						contactinfo.year = $scope.year;
						contactinfo.month = $scope.month;
						contactinfo.day = $scope.day;
						contactinfo.resgister = $scope.resgister;

						//$scope.loading = true;
						angular.element('#contactinfo').attr('disabled',true);

						$scope.contact_form_submitted = false;
		        		userModel.profileUpdate(contactinfo,'application/json').success(function(response) {
							if(response.message == 'sucess'){
								$scope.loader=false;
								angular.element('#contactinfo').removeAttr('disabled');
								//$scope.loading = false;
								$scope.successmsg1 = 'Contact Details Updated successfully..';
								setTimeout(function(){
									    $('#msg').fadeOut('slow')
									}, 3000);
							}
							else{
								angular.element('#contactinfo').removeAttr('disabled');
								//$scope.loading = false;
								$scope.errormsg = 'Error occured Please try again later..';
								setTimeout(function(){
									    $('#msg').fadeOut('slow')
									}, 3000);
							}
						})
					}else{
						$scope.contact_form_submitted = true;
					}

				},

			getDistricts: function(states_id) {
			// body...
				masterModel.getDistricts(states_id).success(function(response){
					$scope.districts = response;
					setTimeout(function() {
						angular.element('#district').selectpicker('refresh');
					}, 10);
				})
			   },
			getdistrictName: function(id){
					angular.forEach($scope.districtName, function (key,value) {
						if (key.value == id) {
							$scope.dis_name =  key.option;
							return $scope.dis_name;
						}
					})
				},
			getTalukaName: function(id){
			angular.forEach($scope.reg_talukaas, function (key,value) {
				if (key.value == id) {
					$scope.tal_name =  key.option;
					return $scope.tal_name;
				    }
			   })
		},
		    getProjLocTalukaName: function(id){
			//console.log($rootScope.reg_talukas);
			angular.forEach($scope.proj_loc_talukas, function (key,value) {
				if (key.value == id) {
					$scope.proj_loc_tal_name =  key.option;
					return $scope.proj_loc_tal_name;
				    }
			   })
		    },		
		    getregTalukaName: function(id){
			//console.log($rootScope.reg_talukas);
			angular.forEach($scope.reg_talukas, function (key,value) {
				if (key.value == id) {
					$scope.reg_tal_name =  key.option;
					return $scope.reg_tal_name;
				    }
			   })
		    },
		    getregCityName: function(id){
			//console.log($rootScope.reg_talukas);
			angular.forEach($scope.reg_cities, function (key,value) {
				if (key.value == id) {
					$scope.reg_city_name =  key.option;
					return $scope.reg_city_name;
				    }
			   })
		    },	
		    getProjLocCityName: function(id){
			//console.log($rootScope.reg_talukas);
			angular.forEach($scope.proj_loc_cities, function (key,value) {
				if (key.value == id) {
					$scope.proj_loc_city_name =  key.option;
					return $scope.proj_loc_city_name;
				    }
			   })
		    },	
			gettalukas: function(districts_id) {
				// body...
				masterModel.getTalukas(districts_id).success(function(response){
					$scope.talukas = response;
					setTimeout(function() {
						angular.element('#taluka').selectpicker('refresh');
					}, 10);
				})
			  },
			getCities: function(talukas_id) {
				// body...
				masterModel.getCities(talukas_id).success(function(response){
					$scope.cities = response;
					setTimeout(function() {
						angular.element('#city').selectpicker('refresh');
					}, 10);
				})
			   },
			OpenModal: function (flag,product_id) {
				if (flag == "AddProduct") {
					$scope.formdata = new FormData();
					$scope.stepsModel = [];
					$scope.successAddMsg = "";
					$scope.errorAddMsg = "";
					$scope.product = "";
					$scope.ProImgErr = '';
					angular.element('#addProduct').modal('show');
					$scope.form_submitted = false;

				}else if (flag == "EditProduct") {
					$scope.imges = [];
					$scope.successEditMsg = "";
					$scope.errorEditMsg = "";
					$scope.ProImgErr = '';
					userModel.viewProduct(product_id).success(function(response) {
						if(response.product_info){
							angular.element('#editProduct').modal('show');
							$scope.product_detail = response.product_info;
							$scope.product_img = response.product_images;
						}
						if ($scope.product_img.length == 1) {
							$scope.ShowCancel = false;
						}else{
							$scope.ShowCancel = true;
						}
					})

				}else if (flag == "ViewProduct") {
					userModel.viewProduct(product_id).success(function(response) {
						if(response){
						angular.element('#viewProduct').modal('show');
						$scope.product_detail = response.product_info;
						$scope.pro_img = response.product_images;	
						}
					})
				}
			},
			previewImages: function (element,flag) {
				if (flag == 'editProduct') {
					$scope.showimg = true;
				}else{
					$scope.showimg = false;
				}
				var reader = new FileReader();
		        reader.onload = $scope.getProductFiles;
		        reader.readAsDataURL(element.files[0]);	
  		    },
			getProductFiles: function (e) {
	 			$scope.$apply(function(){
	 				if (!$scope.ProImgErr) {
	 					$scope.stepsModel.push(e.target.result);
	 				}
					 
					if ($scope.showimg && !$scope.ProImgErr) {
					 	$scope.imges.push(e.target.result);
					 	if ($scope.imges.length == 1 && $scope.product_img.length==0) {
							$scope.ShowCancel1 = false;

						}else if ($scope.imges.length == 0 && $scope.product_img.length==1) {
							$scope.ShowCancel = false;

						}else{
							$scope.ShowCancel1 = true;
							$scope.ShowCancel = true;
						}
					}
				});
			}, 
			getProductFiles1: function ($files,flag) {
			 	 $scope.ProImgErr = '';
		         if($scope.formdata == undefined){
	 		          $scope.formdata = new FormData();
			 	  }

			 	  if (flag == 'addProduct' || flag == 'editProduct') {
				 	  var fileExt = ["image/png","image/jpg","image/jpeg"];
				 		if(fileExt.indexOf($files[0].type) != -1) {
					 		if($files[0].size <= 2097152){
					 			$scope.imageCountArr.push($files[0].name);
			 	  				var index = $scope.imageCountArr.length;
					 			 $scope.$apply(function(){
								 $scope.formdata.append('photo_file['+index+']',$files[0]);
								 $scope.noImg = false;
								})
					 		}else {
						 		$scope.ProImgErr = "Please upload photo less than 2MB size!";
						 	 }
					 	}else {
						 		$scope.ProImgErr = "Unsupported file format.";
						 }
			     }		 	

			},
			add_product: function(addProductData){
			
			$scope.successMSgadd= '';
			$scope.errorMSgadd = '';
		
			angular.element('#add').removeAttr('disabled');
			if ($scope.form.AddProduct.$valid == true){
				if ($scope.product_photoErr) {
					$scope.add_product_submitted = true;
					$scope.loader=false;
					$scope.product_photoErr = "Please upload document less than 10MB size!";
					return;
				}
				$scope.errorMSgadd = '';
				$scope.disabled_prdct = true;
				$scope.loader=true;
				angular.element('#add').attr('disabled',true);
				var postData = ''
				var contentType = 'application/json';
				if($scope.formdata != undefined){
					$scope.AddProduct["product_photo"]=$scope.product_photos;
					angular.forEach($scope.AddProduct,function(key, value) {
				             if(value=='product_photo'){
				                    key = JSON.stringify(key);
				                  }
				                  $scope.formdata.append(value, key);
				                })
                    postData = $scope.formdata;
					contentType = undefined;
				}else{
					$scope.AddProduct["product_photo"]=$scope.product_photos;
					postData = $scope.AddProduct;
				}
				userModel.add_product(postData,contentType).success(function(response){
					console.log(response['success']);
						if (response['success'] == 'success') {
									angular.element('#add').removeAttr('disabled');
									$scope.successMSgadd = "Product added successfully";
									$scope.loader=false;
									$scope.form.AddProduct.$submitted = false;
									$scope.form.AddProduct.$setUntouched();
									var msg_draft ='Product added successfully.'
                                    bootbox.alert(msg_draft);
                                    $state.go('dashboard');
									
						} else {
								 angular.element('#add').removeAttr('disabled');
								 $scope.disabled_prdct = false;
								 $scope.loader=false;
								$scope.errorMSgadd = 'Some internal error occurred.Please try Again Later.';
								setTimeout(function() {
								 $('#errorMSgchange').fadeOut('slow');
								},3000);
							}
						});	
				
			}
			else{
				$scope.errorMSgadd = 'Some internal error occurred.Please try Again Later.';
				setTimeout(function() {
							 $('#errorMSgadd').fadeOut('slow');
						},3000);
			}
	  },  
	  /*getProductDetail: function(product_details,flag){
			if(flag=='view'){	
				$state.go('product_details',{product_id:product_details});   		
			}else if(flag=='edit'){
				$state.go('edit_product_details',{product_id:product_details});
			}   		
		},*/
		    addOreditProduct: function(product,id){
			    if(id){
			    	if ($scope.product_img.length == 0 && $scope.imges.length == 0) {
			    			$scope.noImg = true;
							return false;
						}else{
							var formValid = $scope.form.edit_product.$valid;
						}
				}
				else{
					if ($scope.formdata == undefined || $scope.formdata == null) {
							$scope.noImg = true;
							return false;
					}else{
						var formValid = $scope.form.add_product.$valid;
					}
				}
				
				if(formValid){
						$scope.noImg = false;
	                	$scope.form_submitted = false;

	                	var postData = ''
						var contentType = 'application/json';

						if($scope.formdata != undefined){
						    for ( var key in product) {
						    	$scope.formdata.append(key, product[key]);
							}
							postData = $scope.formdata;
							contentType = undefined;
						}else{
							postData = product;
						}

		                 userModel.addOreditProduct(postData,contentType).success(function (response) {
		                 	if (response != 'fail') {
		                 		$scope.formdata = new FormData();
		                 		$scope.successEditMsg = "Data updated successfully";
								$scope.successAddMsg = "Product Added successfully";
								setTimeout(function(){
				                    $('.modal').animate({
				                        scrollTop: ($('.alert-msg').offset().top - 300)
				                    }, 2000);
				                }, 100); 
				                setTimeout(function(){
										    $('#successEditMsg').fadeOut('slow');
										    $('#successAddMsg').fadeOut('slow');
										  angular.element('#addProduct').modal('hide');
		                 			      angular.element('#editProduct').modal('hide');
										}, 3000);

		                 		userModel.productlist().success(function(response){
						        $scope.productlist = response;
						       });
		                 	}else{
		                 		$scope.errorEditMsg = "Some error Occured";
								$scope.errorAddMsg = "Some error Occured";
								setTimeout(function(){
				                    $('.modal').animate({
				                        scrollTop: ($('.alert-msg').offset().top - 300)
				                    }, 2000);
				                }, 100); 
				                setTimeout(function(){
										  $('#errorEditMsg').fadeOut('slow');
										  $('#errorAddMsg').fadeOut('slow');
										  angular.element('#addProduct').modal('hide');
		                 			      angular.element('#editProduct').modal('hide');
										}, 3000);

		                 	}
		                 })
		        } else{
		           $scope.form_submitted = true;
		           setTimeout(function(){
				                    $('.modal').animate({
				                        scrollTop: ($('.has-error').offset().top - 300)
				                    }, 2000);
				                }, 100); 
		          }
		    },
		    popImg: function (index,img) {
		    	newIndex = index + 1;
		        if ($scope.imges.indexOf(img > -1)) {
		        	 $scope.imges.splice(index, 1);
		        	 $scope.formdata.delete('photo_file['+newIndex+']');
		        	   if ($scope.imges.length == 1 && $scope.product_img.length==0)
		        	 	{
							$scope.ShowCancel1 = false;
						}
						else if($scope.imges.length == 0 && $scope.product_img.length==1){
							$scope.ShowCancel = false;
						}
						else{
							$scope.ShowCancel1 = true;
							$scope.ShowCancel = true;
						}
		        }
		    },
		    deleteImg: function (id,pro_id) {
		    	userModel.deleteImg(id).success(function (response) {
		    		if (response == 'success') {
		    			userModel.viewProduct(pro_id).success(function(response) {
						if(response){
							$scope.product_detail = response.product_info;
							$scope.product_img = response.product_images;	
						}
							if ($scope.product_img.length == 1 && $scope.imges.length==0) {
								$scope.ShowCancel = false;
							}
							else if($scope.imges.length == 1 && $scope.product_img.length==0){
							       $scope.ShowCancel1 = false;
						    }
							else
							{	
								$scope.ShowCancel1 = true;
								$scope.ShowCancel = true;
							}
					   })
		    		}
		    	})
		    },
		    deleteApplication:function(app_no){
			bootbox.confirm({ 
						size: "small",
						message: $scope.deletemsg,

						 buttons: {
							confirm: {
							            label: 'Yes',
							            className: 'btn-success'
							        },
							cancel: {
							            label: 'No',
							            className: 'btn-danger'
							        },
						},       
						callback: function(result){
							/* result is a boolean; true = OK, false = Cancel*/
							if(result == true) {
	           					$scope.loader=true;
								//alert();
								schemeModel.deleteApplication(app_no).success(function(response) {
	            				if(response>=1)
	            				{
	            					bootbox.alert({
									size: "small",
							        message: $scope.successmsg,
							        
	           						});
       								var ben_id = userModel.decryptBen($scope.auth.authlogindetails.Beneficiary.id);
		 		 	 				var dec_ben_id = parseInt(ben_id);
									schemeModel.applications(dec_ben_id).success(function(response){
									$scope.loader=false;
									//console.log('2631');
									$scope.userApplication = response;
									$state.go('dashboard');
								 });
								//console.log($scope.schemeApplication.beneficiary_id);
								//console.log($scope.schemeApplication.app_no);
								}
							 })
							}
						}
					})	

	},
			applynewscheme:function(appl_no){
            bootbox.confirm({ 
						size: "small",
						message:$rootScope.applynewscheme,
						 buttons: {
							cancel: {
							            label: 'No',
							            className: 'btn-success'
							        },
							confirm: {
							            label: 'Yes',
							            className: 'btn-danger'
							        },
						},       
						callback: function(result){
							/* result is a boolean; true = OK, false = Cancel*/
							if (result == true) {
											
									bootbox.confirm({ 
									size: "small",
									message: $rootScope.applynewschemey,
									 buttons: {
										cancel: {
										            label: 'No',
										            className: 'btn-success'
										        },
										confirm: {
										            label: 'Yes',
										            className: 'btn-danger'
										        },
									},       
									callback: function(result){
										/* result is a boolean; true = OK, false = Cancel*/
										if (result == true) {
														bootbox.confirm({ 
												size: "small",
												message:$rootScope.newschemeconfirm,
												 buttons: {
													cancel: {
													            label: 'No',
													            className: 'btn-success'
													        },
													confirm: {
													            label: 'Yes',
													            className: 'btn-danger'
													        },
												},       
												callback: function(result){
													/* result is a boolean; true = OK, false = Cancel*/
													if (result == true) {
														schemeModel.deleteApplication(appl_no).success(function(response) {
							            				if(response>=1)
							            				{
							            					bootbox.alert({
															size: "small",
													        message:$scope.successmsg,
													        
							           						});
							           						var ben_id = userModel.decryptBen($scope.auth.authlogindetails.Beneficiary.id);
		 		 	 										var dec_ben_id = parseInt(ben_id);
															schemeModel.applications(dec_ben_id).success(function(response){
																$scope.userApplication = response;
																//console.log('2705');
															})
														
														}
													 })
													}
												}
											})	
										}
									}
								})	
											
							}
						}
					})	
        }, 
		    DisableOrEnable: function (flag,id,$index) {
		    	$scope.errorPro = '';
		    	$scope.successPro = '';
		    	bootbox.confirm({
		    		message:"Are you sure you want to "+flag+" this product",
		    		buttons: {
						confirm: {
						            label: 'Yes',
						            //className: 'btn-success'
						        },
						cancel: {
						            label: 'No',
						            //className: 'btn-danger'
						        },
				    },     
		    		callback:function (result) {
		    			if (result) {
		    				if (flag == 'Enable') {
		    					flag = 'Pending';
		    				}
		    			 userModel.DisableOrEnable(flag,id).success(function(response) {
		    				if (response) {

			    				userModel.productlist().success(function(response){
									$scope.productlist = response;
								});
								if (flag == 'Pending') {
		    					    flag = 'Enable';
		    				    } 
								$scope.successPro = "Product"+' '+flag+' '+"successfully.";
								setTimeout(function(){
										
								$('html, body').animate({
					                        scrollTop: ($('#complete').offset().top - 300)
					                    }, 2000);
							    },100);
							    setTimeout(function(){
			    				angular.element('#successPro').fadeOut('slow');
			    			   },3000);
		    				}else{
		    					$scope.errorPro = "Internal error Occurred. Please try again later.";
								setTimeout(function(){
										
								$('html, body').animate({
					                        scrollTop: ($('#complete').offset().top - 300)
					                    }, 2000);
							    },100);
							    setTimeout(function(){
			    				angular.element('#errorPro').fadeOut('slow');
			    			   },3000);
	    				    }
		    			 })
		    			}
		    		}
		    	})
		    },

	});
		  
}]);

app.directive("datepicker", function () {

        function link(scope, element, attrs, $watch) {

             scope.$watchGroup(["selectedMonth","currentYear","currentendDay","disbursal_date_month","disbursal_date_day","disbursal_date_year"], function(newv,oldv){
             	
             	if(scope.selectedMonth==scope.disbursal_date_month && scope.currentYear==scope.disbursal_date_year){
                    var strtdate=scope.currentYear+"-"+ scope.selectedMonth+"-"+ scope.disbursal_date_day;
             	}else if(scope.currentMonth==scope.disbursal_date_month && scope.currentYear!=scope.disbursal_date_year){
                    var strtdate=scope.disbursal_date_year+"-"+ scope.selectedMonth+"-"+ scope.disbursal_date_day;
             	}else{
             		var strtdate=scope.currentYear+"-"+ scope.selectedMonth+"-"+ scope.currentDay;

             	}
         	
                
	            var sdate=new Date(strtdate);
	            var endate=scope.currentYear+"-"+scope.selectedMonth+"-"+ scope.currentendDay;
        		var fenddate=new Date(endate);
        		element.datepicker("setEndDate",fenddate);
	            //element.datepicker("clearDates");
	            
			    element.datepicker({
	                format: "yyyy-mm-dd",
	                 orientation: "top auto",
	                 autoclose:true,
	                 startDate:sdate ,
	                 endDate:fenddate
	                 //endDate: new Date()	                  
	            });
                //alert(strtdate);
	            element.datepicker("setStartDate",strtdate);
	            //element.datepicker("autoclose", true);
	            element.datepicker.defaults.autoclose = true;
         //    if(){
         //    	//var endate= new Date();
         //    	var endate=scope.currentYear +"-"+scope.currentMonth+"-"+ "31" ;
         //    	 var end_date = new Date(endate);
	        //      end_date.setDate(end_date.getDate() + 1);
         //    	 var fedate=new Date(end_date);
	        //      element.datepicker("setEndDate", fedate);
        	// }else{
        		

	            element.blur('changeDate', function(ev){
	            	var elem=$(this);
				    $(this).setTimeout(function(){
                        elem.val()==0;
				    },100);
				});
            }); 
        }

        return {
            require: 'ngModel',
            link: link
        };
    });
	app.directive("monthpicker", function () {
	function link(scope, element, attrs,$watch) {
        scope.$watch("instalment_period", function() { 

        });

    	scope.$watch("disbursal_date", function() {

        	var disbursalYear = scope.disbursal_date.substr(0,4);
        	var disbursalMonth = scope.disbursal_date.substring(7, 5);
        	var disbursalDay= scope.disbursal_date.substring(10, 8);

        	var emistrtdate=disbursalYear+"-"+disbursalMonth+"-"+disbursalDay; 
        	/*}else{
        	var emistrtdate="2018"+"-"+"02"+"-"+"01";
        	}*/
                var date = new Date();
                var second_date = new Date(emistrtdate);
                second_date.setMonth(second_date.getMonth()+5);

                var endDate= new Date(second_date);
               // var endDay = endDate.getDate();
                var endDay = 30;
                var endMonth = endDate.getMonth()+1;
                var endYear= endDate.getFullYear();
                dateMonth=date.getMonth()+1;
                dateYear=date.getFullYear();
                date=dateYear+"-"+dateMonth;
                endDate=endYear+"-"+endMonth;
               // console.log(date>=endDate);

            if(date>=endDate && scope.instalment_period!="Half Yearly" && scope.instalment_period!="Yearly") {

                var emiEndDate=endYear+"-"+endMonth+"-"+endDay; 
            	element.datepicker({
                	//minViewMode:1,
                	format: "mm-yyyy",
                	//minViewMode:"months",
                	startView:1,
                	viewMode: 'years',
                	//maxViewMode:"months",
                	//showWeekDays:false,
                	autoclose:true,
                	//format: 'mm-yyyy',
                    startDate: new Date(emistrtdate),
                    //maxDate: new Date(emiEndDate),
                	endDate: new Date(emiEndDate)
            	});
            }else{
                    element.datepicker({                
                        format: "mm-yyyy",
                        startView:1,
                        viewMode: 'years',
                        autoclose:true,
                        startDate: new Date(emistrtdate),
                        endDate: new Date()
                    });
            }
           
        	element.datepicker.viewMode('months');  
    	});

	}return {
    	require: 'ngModel',
    	scope: true,
    	link: link
	};
	});

	app.directive("ccmonthpicker", function () {

		function link(scope, element, attrs,$watch) {
			scope.$watch("santioned_date", function() {

				var disbursalYear = scope.santioned_date.substr(0,4);
				var disbursalMonth = scope.santioned_date.substring(7, 5);
				var disbursalDay= scope.santioned_date.substring(10, 8);

				var emistrtdate=disbursalYear+"-"+disbursalMonth+"-"+disbursalDay;

                var date = new Date();
                var second_date = new Date(emistrtdate);
                second_date.setMonth(second_date.getMonth()+5);

                var endDate= new Date(second_date);
                var endDay = 30;
                //var endDay = endDate.getDate();
                var endMonth = endDate.getMonth()+1;
                var endYear= endDate.getFullYear();
                date=date.getMonth()+1;
            
            if(date>=endMonth) {

                var emiEndDate=endYear+"-"+endMonth+"-"+endDay; 
               element.datepicker({
                    format: "mm-yyyy",
                    startView:1,
                    viewMode: 'years',
                    autoclose:true,
                    startDate: new Date(emistrtdate),
                    endDate: new Date(emiEndDate)
                });
            }else{
                    element.datepicker({                
                        format: "mm-yyyy",
                        startView:1,
                        viewMode: 'years',
                        autoclose:true,
                        startDate: new Date(emistrtdate),
                        endDate: new Date()
                    });
            }
				element.datepicker.viewMode('months');  
			});

		}return {
			require: 'ngModel',
			link: link
		};
	});

