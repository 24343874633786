app.controller('detailsController', ['$scope','$stateParams','$state','pagesModel','$sce',
	function($scope,$stateParams,$state,pagesModel,$sce) {
	// Get single Post data 
	
	if($state.params.id){
		
		pagesModel.getSingleDetail($state.params.id).success(function(response){
			$scope.data = response;
			
			var key = localStorage.getItem('language_key');

		    if(key == 'mr'){

		      $scope.htmldata = $scope.data.marathi_content;
		      $scope.title = 	$scope.data.marathi_title;
		    }
		    else{
		       $scope.htmldata = $scope.data.english_content;
		       $scope.title = 	$scope.data.title;
		    }
		    
		    $scope.$watch('common_vars.language_key', function (newValue, oldValue) {
		    	if(newValue =='mr'){

		    		$scope.htmldata = $scope.data.marathi_content;
		    		$scope.title = 	$scope.data.marathi_title;

		    	}
		    	if(newValue =='en'){
		    		
		    		$scope.htmldata = $scope.data.english_content;
		    		$scope.title = $scope.data.title;
		    		
		    	}
		    }, true);
			
		});
	}
	
}]);