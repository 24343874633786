app.controller('preAadharController',['$scope','$timeout','$state', function($scope, $timeout, $state){
	//variables
	$scope.form = {};
	$scope.user = {};

	$scope.isAadharExist = false;
	$scope.isMobileExist = false;
	$scope.isEmailExist = false;

	$scope.varificationData = angular.fromJson(localStorage.getItem('aadharVarificationData'));
	// alert($scope.varificationData);
	$scope.genders = [{"value": "1","option":"Male"},{"value":"2","option":"Female"},{"value":"3","option":"Transgender"}];

	if(!$scope.varificationData){
		$state.go('home')
	}

	if(localStorage.getItem('loginError')){
		$scope.loginError = localStorage.getItem('loginError');
		$timeout(function() {
			$scope.loginError = '';
			localStorage.removeItem('loginError');
		}, 10000);
	}

	if($scope.varificationData){
		$scope.dob = $scope.varificationData.dob.split('-');
		//console.log($scope.dob);
		
		$scope.data = {
			first_name: $scope.varificationData.first_name,
			last_name: $scope.varificationData.last_name,
			aadhar_no: $scope.varificationData.aadhar_card_no,
			mobile: $scope.varificationData.mobile_no,
			email: $scope.varificationData.email,
			aadhar_response_code:'',
			user_id:$scope.varificationData.user_id,
		}
		if ($scope.varificationData.gender == 'M') {
			$scope.data.gender = '1';
		}else if($scope.varificationData.gender == 'F'){
			$scope.data.gender = '2';
		}else if($scope.varificationData.gender == 'T'){
			$scope.data.gender = '3';

		}
		$timeout(function(){
				angular.element('#gender').selectpicker('refresh');
			}, 500);
		if($state.current.name=='pre_user_aadhar'){
 		// code...
 		$scope.data.captcha_string = captcha();
 		}
		if($scope.dob[2]!='00' && $scope.dob[1]!='00' && $scope.dob[0]!='0000'){
			$scope.data.dobday = $scope.dob[2];
			$scope.data.dobmonth= $scope.dob[1];
			$scope.data.dobyear= $scope.dob[0];
		}
	}else{
		$scope.data = {};
	}

	function captcha() {
		// body...
	    var text = "";
	    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

	    for( var i=0; i < 6; i++ )
	        text += possible.charAt(Math.floor(Math.random() * possible.length));

	    return text;
	}

	angular.extend($scope, {
		hasError: function(formName, fieldName, submit_var){
			if($scope.form[formName] == undefined || $scope.form[formName][fieldName] == undefined){
				return ''; 
			}
			
			return (
				$scope.form[formName][fieldName].$error.required &&
				$scope[submit_var]
			) ? 'has-error' : '';
		},

		// function for generating new captcha string when clicked on refresh button for employer...
		reCaptcha: function(){
	 			$scope.data.captcha_string = captcha();
		},

		doAadharVerify: function(data){
			if($scope.form.aadharVarificationForm.$valid /*&& $scope.data.myRecaptchaResponse*/){
				if ($scope.data.captcha_string === $scope.data.captcha) {
					// code...
					// alert(data.first_name);
					var aadhar_data = {};
					aadhar_data.aadhar_no = data.aadhar_no;
					aadhar_data.user_id = data.user_id;
					localStorage.setItem('aadhar_data', JSON.stringify(data));
					// alert(aadhar_data.user_id);
					userModel.isAadharExistEdit(aadhar_data).success(function(response){
						if (response == 0) {
							$scope.disabled_submit = true;
							userModel.getAadharVerified(data).success(function(response){
								//var addahrdata = localStorage.getItem('aadhar_data');
								//console.log(addahrdata); return;
								if (response.status == 'verified') {
									$state.go('mobile_verification');
								}else{
									$scope.disabled_submit = false;
									$scope.errorMsg_jobseeker= response.message;

								}
							});
						}else{
							bootbox.alert("Aadhar Already Use By Some other user");
						}
					});
					// 
				} else {
					$scope.data.captcha = '';
					$scope.data.captcha_string = captcha();

					$scope.errorMsg_jobseeker = 'Invalid Captcha';
					// alert($scope.errorMsg_jobseeker);
					$('html, body').animate({
					    scrollTop: ($('.alert').offset().top - 2000)
					}, 2000);
				}
			}else{
				
				$scope.errorMsg_jobseeker = 'invalid form please fill form first'; 
				$scope.enabledFormFields();
				$scope.disabled_submit = false;
				$scope.aadharVarificationForm_submit = true;
			}
		},

		verifyOTP: function(data){
			if($scope.form.otpVarification_form.$valid /*&& $scope.data.myRecaptchaResponse*/){
				userModel.verifyOTP(data).success(function(response){
					if (response.status == 200) {
						$state.go('resetpassword');
					} if (response.status == 408) {
						bootbox.alert("Your OTP expired");
					} else{
						bootbox.alert("Please enter correct OTP");
					}
				});
			}else{
				bootbox.alert("Please Enter Otp");
			}
		},

		setPassword: function(user){
			if($scope.form.setpassword.$valid && ( $scope.user.password === $scope.user.confirm_password)){
				var data = angular.copy(user);
	          	data.password = angular.copy(userModel.encryptCredentials(data.password));
	          	$scope.aadhar_data = angular.fromJson(localStorage.getItem('aadhar_data'));
	          	//console.log($scope.aadhar_data); return;
				var final_data = {};
				final_data.aadhar_data = $scope.aadhar_data;
				final_data.password = data.password;
				userModel.resetpassword(final_data).success(function(response){
						$state.go('home');

				});
			}
			else{
				$scope.setpasswordSubmit = true;
				// alert("Invalid Form");
			}

		}

	});



	
}]);